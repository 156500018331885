import { RootState as IState } from 'store/store'
import { isSuccess, unWrap } from 'store/webdata'

export enum BotType {
  academicSuccess = 'Academic Success',
  active = 'Active',
  collegeAccess = 'College Access',
  communityCollege = 'Community College',
  custom = 'Custom',
  demo = 'Demo',
  freeLiveChat = 'Free Live Chat',
  graduate = 'Graduate',
  granduateAndUndergraduate = 'Graduate & Undergraduate',
  internal = 'Internal',
  openEnrollmentRetention = 'Open Enrollment & Retention',
  recruitEnroll = 'Recruit & Enroll',
  retention = 'Retention',
  sandbox = 'Sandbox',
  workforce = 'Workforce',
}

export enum BotUseCase {
  academicSuccess = 'Academic Success',
  admissions = 'Admissions',
  admissionsAndRetention = 'Admissions and Retention',
  collegeAccess = 'College Access',
  collegeSuccess = 'College Success',
  custom = 'Custom',
  demo = 'Demo',
  employeeRecruitingAndRetention = 'Employee Recruiting and Retention',
  fasfa = 'FASFA/Finaid',
  freeLiveChat = 'Free Live Chat',
  sandbox = 'Sandbox',
  retention = 'Retention',
}

export enum InstitutionType {
  collegeAccessAndSuccess = 'College Access and Success',
  stateAgency = 'State Agency',
  communityCollege = 'Community College',
  privateUndergraduate = 'Private Undergraduate',
  publicUndergraduate = 'Public Undergraduate',
  privateGraduate = 'Private Graduate',
  publicGraduate = 'Public Graduate',
  privateUndergraduateAndGraduate = 'Private Undergraduate and Graduate',
  publicUndergraduateAndGraduate = 'Public Undergraduate and Graduate',
  workforce = 'Workforce',
  internal = 'Internal',
}

export enum BotStatus {
  active = 'Active',
  internal = 'Internal',
  retired = 'Retired',
}

export const getInstitutions = (state: IState) => {
  return isSuccess(state.institutions.institutions)
    ? unWrap(state.institutions.institutions)
    : []
}
