import React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Button } from 'components/Button/Button'
import { ADMITHUB_DOMAIN } from 'const/settings'
import { TextInput } from 'components/TextInput/TextInput'
import * as api from 'api'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  trustedEmailDomainFormReducer,
  setTextInput,
  addEmailDomain,
  removeEmailDomain,
  ITrustedEmailDomainFormReducerState,
} from 'components/SettingsTrustedEmailDomainForm/settingsTrustedEmailDomainFormReducer'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'

interface ITrustedEmailProps {
  readonly email: string
  readonly locked: boolean
  readonly removing: boolean
  readonly onRemove: (emailDomain: string) => void
}

function TrustedEmail({
  email,
  locked,
  onRemove,
  removing,
}: ITrustedEmailProps) {
  const handleRemove = () => onRemove(email)
  return (
    <li>
      <section className="d-flex align-items-center justify-content-start">
        <p className="mb-0 text-truncate">
          {email}
          {locked && <AHIcon name="lock" className="ml-1" />}
        </p>
        {!locked && (
          <PermissionGuard permission={PERMISSIONS.SETTINGS.EDIT}>
            <Button color="link" onClick={handleRemove} loading={removing}>
              Remove
            </Button>
          </PermissionGuard>
        )}
      </section>
    </li>
  )
}

interface ITrustedEmailsFormProps {
  readonly emails: ReadonlyArray<string>
  readonly initialState?: ITrustedEmailDomainFormReducerState
}

export function TrustedEmailDomainsForm({
  emails,
  initialState = {
    emailDomains: emails,
    textValue: '',
    saveStatus: 'initial',
    removeStatus: {},
  },
}: ITrustedEmailsFormProps) {
  const [state, dispatch] = React.useReducer(
    trustedEmailDomainFormReducer,
    initialState
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setTextInput(e.target.value))

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (
      !state.textValue.match(
        /^@?(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/
      )
    ) {
      toast.error('Not a valid domain.')
      return
    }
    dispatch(addEmailDomain.request())
    api
      .addEmailDomainToInstitution({ emailDomain: state.textValue })
      .then(res => {
        dispatch(addEmailDomain.success(res.data))
      })
      .catch(() => {
        toast.error('Problem adding email.')
        dispatch(addEmailDomain.failure())
      })
  }

  const handleRemove = (emailDomain: string) => {
    dispatch(removeEmailDomain.request(emailDomain))
    api
      .removeEmailDomainFromInstitution({ emailDomain })
      .then(res => {
        dispatch(
          removeEmailDomain.success({ emailDomain, institution: res.data })
        )
      })
      .catch(() => {
        toast.error('Problem removing email.')
        dispatch(removeEmailDomain.failure(emailDomain))
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <PermissionGuard permission={PERMISSIONS.SETTINGS.EDIT}>
        <section className="d-flex mt-2">
          <TextInput
            value={state.textValue}
            onChange={handleChange}
            placeholder="Add domain name for emails"
            className="mr-2"
          />
          <Button
            color="primary"
            type="submit"
            outlined
            loading={state.saveStatus === 'loading'}>
            Add
          </Button>
        </section>
      </PermissionGuard>
      <ul className="list-unstyled mt-3 pl-1 text-muted font-weight-bold">
        {[...state.emailDomains].sort().map(email => {
          const locked = email === ADMITHUB_DOMAIN
          const removing = state.removeStatus[email] === 'removing'
          return (
            <TrustedEmail
              key={email}
              email={email}
              locked={locked}
              onRemove={handleRemove}
              removing={removing}
            />
          )
        })}
      </ul>
    </form>
  )
}
