import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { ITrackingEvent } from 'components/EventTracker/EventTracker'
import PermissionGuard from 'util/permissions/PermissionGuard'

export interface ISimpleModalFooterProps {
  onSubmit?: () => void
  onCancel?: () => void
  noPadding?: boolean
  disableSubmit?: boolean
  disableCancel?: boolean
  loading?: boolean
  submitText?: string
  submitPermission?: string
  cancelText?: string
  className?: string
  submitTrackingEvent?: ITrackingEvent
  cancelTrackingEvent?: ITrackingEvent
  hideSubmit?: boolean
}

export const SimpleModalFooter = ({
  onSubmit,
  onCancel,
  noPadding = false,
  disableSubmit,
  disableCancel,
  hideSubmit,
  loading,
  submitText,
  submitPermission,
  cancelText,
  className,
  submitTrackingEvent,
  cancelTrackingEvent,
}: ISimpleModalFooterProps) => {
  const submitButton = (
    <Button
      eventLocation={submitTrackingEvent?.location}
      eventAction={submitTrackingEvent?.action}
      eventObject={submitTrackingEvent?.object}
      type="submit"
      onClick={() => onSubmit?.()}
      disabled={!!disableSubmit}
      loading={loading}
      className="btn btn-secondary-teal px-3 py-2">
      {submitText ?? 'Save'}
    </Button>
  )

  return (
    <div
      className={classNames(
        'd-flex justify-content-end',
        { 'px-4 pt-3 pb-4': !noPadding },
        className
      )}>
      {!hideSubmit &&
        (submitPermission ? (
          <PermissionGuard permission={submitPermission}>
            {submitButton}
          </PermissionGuard>
        ) : (
          submitButton
        ))}

      {onCancel && (
        <Button
          type="button"
          eventLocation={cancelTrackingEvent?.location}
          eventAction={cancelTrackingEvent?.action}
          eventObject={cancelTrackingEvent?.object}
          className="ml-2 btn text-mainstay-dark-blue-65 border-color-mainstay-dark-blue-65 border-1px bg-white px-3 py-2"
          onClick={onCancel}
          disabled={!!disableCancel}>
          {cancelText ?? 'Cancel'}
        </Button>
      )}
    </div>
  )
}
