import { createAsyncAction, ActionType, getType } from 'typesafe-actions'
import {
  WebData,
  Loading,
  Success,
  Failure,
  HttpErrorKind,
} from 'store/webdata'
import { InstitutionListItem } from 'api'

export const fetchInstitutions = createAsyncAction(
  '@@MASCOT/v2/FETCH_INSTITUTIONS_START',
  '@@MASCOT/v2/FETCH_INSTITUTIONS_SUCCESS',
  '@@MASCOT/v2/FETCH_INSTITUTIONS_FAILURE'
)<
  { copyingScript?: boolean },
  { institutions: InstitutionListItem[]; copyingScript?: boolean },
  HttpErrorKind & { copyingScript?: boolean }
>()

export type IInstitutionActions = ActionType<typeof fetchInstitutions>

interface IInstitutionState {
  readonly institutions: WebData<InstitutionListItem[]>
  readonly scriptCopyTargetInstitutions: WebData<InstitutionListItem[]>
}

const defaultState = {
  institutions: undefined,
  scriptCopyTargetInstitutions: undefined,
}

export const institutionReducer = (
  state: IInstitutionState = defaultState,
  action: IInstitutionActions
) => {
  const institutionsField = !!action.payload?.copyingScript
    ? 'scriptCopyTargetInstitutions'
    : 'institutions'

  switch (action.type) {
    case getType(fetchInstitutions.request):
      return {
        ...state,
        [institutionsField]: Loading(),
      }
    case getType(fetchInstitutions.success):
      return {
        ...state,
        [institutionsField]: Success(action.payload.institutions),
      }
    case getType(fetchInstitutions.failure):
      return {
        ...state,
        [institutionsField]: Failure(action.payload),
      }
    default:
      return state
  }
}

export default institutionReducer
