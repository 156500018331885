import classNames from 'classnames'
import { Button, IButtonProps } from 'components/Button/Button'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import React from 'react'

export const CreateButton = (
  props: IButtonProps & { title: string; outlined?: boolean }
) => {
  const { title, className, outlined, ...restProps } = props
  return (
    <Button
      className={classNames('d-flex flex-row align-items-center', className)}
      color="secondary-teal"
      {...restProps}>
      <AHIcon name={outlined ? 'add_circle_outline' : 'add_circle'} />{' '}
      <span>{title}</span>
    </Button>
  )
}

interface IPageHeaderProps {
  title: string | React.ReactNode
  count?: number | undefined
  description?: string | null
  children?: React.ReactNode
  className?: string
}

export const PageHeader = React.forwardRef(
  (props: IPageHeaderProps, ref: React.Ref<HTMLDivElement>) => {
    const { title, description, count, className, children } = props
    return (
      <div className={classNames('pt-3 mb-1_75rem', className)}>
        <div className="d-flex justify-content-between align-items-center text-mainstay-dark-blue-80">
          <div className="d-flex align-items-center min-width-0 overflow-x-hidden flex-grow-1">
            {typeof title === 'string' ? (
              <h2
                className="m-0 text-truncate text-mainstay-dark-blue"
                ref={ref}>
                {title}
              </h2>
            ) : (
              title
            )}
            {count ? <small className="mt-2 ml-2">{count}</small> : null}
          </div>
          {children}
        </div>
        {description && (
          <p className="text-mainstay-dark-blue-80 mt-1 mb-0">{description}</p>
        )}
      </div>
    )
  }
)
