import * as React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { useDispatch, useFeatures } from 'util/hooks'
import {
  PromptType,
  ExitActionType,
  ICampaignScriptStep,
} from 'store/campaign-scripts/reducer'
import {
  addBlankExitAction,
  setShowPauseTimeForm,
  setShowVCardForm,
} from 'store/campaign-scripts/actions'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { MenuItem } from '@material-ui/core'

interface ICampaignBuilderContextMenuProps {
  onClickAdd?: (type: PromptType) => void
  onClickAddLink?: () => void
  node?: ICampaignScriptStep
  menuType?: 'full' | 'limited'
  onClose?: () => void
  nodeType?: string
  initial?: boolean
}

function CampaignBuilderContextMenu({
  onClose,
  initial,
  menuType,
  nodeType,
  onClickAdd,
  node,
  onClickAddLink,
}: ICampaignBuilderContextMenuProps) {
  const dispatch = useDispatch()
  const { hasFeature, FeaturesType } = useFeatures()
  const showPauseTimeOption = React.useMemo(
    () => !node?.showPauseTimeForm && nodeType === 'Auto',
    [node, nodeType]
  )
  const showAddMenu = React.useMemo(
    () => initial || !['Boolean', 'Number'].includes(nodeType || ''),
    [initial, nodeType]
  )
  const showActionMenu = React.useMemo(() => !initial && Boolean(node), [
    initial,
    node,
  ])

  const handleClickOutside = React.useCallback(() => onClose && onClose(), [
    onClose,
  ])

  const createHandler = (callback?: () => void) => {
    return (e: React.MouseEvent) => {
      e.stopPropagation()
      if (onClose) {
        onClose()
      }

      if (callback) {
        callback()
      }
    }
  }

  return (
    <OutsideClickHandler
      onClickOutside={handleClickOutside}
      excludeClassname="node-add-button"
      containerClassName="cardo py-3 shadow rounded bg-white mt-2 min-width-350px">
      <div className="position-relative">
        {showAddMenu && (
          <>
            <h6 className="px-4 pt-2 pb-1">Add Message</h6>
            <div>
              <MenuItem
                tabIndex={0}
                className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                onClick={createHandler(() => {
                  if (onClickAdd) {
                    onClickAdd(PromptType.auto)
                  }
                })}>
                Simple message
              </MenuItem>
              {menuType === 'full' && (
                <MenuItem
                  tabIndex={0}
                  className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                  onClick={createHandler(() => {
                    if (onClickAdd) {
                      onClickAdd(PromptType.boolean)
                    }
                  })}>
                  Message + yes/no prompt
                </MenuItem>
              )}
              {menuType === 'full' && (
                <MenuItem
                  tabIndex={0}
                  className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                  onClick={createHandler(() => {
                    if (onClickAdd) {
                      onClickAdd(PromptType.number)
                    }
                  })}>
                  Message + multiple choice prompt
                </MenuItem>
              )}
              <MenuItem
                tabIndex={0}
                className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                onClick={createHandler(() => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  onClickAdd!(PromptType.open)
                })}>
                Message + open text prompt
              </MenuItem>
              {menuType === 'full' && onClickAddLink && (
                <MenuItem
                  tabIndex={0}
                  className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                  onClick={createHandler(() => {
                    onClickAddLink?.()
                  })}>
                  Link to another message
                </MenuItem>
              )}
            </div>
          </>
        )}
        {showAddMenu && showActionMenu && <hr className="mx-4" />}

        {showActionMenu && (
          <>
            <h6 className="px-4 pt-2 pb-1">Add Action</h6>
            <div>
              <MenuItem
                tabIndex={0}
                className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                onClick={createHandler(
                  () =>
                    node &&
                    dispatch(
                      addBlankExitAction({
                        action: {
                          newlyCreated: true,
                          name: ExitActionType.SendEmail,
                          args: {
                            to: '',
                            prefix:
                              'Use the "Click here to reply" button below to initiate a conversation. Your message will be delivered by your bot via the contact\'s communication channel.',
                            subject: 'FWD: message from a contact',
                          },
                        },
                        dialogStateId: node.id,
                      })
                    )
                )}>
                Send responses to email
              </MenuItem>
              {nodeType && ['Boolean', 'Number', 'Open'].includes(nodeType) && (
                <MenuItem
                  tabIndex={0}
                  className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                  onClick={createHandler(
                    () =>
                      node &&
                      dispatch(
                        addBlankExitAction({
                          action: {
                            newlyCreated: true,
                            name: ExitActionType.Save,
                            args: {},
                          },
                          dialogStateId: node.id,
                        })
                      )
                  )}>
                  Save responses
                </MenuItem>
              )}
              <MenuItem
                tabIndex={0}
                className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                onClick={createHandler(
                  () =>
                    node &&
                    dispatch(
                      addBlankExitAction({
                        action: {
                          newlyCreated: true,
                          name: ExitActionType.Save,
                          args: {
                            value: null,
                          },
                        },
                        dialogStateId: node.id,
                      })
                    )
                )}>
                Set a field value
              </MenuItem>
              <MenuItem
                tabIndex={0}
                className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                onClick={createHandler(
                  () =>
                    node &&
                    dispatch(
                      addBlankExitAction({
                        action: {
                          newlyCreated: true,
                          name: ExitActionType.Save,
                          args: {
                            unset: true,
                          },
                        },
                        dialogStateId: node.id,
                      })
                    )
                )}>
                Unset a field
              </MenuItem>
              {showPauseTimeOption && (
                <div>
                  <MenuItem
                    tabIndex={0}
                    className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                    onClick={createHandler(() => {
                      if (node) {
                        dispatch(
                          setShowPauseTimeForm({
                            showForm: true,
                            dialogStateId: node.id,
                          })
                        )
                      }
                    })}>
                    Set Custom Pause Time
                  </MenuItem>
                  {node && !node?.vCard && (
                    <MenuItem
                      tabIndex={0}
                      className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                      onClick={createHandler(() => {
                        if (node) {
                          dispatch(
                            setShowVCardForm({
                              showForm: true,
                              dialogStateId: node.id,
                            })
                          )
                        }
                      })}>
                      Attach vCard
                    </MenuItem>
                  )}
                  {hasFeature(FeaturesType.RAGTIME) && (
                    <MenuItem
                      tabIndex={0}
                      className="pointer py-2 px-4 hover-bg-mainstay-dark-blue-10"
                      onClick={createHandler(
                        () =>
                          node &&
                          dispatch(
                            addBlankExitAction({
                              action: {
                                newlyCreated: true,
                                name: ExitActionType.Ragtime,
                                args: {
                                  maxMessages: 3,
                                  maxMinutes: 3,
                                  genAiTopicId: null,
                                },
                              },
                              dialogStateId: node.id,
                            })
                          )
                      )}>
                      Enter Gen-AI Discussion
                    </MenuItem>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        {node && (
          <div className="position-absolute top-0 right-0 mt-2 mr-4">
            <AHIcon className="pointer" onClick={onClose} name="close" />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default CampaignBuilderContextMenu
