import { Link } from 'util/routing'
import cls from 'classnames'

export interface ILinkWithMiddleEllipsisProps {
  target: string | null
  displayText?: string | null
  isInternal?: boolean
  offset?: number
  className?: string
}

/**
 * Link component that has the ellipsis in the middle to help display longer strings.
 * eg:
 *     - https://www.britannica.co...hemical-element
 *     - Boron | Properties, Use... Facts | Britannica
 */
export const LinkWithMiddleEllipsis = ({
  target,
  className,
  displayText,
  isInternal = true,
  offset = 15,
}: ILinkWithMiddleEllipsisProps) => {
  if (displayText && !target) {
    return (
      <span className="d-inline-block text-ellipsis fs-0_8rem">
        {displayText.slice(0, displayText.length - offset)}
      </span>
    )
  }

  if (!!target) {
    const linkText = displayText ?? target
    const linkClassName = cls(
      'mid-ellipsis-link d-flex text-secondary-teal fs-0_8rem',
      className
    )
    const shouldTruncate = linkText.length > offset
    if (isInternal) {
      return (
        <Link target="_blank" className={linkClassName} to={target}>
          <span className="d-inline-block text-ellipsis">
            {shouldTruncate
              ? linkText.slice(0, linkText.length - offset)
              : linkText}
          </span>
          <span className="d-inline-block no-wrap">
            {shouldTruncate ? linkText.slice(-1 * offset) : ''}
          </span>
        </Link>
      )
    }
    return (
      <a
        className={linkClassName}
        target="_blank"
        rel="noreferrer"
        href={target}>
        <span className="d-inline-block text-ellipsis">
          {shouldTruncate
            ? linkText.slice(0, linkText.length - offset)
            : linkText}
        </span>
        <span className="d-inline-block no-wrap">
          {shouldTruncate ? linkText.slice(-1 * offset) : ''}
        </span>
      </a>
    )
  }

  return null
}
