import { openEditRecurringCampaignEndDateModal } from 'store/sharedGlobalState/actions'
import { IActions } from 'store/store'

import { getType } from 'typesafe-actions'

export interface IGlobalSharedState {
  showRecurringCampignEndDateDisplayModal?: boolean
}

const initialState: IGlobalSharedState = {}

export const globalStateReducer = (
  state: IGlobalSharedState = initialState,
  action: IActions
): IGlobalSharedState => {
  switch (action.type) {
    case getType(openEditRecurringCampaignEndDateModal): {
      return {
        showRecurringCampignEndDateDisplayModal: action.payload,
      }
    }
    default:
      return state
  }
}
