import * as React from 'react'
import classnames from 'classnames'
import {
  IAdmitHubMessageEvent,
  IAdmitHubEventData,
} from 'embed/WebChatIFrameOuter'
import { CSSTransition } from 'components/CSSTransition/CSSTransition'

export enum PopUpStatus {
  Loading = 'LOADING',
  Visible = 'VISIBLE',
  Completed = 'COMPLETED',
}

interface IPopUpMessageIFrameProps {
  scriptDomain: string
  popUpStatus: PopUpStatus
  onClick?: () => void
  onClose?: () => void
  onShowPopUp?: () => void
}

export default function PopUpMessageIFrame({
  scriptDomain,
  popUpStatus,
  onClose,
  onClick,
  onShowPopUp,
}: IPopUpMessageIFrameProps) {
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const iframe = document.getElementById(
      'admithub_message_iframe'
    ) as HTMLIFrameElement | null
    const handleIFrameEvents = (e: IAdmitHubMessageEvent) => {
      const data: IAdmitHubEventData = e.data
      if (data.type === 'admitHubEvent') {
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(e.data, '*')
        }

        switch (data.action) {
          case 'openPopUp': {
            if (popUpStatus !== PopUpStatus.Completed && onShowPopUp) {
              onShowPopUp()
            }
            break
          }
          case 'closePopUp': {
            if (onClose) {
              onClose()
            }
            break
          }
          case 'clickPopUp': {
            if (onClick) {
              onClick()
            }
            break
          }
        }
      }
    }
    window.addEventListener('message', handleIFrameEvents)
  })

  return (
    <CSSTransition
      in={popUpStatus === PopUpStatus.Visible}
      timeout={500}
      classNames="transition-popup">
      <iframe
        src={`${scriptDomain || ''}/webchatPopUp.html`}
        title="Web Chat Intro Message"
        aria-label="Select to talk to our bot"
        id="admithub_message_iframe"
        className={classnames('message-iframe', {
          hidden: popUpStatus !== PopUpStatus.Visible,
        })}>
        Please refresh the browser with i-frames enabled in order to see the web
        chat intro message.
      </iframe>
    </CSSTransition>
  )
}
