import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'
import { throttle } from 'lodash'
import {
  routerMiddleware,
  RouterAction,
  connectRouter,
} from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { IAuthActions } from 'store/auth/actions'
import { RootState as IState } from 'store/store'
import { loadAuthState, saveAuthState } from 'store/localStorage'
import { IScheduledCampaignsActions } from 'store/scheduledCampaigns/actions'
import { ITopicActions } from 'store/triage/topic/actions'
import { IImportReportActions as ILegacyImportReportActions } from 'store/import-reports/actions'
import { IKnowledgeSeederActions } from 'store/knowledgeSeeder/actions'
import { IContactsActions as ILegacyContactsActions } from 'store/triage/chat/contactsActions'
import { IContactsActions } from 'store/contacts/actions'
import { IConversationActions } from 'store/triage/chat/conversationActions'
import { IMessagesActionTypes } from 'store/triage/chat/messagesActions'
import { IExchangesActions } from 'store/triage/chat/exchangesActions'
import { IUserLocationActions } from 'store/triage/userLocation/actions'
import { IProfileActions } from 'store/triage/profile/actions'
import { IInstitutionActions as ILegacyInstitutionActions } from 'store/triage/institution/actions'
import { ICampaginHistoryActions } from 'store/campaign-history/actions'
import { ICampaignDetailsActions } from 'store/campaign-details/actions'
import { ICampaignSchedulerActions } from 'store/campaign-scheduler/actions'
import { IImportLabelActions } from 'store/import-label/actions'
import { IInstitutionActions } from 'store/institution/reducer'
import { IConversationsActions } from 'store/conversations/reducer'
import { IActivityLogActions } from 'store/triage/activity-log/actions'
import { IUserActions } from 'store/triage/users/actions'
import { ICampaignScriptActions } from 'store/campaign-scripts/actions'
import { showNavConfirmation } from 'components/NavConfirmation/NavConfirmation'
import { IKnowledgeActions } from 'store/triage/knowledge/actions'
import { IWebChatActions } from 'store/embed/actions'
import { IContactLabelActions } from 'store/contact-labels/actions'
import { IEscalationActions } from 'store/escalation/actions'
import { IPersonalizationActions } from 'store/personalization/institutionAttributes/actions'
import { IPersonalizationContactActions } from 'store/personalization/contactAttributes/actions'
import { IPageActions } from 'store/page/pageReducer'
import { IDialogLabelActions } from 'store/dialog-labels/actions'
import { ITriageActions } from 'store/triage/reducer'
import { IAttributeMapperActions } from 'store/attribute-mapper/actions'
import { IContactSegmentActions } from 'store/contact-segments/actions'
import { configureStore, Middleware } from '@reduxjs/toolkit'
import { loggerMiddleware } from 'store/middleware'
import { commandsReducer } from 'store/commands/commands-store'
import * as reducers from 'store/reducers'

import { reducers as conversationV2Reducers } from 'page/conversations-v2/index'
import { reducers as knowledgeBaseReducers } from 'page/knowledge-base/index'
import { reducers as settingsReducers } from 'page/settings/index'
import { filterContactsReducer } from 'components/FilterContactsModal/filterContactsReducer'
import { IAskAIActions } from 'store/ask-ai/actions'
import { askAIReducer } from 'store/ask-ai/reducer'
import { globalStateReducer } from 'store/sharedGlobalState/reducer'
import { IGlobalSharedStateActions } from 'store/sharedGlobalState/actions'

const triageUiReducer = combineReducers({
  conversation: reducers.conversationReducer,
  page: reducers.pageReducer,
})

const triageApplicationReducer = combineReducers({
  exchanges: reducers.exchangesReducer,
  contacts: reducers.legacyContactsReducer,
  messages: reducers.legacyMessagesReducer,
  topics: reducers.topicsReducer,
  knowledge: reducers.knowledgeReducer,
  profile: reducers.profileReducer,
  institution: reducers.legacyInstitutionsReducer,
  userLocation: reducers.userLocationReducer,
  activityLog: reducers.activityLogReducer,
  users: reducers.legacyUsersReducer,
})

const triageReducer = combineReducers({
  ui: triageUiReducer,
  application: triageApplicationReducer,
})

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    triage: triageReducer,
    auth: reducers.authReducer,
    scheduledCampaigns: reducers.scheduledCampaignsReducer,
    campaignHistory: reducers.campaignHistoryReducer,
    campaignDetails: reducers.campaignDetailsReducer,
    knowledgeSeeder: reducers.knowledgeSeederReducer,
    campaignScheduler: reducers.campaignSchedulerReducer,
    importLabel: reducers.importLabelReducer,
    institutions: reducers.institutionReducer,
    conversations: reducers.conversationsReducer,
    conversationsV2: conversationV2Reducers,
    knowledgeBase: knowledgeBaseReducers,
    settings: settingsReducers,
    importReports: reducers.importReportsReducer,
    campaignScripts: reducers.campaignScriptsReducer,
    contacts: reducers.contactsReducer,
    webchat: reducers.WebChatReducer,
    contactLabels: reducers.contactLabelReducer,
    escalation: reducers.escalationReducer,
    personalization: reducers.personalizationReducer,
    contactsPersonalization: reducers.personalizationContactsReducer,
    dialogLabels: reducers.dialogLabelsReducer,
    triageCount: reducers.triageReducer,
    contactSegments: reducers.contactSegmentsReducer,
    commands: commandsReducer,
    ablyConnection: reducers.ablyConnectionReducer,
    attributeMapper: reducers.attributeMapperReducer,
    filterContacts: filterContactsReducer,
    askAI: askAIReducer,
    globalState: globalStateReducer,
  })

export const history = createBrowserHistory({
  getUserConfirmation: showNavConfirmation,
})

const rootReducer = createRootReducer(history)

export type RootState = StateType<typeof rootReducer>

export const emptyState = rootReducer(undefined, { type: '@@INIT' })

const defaultData: IState = {
  ...emptyState,
  auth: loadAuthState() || emptyState.auth,
}

export type IActions =
  | IAuthActions
  | IScheduledCampaignsActions
  | RouterAction
  | ITopicActions
  | ILegacyContactsActions
  | IMessagesActionTypes
  | IExchangesActions
  | IConversationActions
  | IUserLocationActions
  | IProfileActions
  | ILegacyInstitutionActions
  | ICampaignSchedulerActions
  | IImportLabelActions
  | ICampaginHistoryActions
  | ICampaignDetailsActions
  | IKnowledgeSeederActions
  | IInstitutionActions
  | IConversationsActions
  | IActivityLogActions
  | IUserActions
  | ICampaignScriptActions
  | ILegacyImportReportActions
  | IContactsActions
  | IKnowledgeActions
  | IWebChatActions
  | IContactLabelActions
  | IEscalationActions
  | IPersonalizationActions
  | IPersonalizationContactActions
  | IPageActions
  | IDialogLabelActions
  | ITriageActions
  | IContactSegmentActions
  | IAttributeMapperActions
  | IAskAIActions
  | IGlobalSharedStateActions

export const store = configureStore({
  preloadedState: defaultData,
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      /* https://stackoverflow.com/a/63244831/

      https://redux-toolkit.js.org/api/getDefaultMiddleware
      */
      serializableCheck: false,
      immutableCheck: false,
    }).prepend(routerMiddleware(history), loggerMiddleware),
  devTools: true,
})
export type Dispatch = typeof store.dispatch
export type Store = typeof store

interface ICreateStoreProps {
  readonly state?: IState
  readonly middleware?: Middleware<IState>
}

export function createStore(args: ICreateStoreProps) {
  return configureStore({
    preloadedState: args.state,
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      const prepend = []
      if (args.middleware) {
        prepend.push(args.middleware)
      }
      return getDefaultMiddleware({
        /* https://stackoverflow.com/a/63244831/
        https://redux-toolkit.js.org/api/getDefaultMiddleware
        */
        serializableCheck: false,
        immutableCheck: false,
      }).prepend(prepend)
    },
    devTools: true,
  })
}

export const emptyStore = createStore({})
// Note(jsimms): The org switcher needs to ensure that the state is saved before refreshing the page
// so this function needs to be called manually after the action fires.
export const saveAuthToLocalStorage = () => {
  saveAuthState({
    ...emptyState.auth,
    authed: store.getState().auth.authed,
    orgSlug: store.getState().auth.orgSlug,
    userID: store.getState().auth.userID,
    userEmail: store.getState().auth.userEmail,
    userName: store.getState().auth.userName,
  })
}

store.subscribe(throttle(saveAuthToLocalStorage, 1000))
