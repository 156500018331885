import {
  createStandardAction,
  ActionType,
  createAsyncAction,
} from 'typesafe-actions'

import { push } from 'connected-react-router'
import { Filters, SortBy, SortOrder } from 'store/campaign-history/reducer'
import {
  ICampaignHistoryResponseData,
  ICampaignTriggerResponseData,
} from 'api/response'
import { ICountsResponseData } from 'api'

export const fetchCampaignHistory = createAsyncAction(
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_HISTORY_START',
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_HISTORY_SUCCESS',
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_HISTORY_FAILURE'
)<
  void,
  {
    readonly campaigns: ReadonlyArray<ICampaignHistoryResponseData>
  },
  void
>()

export const fetchCampaignTriggers = createAsyncAction(
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_TRIGGERS_START',
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_TRIGGERS_SUCCESS',
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_TRIGGERS_FAILURE'
)<void, ReadonlyArray<ICampaignTriggerResponseData>, void>()

export const fetchCampaignCountByType = createStandardAction(
  '@@MASCOT/CAMPAIGN_HISTORY/FETCH_CAMPAIGN_COUNTS_BY_TYPE'
)<{
  counts: Readonly<ICountsResponseData>
}>()

export const navToFirstPage = () => push('/campaigns')

export const setCampaignHistoryFilterBy = createStandardAction(
  '@@MASCOT/CAMPAIGN_HISTORY/SET_CAMPAIGN_HISTORY_FILTER_BY'
)<Filters>()

export const setCampaignHistorySortBy = createStandardAction(
  '@@MASCOT/CAMPAIGN_HISTORY/SET_CAMPAIGN_HISTORY_SORT_BY'
)<{
  readonly sortBy: SortBy
  readonly order: SortOrder
}>()

export type ICampaginHistoryActions =
  | ActionType<typeof fetchCampaignHistory>
  | ActionType<typeof fetchCampaignCountByType>
  | ActionType<typeof setCampaignHistorySortBy>
  | ActionType<typeof setCampaignHistoryFilterBy>
  | ActionType<typeof fetchCampaignTriggers>
