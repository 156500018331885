import { useCallback, useLayoutEffect, useMemo } from 'react'
import 'page/NotFoundGame/notFoundGame.css'

type NotFoundGameState = {
  loop: NodeJS.Timer | null
  logo: {
    element: HTMLAnchorElement | null
  }
  score: {
    element: HTMLDivElement | null
  }
  rightNavbar: {
    element: HTMLDivElement | null
  }
  ball: {
    element: HTMLDivElement | null
    x: number // px
    y: number // px
    vx: number // px per iteration
    vy: number // px per iteration
    maxVx: number // px per iteration
    maxVy: number // px per iteration
    size: number // px
    intangible: number // iterations
  }
  leftPaddle: {
    element: HTMLDivElement | null
    x: number // px (sidebarSize - width)
    y: number // px
    width: number // px
    height: number // px
    points: number
  }
  rightPaddle: {
    element: HTMLDivElement | null
    x: number // px (sidebarSize)
    y: number // px
    vy: number // px per iteration
    ay: number // px per iteration per iteration
    maxVy: number // px per iteration
    width: number // px
    height: number // px
    points: number
  }
}

const GAME_CONSTANTS = {
  loopTime: 10, // ms
  startingSpeed: 2, // px per iteration
  sidebarSize: 75, // px
  bounceAcceleration: 0.5, // px per iteration
  tipSize: 15, // px
  tipAcceleration: 1.5, // px per iteration
  paddingX: 10, // px
}

const NotFoundGame = () => {
  // Since there are no props, there are no re-renders once the elements are populated and this object persists and can be mutated.
  // Memo-izing `game` and the subsequent functinos is necessary to make the linter happy when it comes to run our useLayoutEffect,
  // but functionally are kind of silly because this component will never re-render.
  const game: NotFoundGameState = useMemo(
    () => ({
      loop: null,
      logo: {
        element: null,
      },
      score: {
        element: null,
      },
      rightNavbar: {
        element: null,
      },
      ball: {
        element: null,
        x: -1,
        y: -1,
        vx: 0,
        vy: 0,
        maxVx: 10,
        maxVy: 10,
        size: 50,
        intangible: 50,
      },
      leftPaddle: {
        element: null,
        x: 70,
        y: 0,
        width: 5,
        height: 50,
        points: 0,
      },
      rightPaddle: {
        element: null,
        x: window.innerWidth - 75,
        y: 0,
        vy: 0,
        ay: 0.2,
        maxVy: 8,
        width: 5,
        height: 50,
        points: 0,
      },
    }),
    []
  )

  const resetBall = useCallback(
    (logoRect?: { x: number; y: number; width: number; height: number }) => {
      if (logoRect) {
        game.ball.x = logoRect.x + logoRect.width / 2
        game.ball.y = logoRect.y + logoRect.height / 2
        game.ball.vx = GAME_CONSTANTS.startingSpeed
        game.ball.vy = GAME_CONSTANTS.startingSpeed
        return
      }

      game.ball.x = window.innerWidth / 2
      game.ball.y = window.innerHeight / 2
      game.ball.vx =
        GAME_CONSTANTS.startingSpeed * (Math.floor(Math.random() * 2) ? 1 : -1)
      game.ball.vy =
        GAME_CONSTANTS.startingSpeed * (Math.floor(Math.random() * 2) ? 1 : -1)
    },
    [game]
  )

  const moveBall = useCallback(() => {
    game.ball.x += game.ball.vx
    game.ball.y += game.ball.vy

    if (game.ball.intangible) {
      game.ball.intangible -= 1
    }
  }, [game])

  const displayBall = useCallback(() => {
    if (!game.ball.element) {
      return
    }
    game.ball.element.style.left = `${game.ball.x - game.ball.size / 2}px`
    game.ball.element.style.top = `${game.ball.y - game.ball.size / 2}px`
  }, [game.ball.element, game.ball.size, game.ball.x, game.ball.y])

  const movePaddle = useCallback(
    (event: MouseEvent) => {
      if (!game.leftPaddle?.element) {
        return
      }
      const cursorY = event.clientY
      game.leftPaddle.y = cursorY
      game.leftPaddle.element.style.top = `${game.leftPaddle.y -
        game.leftPaddle.height / 2}px`
    },
    [game]
  )

  const resizeGame = useCallback(() => {
    if (!game.rightPaddle?.element) {
      return
    }
    game.rightPaddle.x = window.innerWidth - GAME_CONSTANTS.sidebarSize
    game.rightPaddle.element.style.left = `${game.rightPaddle.x}px`
  }, [game])

  const startGame = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      if (
        !game.logo?.element ||
        !game.ball.element ||
        !game.rightNavbar.element ||
        !game.rightPaddle.element
      ) {
        return
      }
      const logoRect = game.logo.element.getBoundingClientRect()
      game.logo.element.classList.remove('pulse')
      game.logo.element.style.opacity = '0'

      resetBall(logoRect)
      displayBall()

      game.ball.element.style.opacity = '1'
      game.rightNavbar.element.style.opacity = '1'
      game.rightPaddle.element.style.opacity = '1'
    },
    [game, displayBall, resetBall]
  )

  const isOutOfBounds = useCallback(() => {
    if (!game.score.element) {
      return
    }
    // left side
    if (game.ball.x <= -game.ball.size / 2) {
      game.rightPaddle.points++
      game.score.element.innerText = `${game.leftPaddle.points} - ${game.rightPaddle.points}`
      game.ball.x = -20000
      game.ball.y = -20000
      return true
    }

    // right side
    if (game.ball.x >= window.innerWidth + game.ball.size / 2) {
      game.leftPaddle.points++
      game.score.element.innerText = `${game.leftPaddle.points} - ${game.rightPaddle.points}`
      game.ball.x = -20000
      game.ball.y = -20000
      return true
    }

    // in bounds
    return false
  }, [game])

  const resolveBounces = useCallback(() => {
    // top
    if (game.ball.y <= game.ball.size / 2) {
      game.ball.vy = -game.ball.vy
    }

    // bottom
    else if (game.ball.y >= window.innerHeight - game.ball.size / 2) {
      game.ball.vy = -game.ball.vy
    }

    // intangible
    if (game.ball.intangible) {
      return
    }

    // left paddle
    if (
      game.ball.x - game.ball.size / 2 <=
        game.leftPaddle.x + game.leftPaddle.width &&
      game.ball.x - game.ball.size / 2 >=
        game.leftPaddle.x - GAME_CONSTANTS.paddingX &&
      game.ball.y - game.ball.size / 2 <=
        game.leftPaddle.y + game.leftPaddle.height / 2 &&
      game.ball.y + game.ball.size / 2 >=
        game.leftPaddle.y - game.leftPaddle.height / 2
    ) {
      game.ball.vx = Math.min(
        game.ball.maxVx,
        Math.abs(game.ball.vx) + GAME_CONSTANTS.bounceAcceleration
      )

      if (Math.abs(game.ball.y - game.leftPaddle.y) < GAME_CONSTANTS.tipSize) {
        if (game.ball.y < game.leftPaddle.y) {
          game.ball.vy = Math.max(
            -game.ball.maxVy,
            game.ball.vy - GAME_CONSTANTS.tipAcceleration
          )
        } else {
          game.ball.vy = Math.min(
            game.ball.maxVy,
            game.ball.vy + GAME_CONSTANTS.tipAcceleration
          )
        }
      }
    }

    // right paddle
    else if (
      game.ball.x + game.ball.size / 2 <=
        game.rightPaddle.x + game.rightPaddle.width + GAME_CONSTANTS.paddingX &&
      game.ball.x + game.ball.size / 2 >= game.rightPaddle.x &&
      game.ball.y - game.ball.size / 2 <=
        game.rightPaddle.y + game.rightPaddle.height / 2 &&
      game.ball.y + game.ball.size / 2 >=
        game.rightPaddle.y - game.rightPaddle.height / 2
    ) {
      game.ball.vx =
        Math.min(
          game.ball.maxVx,
          Math.abs(game.ball.vx) + GAME_CONSTANTS.bounceAcceleration
        ) * -1

      if (Math.abs(game.ball.y - game.rightPaddle.y) < GAME_CONSTANTS.tipSize) {
        if (game.ball.y < game.rightPaddle.y) {
          game.ball.vy = Math.max(
            -game.ball.maxVy,
            game.ball.vy - GAME_CONSTANTS.tipAcceleration
          )
        } else {
          game.ball.vy = Math.min(
            game.ball.maxVy,
            game.ball.vy + GAME_CONSTANTS.tipAcceleration
          )
        }
      }
    }
  }, [game])

  const moveRightPaddle = useCallback(() => {
    if (!game.rightPaddle.element) {
      return
    }
    // accelerate
    if (game.ball.y < game.rightPaddle.y) {
      game.rightPaddle.vy = Math.max(
        -game.rightPaddle.maxVy,
        game.rightPaddle.vy - game.rightPaddle.ay
      )
    } else if (game.ball.y > game.rightPaddle.y) {
      game.rightPaddle.vy = Math.min(
        game.rightPaddle.maxVy,
        game.rightPaddle.vy + game.rightPaddle.ay
      )
    }

    // move
    game.rightPaddle.y = Math.max(
      game.rightPaddle.height / 2,
      Math.min(
        window.innerHeight - game.rightPaddle.height / 2,
        game.rightPaddle.y + game.rightPaddle.vy
      )
    )
    game.rightPaddle.element.style.top = `${game.rightPaddle.y -
      game.rightPaddle.height / 2}px`

    // edge --> reset momentum
    if (game.rightPaddle.y === game.rightPaddle.height / 2) {
      game.rightPaddle.vy = 0
    } else if (
      game.rightPaddle.y ===
      window.innerHeight - game.rightPaddle.height / 2
    ) {
      game.rightPaddle.vy = 0
    }
  }, [game])

  const iterateGame = useCallback(() => {
    if (!game.logo.element) {
      game.logo.element = document.querySelector('.ah-logo-main')
    }
    // no ball --> reset
    if (game.ball.x < -10000 && game.ball.y < -10000) {
      resetBall()
    }
    // move
    else {
      moveBall()
    }
    // display
    displayBall()
    // out of bounds
    if (isOutOfBounds()) {
      return
    }
    // bounce off of top, bottom, or paddles
    resolveBounces()
    // move right paddle
    moveRightPaddle()
  }, [
    game,
    displayBall,
    isOutOfBounds,
    moveBall,
    moveRightPaddle,
    resetBall,
    resolveBounces,
  ])

  // Set up the game. `useLayoutEffect` will wait for the DOM to render so we're sure to find our elements.
  useLayoutEffect(() => {
    game.logo.element = document.querySelector('.ah-logo-main')
    game.score.element = document.querySelector('#_404_game_score')
    game.rightNavbar.element = document.querySelector('#_404_game_right_navbar')
    game.ball.element = document.querySelector('#_404_game_ball')
    game.leftPaddle.element = document.querySelector('#_404_game_paddle_left')
    game.rightPaddle.element = document.querySelector('#_404_game_paddle_right')

    if (game.logo.element) {
      window.addEventListener('mousemove', movePaddle)
      window.addEventListener('resize', resizeGame)
      game.logo.element.addEventListener('click', startGame)
      game.logo.element.classList.add('pulse')
      resizeGame()
    }

    game.loop = setInterval(iterateGame, GAME_CONSTANTS.loopTime)

    return () => {
      if (game.logo.element) {
        window.removeEventListener('mousemove', movePaddle)
        window.removeEventListener('resize', resizeGame)
        game.logo.element.removeEventListener('click', startGame)
      }
      if (game.loop) {
        clearInterval(game.loop)
      }
    }
  }, [game, movePaddle, resizeGame, startGame, iterateGame])

  return (
    <div id="_404_game">
      <div id="_404_game_score" />
      <div id="_404_game_right_navbar" />
      <div id="_404_game_paddle_left" />
      <div id="_404_game_paddle_right" />
      <div id="_404_game_ball" />
    </div>
  )
}

export default NotFoundGame
