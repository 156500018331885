import { Card } from 'components/Card/Card'
import { Spinner } from 'components/Spinner/Spinner'
import { useState, useEffect } from 'react'

import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { MainstayModal } from 'components/Modal/Modal'
import { Pill } from 'components/Pill/Pill'
import { TextArea } from 'components/TextArea/TextArea'
import { TextInput } from 'components/TextInput/TextInput'
import moment from 'moment'
import 'page/knowledge-base/KnowledgeSource/KnowledgeSourcesTable.scss'
import { PERMISSIONS } from 'util/permissions/permissions'

import {
  GenAIScapeKnowledgeSourceType,
  GenAIScrapeAttemptStatus,
  GenAIScrapeKnowledgeSourceShapeType,
} from 'api'
import { Link } from 'util/routing'
import { ReuploadKnowledgeSourceDocumentModal } from 'page/knowledge-base/KnowledgeSource/KnowledgeBaseScraping'
import { WebData } from 'store/webdata'

export const knowledgeSourceLinkDetails = ({
  id,
  pageNumbers,
  sourceTitle,
  originUrl,
}: {
  id: string | null
  pageNumbers?: (string | number)[] | null
  sourceTitle: string | null
  originUrl: string | null
}) => {
  return {
    displayText: pageNumbers?.length
      ? `${sourceTitle || '[source]'} p. ${pageNumbers.join(', ')}`
      : sourceTitle || '[source]',
    link: id ? `/knowledge-sources?id=${id}` : originUrl,
  }
}

function getKnowledgeSourceCardTitle(
  knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
): string {
  const typeForTitle =
    knowledgeSource.source_type === GenAIScapeKnowledgeSourceType.URL
      ? 'Website'
      : 'Document'
  return (
    knowledgeSource.title || `${typeForTitle}: ${knowledgeSource.origin_url}`
  )
}

const EditKnowledgeSourceModal = ({
  open,
  setOpen,
  knowledgeSource,
  editKnowledgeSource,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  readonly editKnowledgeSource: (
    knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  ) => Promise<void>
}) => {
  const [title, setTitle] = useState(knowledgeSource.title || '')
  const [description, setDescription] = useState(
    knowledgeSource.description || ''
  )
  const [error, setError] = useState('')

  const [editLoading, setEditLoading] = useState(false)

  useEffect(() => {
    // Ensure when the Modal is opened, we're getting up-to-date values from the knowledgeSource prop into input state
    setTitle(knowledgeSource.title || '')
    setDescription(knowledgeSource.description || '')
  }, [open, knowledgeSource.title, knowledgeSource.description])

  const onEditSubmit = async () => {
    setError('')

    if (!title) {
      setError("Title can't be empty")
      return
    }

    setEditLoading(true)

    try {
      await editKnowledgeSource({
        ...knowledgeSource,
        title,
        description: description.length > 0 ? description : null,
      })
    } finally {
      setEditLoading(false)
    }

    onCancel()
  }

  const onCancel = () => {
    setTitle(knowledgeSource.title || '')
    setDescription(knowledgeSource.description || '')
    setOpen(false)
  }
  return (
    <MainstayModal
      show={open}
      text="Edit Knowledge Source"
      onClose={() => setOpen(!open)}
      onSubmit={onEditSubmit}
      disableSubmit={editLoading}
      loading={editLoading}>
      <p>
        The source type and URL cannot be modified. If you wish to change these
        values then archive this knowledge source and make a new one.
      </p>
      <div>
        <span>Title</span>
        <TextInput value={title} onChange={e => setTitle(e.target.value)} />
        <div className="error">{error}</div>
      </div>
      <div className="my-3">
        <span>Description</span>
        <TextArea
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </div>
    </MainstayModal>
  )
}

export const KnowledgeSourcesCard = ({
  knowledgeSource,
  editKnowledgeSource,
  archiveKnowledgeSource,
  triggerScrape,
  downloadFile,
  reuploadFile,
  uploadResult,
}: {
  readonly knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  readonly editKnowledgeSource: (
    knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  ) => Promise<void>
  readonly archiveKnowledgeSource: () => Promise<void>
  readonly triggerScrape: () => Promise<void>
  readonly downloadFile: () => Promise<void>
  readonly reuploadFile: (
    file: File,
    fileName: string,
    knowledgeSourceId: number
  ) => Promise<void>
  readonly uploadResult: WebData<GenAIScrapeKnowledgeSourceShapeType>
}) => {
  const [showingEditModal, setShowingEditModal] = useState(false)
  const [showReuploadModal, setShowReuploadModal] = useState(false)

  const [archiveLoading, setArchiveLoading] = useState(false)
  const [triggerScrapeLoading, setTriggerScrapeLoading] = useState(false)

  const onArchive = async () => {
    setArchiveLoading(true)
    try {
      await archiveKnowledgeSource()
    } finally {
      setArchiveLoading(false)
    }
  }

  const onTriggerScrape = async () => {
    setTriggerScrapeLoading(true)
    try {
      await triggerScrape()
    } finally {
      setTriggerScrapeLoading(false)
    }
  }

  return (
    <>
      <EditKnowledgeSourceModal
        open={showingEditModal}
        setOpen={setShowingEditModal}
        knowledgeSource={knowledgeSource}
        editKnowledgeSource={editKnowledgeSource}
      />

      <ReuploadKnowledgeSourceDocumentModal
        open={showReuploadModal}
        setOpen={setShowReuploadModal}
        knowledgeSourceId={knowledgeSource.id}
        reuploadFile={reuploadFile}
        uploadResult={uploadResult}
      />

      <Card
        className="knowledge-base-scraping-knowledge-source mt-2"
        title={
          <div
            className="d-flex flex-row w-100 justify-content-between"
            data-knowledge-source-id={knowledgeSource.id}>
            <h5 className="text-mainstay-dark-blue">
              {getKnowledgeSourceCardTitle(knowledgeSource)}
            </h5>
            <ActionMenu
              popoverPlacement="bottom-end"
              className="mt-2"
              stopPropagation={true}
              menuItems={[
                {
                  label: 'Edit',
                  icon: <AHIcon name="edit" />,
                  onSelect: () => setShowingEditModal(true),
                  permission: PERMISSIONS.KNOWLEDGE_SOURCE.EDIT,
                },
                knowledgeSource.source_type ===
                GenAIScapeKnowledgeSourceType.S3_DOC
                  ? {
                      label: 'Reupload',
                      icon: <AHIcon name="cloud_upload" />,
                      permission: PERMISSIONS.KNOWLEDGE_SOURCE.CREATE,
                      onSelect: () => setShowReuploadModal(true),
                    }
                  : {
                      label: 'Scrape',
                      icon: (
                        <>
                          {triggerScrapeLoading ? (
                            <Spinner
                              size="sm"
                              className="ml-1 mr-2 stroke-mainstay-dark-blue"
                            />
                          ) : (
                            <AHIcon name="cloud_download" />
                          )}
                        </>
                      ),
                      permission: PERMISSIONS.KNOWLEDGE_SOURCE.CREATE,
                      onSelect: onTriggerScrape,
                    },
                {
                  label: 'Delete',
                  icon: (
                    <>
                      {archiveLoading ? (
                        <Spinner
                          size="sm"
                          className="ml-1 stroke-mainstay-dark-blue"
                        />
                      ) : (
                        <AHIcon name="delete" />
                      )}
                    </>
                  ),
                  onSelect: onArchive,
                  permission: PERMISSIONS.KNOWLEDGE_SOURCE.DELETE,
                },
              ]}
            />
          </div>
        }>
        <table>
          <tbody>
            {knowledgeSource.description && (
              <tr>
                <th>Description</th>
                <td className="pl-2 pt-1 d-flex align-items-center">
                  {knowledgeSource.description}
                </td>
              </tr>
            )}
            {knowledgeSource.source_type ===
            GenAIScapeKnowledgeSourceType.S3_DOC ? (
              <tr>
                <th>File</th>
                <td className="pl-2">
                  <Link to="#" onClick={downloadFile}>
                    {knowledgeSource.original_filename}
                  </Link>
                </td>
              </tr>
            ) : (
              <tr>
                <th>URL</th>
                <td className="pl-2">
                  <a
                    href={knowledgeSource.origin_url || ''}
                    target="_blank"
                    className="m-0">
                    {knowledgeSource.origin_url}
                  </a>
                </td>
              </tr>
            )}
            <tr>
              <th>Last Scraped</th>
              <td className="pl-2">
                {knowledgeSource.scrape_date
                  ? moment(knowledgeSource.scrape_date).format(
                      'YYYY-MM-DD hh:mm A'
                    )
                  : 'Never'}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td className="pl-2 pt-1 d-flex align-items-center">
                <Pill
                  className="w-content"
                  text={cleanScrapeStatus(knowledgeSource.latest_scrape_status)}
                  color={getColorForScrapeStatus(
                    knowledgeSource.latest_scrape_status
                  )}
                />
                {knowledgeSource.latest_scrape_status ===
                  GenAIScrapeAttemptStatus.FAIL && (
                  <span className="pl-2 text-mainstay-spark-red small">
                    {knowledgeSource.fail_reason}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </>
  )
}

const queuedStatuses = [
  GenAIScrapeAttemptStatus.QUEUED.valueOf(),
  GenAIScrapeAttemptStatus.DELETE_QUEUED.valueOf(),
]
const startedStatuses = [
  GenAIScrapeAttemptStatus.STARTED.valueOf(),
  GenAIScrapeAttemptStatus.DELETE_STARTED.valueOf(),
]
const successStatuses = [
  GenAIScrapeAttemptStatus.SUCCESS.valueOf(),
  GenAIScrapeAttemptStatus.DELETE_SUCCESS.valueOf(),
]
export function getColorForScrapeStatus(status: string | null) {
  if (!status) {
    return 'mainstay-spark-red'
  }
  if (queuedStatuses.includes(status)) {
    return 'mainstay-dark-blue-50'
  }
  if (startedStatuses.includes(status)) {
    return 'mainstay-blue-70'
  }
  if (successStatuses.includes(status)) {
    return 'mainstay-success-500'
  }
  return 'mainstay-spark-red'
}

function cleanScrapeStatus(status: string | null) {
  if (!status) {
    return 'UNKNOWN'
  }
  if (queuedStatuses.includes(status)) {
    return 'QUEUED'
  }
  if (startedStatuses.includes(status)) {
    return 'STARTED'
  }
  if (successStatuses.includes(status)) {
    return 'SUCCESS'
  }
  return 'UNKNOWN'
}
