import React, {
  ChangeEvent,
  useRef,
  useEffect,
  MutableRefObject,
  useCallback,
  useState,
  useMemo,
} from 'react'
import { Styles } from 'react-select/lib/styles'

import ReactSelect, { Creatable as ReactSelectCreatable } from 'react-select'

import { Props as CreatableProps } from 'react-select/lib/Creatable'
import { Props as SelectProps } from 'react-select/lib/Select'
import ControlComponent, {
  ControlProps,
} from 'react-select/lib/components/Control'
import { NoticeProps } from 'react-select/lib/components/Menu'
import Fuse from 'fuse.js'
import classnames from 'classnames'
import * as _ from 'lodash'
import { isArray } from 'util'

import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { useAutoSuggestHandlers } from 'util/hooks'
import { ValueType } from 'react-select/lib/types'
import { SelectComponents } from 'react-select/lib/components'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'
import { DropdownIndicator } from 'components/SettingsEscalation/reactSelectUtils'

export interface IAutoSuggestOption<T = {}> {
  label: string
  value: string
  data?: T
}
export interface IAutoSuggestOptionsProps<T> {
  options: IAutoSuggestOption<T>[]
}

export interface IAutoSuggestProps<T = {}> {
  isValid?: boolean
  error?: string
  touched?: boolean
  disabled?: boolean
  multi?: boolean
  isClearable?: boolean
  loading?: boolean
  label?: string
  name?: string
  id?: string
  limit?: number
  closeOnSelect?: boolean
  creatable?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  placeholder?: string
  value?: string[] | string
  className?: string
  selectComponents?: Partial<SelectComponents<IAutoSuggestOption<T>>>
  filterOption?: (option: IAutoSuggestOption<T>, rawInput: string) => boolean
  onCreate?: (value: string) => void
  onChange?: (
    event: ChangeEvent<{
      value: string | string[]
      name: string
      data: ValueType<IAutoSuggestOption<T>>
    }>
  ) => void
  onBlur?: (event: { target: { name?: string } }) => void
  menuIsOpen?: boolean
  onClick?: () => void
  styles?: Partial<Styles>
  readOnly?: boolean
  wrapperClassName?: string
  eventObject?: string
}

interface IAutoSuggestRenderProps<T> {
  render: (renderProps: CreatableProps<IAutoSuggestOption<T>>) => JSX.Element
}

export interface IFormGroupProps {
  label?: string
  id?: string
  name?: string
  isValid?: boolean
  touched?: boolean
  error?: string
  className?: string
  eventAction?: EventAction
  eventObject?: string
}

export const FormGroup: React.FunctionComponent<IFormGroupProps> = props => {
  const classNames = classnames(
    'form-group',
    {
      'is-invalid': props.touched && !props.isValid,
    },
    props.className
  )
  return (
    <EventTrackerAttrAdder
      eventAction={props.eventAction}
      eventObject={props.eventObject}>
      <div className={classNames}>
        {props.label && (
          <label htmlFor={props.id || props.name}>{props.label}</label>
        )}
        {props.children}
        {!props.isValid && props.touched && (
          <div className="invalid-feedback">{props.error}</div>
        )}
      </div>
    </EventTrackerAttrAdder>
  )
}

export class NoOptionsMessage<T> extends React.PureComponent<NoticeProps<T>> {
  render() {
    // tslint:disable no-unsafe-any
    const text = this.props.selectProps.inputValue
      ? `No results for "${this.props.selectProps.inputValue}"`
      : 'No results'
    // tslint:enable no-unsafe-any

    return <div className="text-center text-muted p-2 font-italic">{text}</div>
  }
}

interface IControlOwnProps {
  readOnly?: boolean
}

export class Control<T> extends React.PureComponent<
  ControlProps<T> & IControlOwnProps
> {
  render() {
    const { children, ...rest } = this.props
    return (
      <ControlComponent {...rest}>
        {!this.props.readOnly && (
          <AHIcon
            name="search"
            className="large pl-1 text-mainstay-dark-blue-80"
          />
        )}
        {children}
      </ControlComponent>
    )
  }
}

export class IconlessControl<T> extends React.PureComponent<ControlProps<T>> {
  render() {
    const { children, ...rest } = this.props
    return <ControlComponent {...rest}>{children}</ControlComponent>
  }
}

export const formatCreateLabel = (v: string): React.ReactNode => {
  return (
    <div className="text-primary d-flex align-items-center pointer">
      <AHIcon className="large mr-2" name="add_circle_outline" />
      {`Add "${v}"`}
    </div>
  )
}

export class Creatable<T> extends React.PureComponent<CreatableProps<T>> {
  render() {
    const { components, ...rest } = this.props
    return (
      <ReactSelectCreatable<T>
        classNamePrefix="react-select"
        components={{ Control, NoOptionsMessage, ...components }}
        formatCreateLabel={formatCreateLabel}
        {...rest}
      />
    )
  }
}

export class Select<T> extends React.PureComponent<SelectProps<T>> {
  render() {
    const { components, ...rest } = this.props
    return (
      <ReactSelect<T>
        classNamePrefix="react-select"
        components={{ Control, NoOptionsMessage, ...components }}
        {...rest}
      />
    )
  }
}

type AutoSuggestContainerProps<T> = Pick<
  IAutoSuggestProps<T> &
    IAutoSuggestOptionsProps<T> &
    IAutoSuggestRenderProps<T>,
  | 'render'
  | 'options'
  | 'limit'
  | 'onChange'
  | 'name'
  | 'id'
  | 'onCreate'
  | 'onBlur'
  | 'value'
  | 'menuPlacement'
>

/**
 * @deprecated - use react-select. This component isn't accessible.
 */
export function AutoSuggestContainer<T>({
  render,
  options,
  limit,
  onChange,
  name,
  id,
  onCreate,
  onBlur,
  value,
  menuPlacement,
}: AutoSuggestContainerProps<T>) {
  const searchIndex: MutableRefObject<Fuse<IAutoSuggestOption<T>>> = useRef(
    new Fuse(options, {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['label', 'value'],
    })
  )

  const {
    handleBlur,
    handleChange,
    handleCreate,
    getOptionLabel,
    getOptionValue,
  } = useAutoSuggestHandlers({ onBlur, onChange, onCreate, id, name })

  const [filteredOptions, setFilteredOptions] = useState(
    options.slice(0, limit)
  )

  useEffect(() => {
    setFilteredOptions(options.slice(0, limit))
  }, [limit, options])

  useEffect(() => {
    if (searchIndex.current) {
      searchIndex.current.setCollection(options)
    }
  }, [options])

  const handleInputChange = useCallback(
    (inputValue: string) => {
      let filteredOptions = searchIndex.current
        ? searchIndex.current.search(inputValue).slice(0, limit)
        : options
      if (filteredOptions.length === 0) {
        filteredOptions = options
      }
      setFilteredOptions(filteredOptions)
      return inputValue
    },
    [options, limit]
  )

  const autoCompleteValue = useMemo(() => {
    if (isArray(value)) {
      return options.filter(x => value.includes(x.value))
    }
    return options.find(x => x.value === value)
  }, [value, options])

  return render({
    value: autoCompleteValue,
    onInputChange: handleInputChange,
    getOptionLabel,
    getOptionValue,
    menuPlacement,
    onChange: handleChange,
    onCreateOption: handleCreate,
    options: filteredOptions,
    onBlur: handleBlur,
  })
}

AutoSuggestContainer.defaultProps = {
  limit: Number.MAX_SAFE_INTEGER,
  closeOnSelect: false,
}

function AutoSuggest<T>(
  props: IAutoSuggestOptionsProps<T> & IAutoSuggestProps<T>
) {
  const className = classnames(
    'form-control',
    'p-0',
    'border-0',
    {
      'is-invalid': !props.isValid,
    },
    props.className
  )

  return (
    <AutoSuggestContainer
      {...props}
      render={autoSuggestProps => {
        return (
          <FormGroup
            name={props.name}
            id={props.id}
            error={props.error}
            isValid={props.isValid}
            touched={props.touched}
            eventObject={props.eventObject}
            label={props.label}>
            {props.creatable ? (
              <Creatable<IAutoSuggestOption<T>>
                id={props.id || props.name}
                name={props.name}
                isMulti={props.multi}
                isClearable={props.isClearable}
                isLoading={props.loading}
                isDisabled={props.disabled}
                placeholder={props.placeholder}
                closeMenuOnSelect={props.closeOnSelect}
                className={className}
                components={{
                  ...props.selectComponents,
                  DropdownIndicator,
                  IndicatorSeparator: undefined,
                }}
                {...autoSuggestProps}
              />
            ) : (
              <Select<IAutoSuggestOption<T>>
                menuIsOpen={props.menuIsOpen}
                id={props.id || props.name}
                name={props.name}
                isMulti={props.multi}
                isClearable={props.isClearable}
                isDisabled={props.disabled}
                placeholder={props.placeholder}
                isLoading={props.loading}
                closeMenuOnSelect={props.closeOnSelect}
                className={className}
                components={{
                  ...props.selectComponents,
                  DropdownIndicator,
                  IndicatorSeparator: undefined,
                }}
                {...autoSuggestProps}
              />
            )}
          </FormGroup>
        )
      }}
    />
  )
}

export default AutoSuggest
