export const PERMISSIONS = {
  AUDIENCE: {
    VIEW: 'audience/view',
    CREATE: 'audience/create',
    EDIT: 'audience/edit',
    DELETE: 'audience/delete',
  },
  CAMPAIGN: {
    CREATE: 'campaign/create',
    VIEW: 'campaign/view',
    EDIT: 'campaign/edit',
    DELETE: 'campaign/delete',
  },
  CONVERSATION: {
    VIEW: 'conversation/view',
    LIVE_CHAT: 'conversation/live_chat',
  },
  TRIAGE: {
    VIEW: 'triage/view',
  },
  CONTACT: {
    CREATE: 'contact/create',
    VIEW: 'contact/view',
    EDIT: 'contact/edit',
    DELETE: 'contact/delete',
  },
  DASHBOARDS: {
    READ: 'dashboards/read',
    WRITE: 'dashboards/write',
  },
  IMPORT: {
    VIEW: 'import/view',
  },
  FALLBACK: {
    EDIT: 'fallback/edit',
    VIEW: 'fallback/view',
  },
  INTEGRATION: {
    VIEW: 'integration/view',
  },
  REPORT: {
    VIEW: 'report/view',
  },
  SETTINGS: {
    VIEW: 'settings/view',
    EDIT: 'settings/edit',
  },
  SCRIPT: {
    VIEW: 'script/view',
    EDIT: 'script/edit',
    CREATE: 'script/create',
    DELETE: 'script/delete',
    TEST: 'script/test',
    DUPLICATE: 'script/duplicate',
  },
  UNDERSTANDING: {
    APPROVE: 'understanding/approve',
    VIEW: 'understanding/view',
    CREATE: 'understanding/create',
    EDIT: 'understanding/edit',
    DELETE: 'understanding/delete',
  },
  USER: {
    CREATE: 'user/create',
    EDIT: 'user/edit',
    VIEW: 'user/view',
    DELETE: 'user/delete',
    SELF: 'user/self',
  },
  KNOWLEDGE_SOURCE: {
    CREATE: 'knowledge_source/create',
    DELETE: 'knowledge_source/delete',
    EDIT: 'knowledge_source/edit',
    VIEW: 'knowledge_source/view',
  },
}
