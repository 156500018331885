import {
  MessageStatus,
  ApprovalStatus,
  SenderType,
} from 'store/triage/chat/messagesReducer'
import { IImportReport } from 'store/import-reports/reducer'
import { ITemplateCollection } from 'api'
import {
  ICreateInstitutionRequestData,
  IPurchasePhoneNumbersRequestData,
} from 'api/request'
import {
  ICampaignScriptStep,
  ICampaignScriptCustomLogic,
} from 'store/campaign-scripts/reducer'
import { IPasswordConstraint } from 'util/auth'
import { ContactAttributeType } from 'store/personalization/contactAttributes/selectors'
import { DataTypes } from 'components/AttributeMapper/utils'
import { Insights } from 'components/TrendsV2/types'
import * as t from 'io-ts'
import * as et from 'io-ts/Type'

export interface IDialogSettings {
  unknownStudentDialogBotOn?: string
  unknownStudentDialogBotOff?: string
  knownNonContactedStudentDialog?: string
  botOffDialog?: string
  createAndIntroDialog?: string
  botOffNoDialog?: boolean
  finishedConversationDialog?: string
  aiDisabled?: boolean
  aiResponseMessageDisabled?: boolean
  escalationsDisabledDuringLiveChat?: boolean
}

import { FeaturesType } from 'components/Feature/Feature'
import { IField } from 'components/ContactUploadFlow/reducer'
import { IDataRow } from 'components/DataTable/DataTable'
import {
  ConditionType,
  Operator as EscalationOperator,
} from 'store/escalation/selectors'
import { InstitutionAttributeType } from 'store/personalization/institutionAttributes/selectors'

import { IDialogLabel } from 'store/dialog-labels/reducer'
import { Channel, TransportId } from 'store/transport'
import { IFilteredContact } from 'store/contact-segments/reducer'
import { ComparisonOperator } from 'components/ContactFilterBuilder/SelectOperator'
import { CombinationOperator } from 'components/ContactFilterBuilder/formUtils'
import { fromEnum } from 'util/schema'
import { IRecurrenceSettings } from 'store/campaign-scheduler/reducer'
import { IPhoneNumber } from 'page/SettingsSMS'
import { IMessagingStatusOption } from 'components/ContactPanel/EditableContactPanel'
import { IMemberInstitution } from 'store/triage/profile/reducer'
import { ResponsesFilters } from 'store/knowledgeSeeder/responsesFilterTypes'

export interface IInstitution {
  id: string
  abbr: string
  aiSubject: string | null
  animal: string
  askInternet: boolean | null
  botType?: string
  botStatus: string
  botUseCase?: string
  globalAppropriateHourSettings: ICampaignSendWindow
  cohorts?: {
    [key: string]: {
      displayName: string
      id: string
      twilioMessagingServiceSid: string
    }
  }
  collection: string
  collegeId: string
  counselors: object[]
  channels: readonly Channel[]
  createdAt: string
  csvTransformPipeline: string[]
  customQueryFields: string[]
  dateAccepted: string
  dateFormat: string
  dateScholarship: string
  departments: object[]
  defaultCounselorId?: number
  dialogSettings?: IDialogSettings
  disabledFeatures: string[]
  displayName: string | null
  emailDomains: string[]
  emailPrefix: string
  readonly filteredChannels?: ReadonlyArray<TransportId>
  hashtag: string | null
  hubspotId: string | undefined
  hubspotParentId: string | undefined
  hubspotName: string | undefined
  infoGatheringBot: string | null
  institutionType: string | null
  intentDeadline: Date | null
  internal: boolean
  internalFieldMapping: object
  internalTextingOnly: boolean
  introKnownProspectBotPhoto: string | null
  isManaged?: boolean
  ipedsId?: string
  linkAcceptAid: string | null
  linkAdmit: string | null
  linkCounselors: string | null
  linkIncompleteApplication: string | null
  linkFafsa: string | null
  linkFinAid: string | null
  linkIntentToEnroll: string | null
  linkOrientationChecklist: string | null
  linkOrientationInfo: string | null
  linkTourSignUp: string | null
  linkVisitTips: string | null
  mediaCongrats: string | null
  mediaMascot: string | null
  messagingService: string
  messagingServiceSid: string | null
  messagingType: string[]
  name: string
  oliName: string
  passwordConstraints: IPasswordConstraint[]
  phoneFinAid: string | null
  prependCounselorResponse?: string | null
  primaryBrandColor: string | null
  promptFilter: string | null
  salesforceIntegration?: {
    syncEnabled: boolean
  }
  synonyms: {
    botname: string[]
    id: string[]
    institution: string[]
  }
  timeZone: string
  tuitionDueDate: Date | null
  voiceMail: string | null
  enabledFeatures?: FeaturesType[]
  webChatUnlocked?: boolean
  webhookSystemName?: string
  availableRegions?: string[]
  regions_map?: {
    [regionId: string]: string
  }
  regionField?: { id: number; name: string }
  ssoEntityID?: string
  ssoDomain?: string
  passwordBasedAuthDisabled?: boolean
}

export type ILimitedInstitution = Pick<
  IInstitution,
  | 'id'
  | 'name'
  | 'abbr'
  | 'botType'
  | 'channels'
  | 'messagingService'
  | 'oliName'
  | 'displayName'
  | 'enabledFeatures'
  | 'availableRegions'
  | 'internal'
  | 'isManaged'
  | 'dateFormat'
  | 'timeZone'
  | 'webhookSystemName'
  | 'globalAppropriateHourSettings'
>

export interface IInstitutionResponseData
  extends Omit<ILimitedInstitution, 'globalAppropriateHourSettings'> {
  campaignSendWindow: ICampaignSendWindow
}

const PasswordConstraintShape = {
  type: t.union([t.literal('regex'), t.literal('blacklist')]),
  matchType: t.union([
    t.union([t.literal('entireString'), t.literal('subString')]),
    t.undefined,
  ]),
  description: t.string,
  regex: t.union([t.string, t.undefined]),
  all: t.union([t.array(t.string), t.undefined]),
  map: t.union([
    t.array(t.type({ email: t.string, values: t.array(t.string) })),
    t.undefined,
  ]),
}

export const LimitedInstitutionShape = {
  id: t.string,
  collegeId: t.string,
  name: t.string,
  abbr: t.string,
  botType: t.union([t.string, t.undefined]),
  channels: t.array(
    t.union([
      t.literal('sms'),
      t.literal('slack'),
      t.literal('facebook'),
      t.literal('web_bot'),
    ])
  ),
  messagingService: t.string,
  oliName: t.string,
  displayName: et.nullable(t.string),
  enabledFeatures: t.union([
    t.array(fromEnum('FeaturesType', FeaturesType)),
    t.undefined,
  ]),
  availableRegions: t.union([t.array(t.string), t.undefined]),
  passwordConstraints: t.array(t.type(PasswordConstraintShape)),
  internal: t.boolean,
  salesforceIntegration: t.union([
    t.type({
      syncEnabled: t.boolean,
    }),
    t.undefined,
  ]),
  dateFormat: t.string,
}

interface ICampaignStatusResponse {
  /** name of the corresponding Dialog */
  readonly name?: string
  readonly started: boolean
  readonly started_at: null | string
  readonly finished: boolean
  readonly finished_at: null | string
}

export interface IContactResponseData {
  id: string
  name: {
    first: string
    last: string
    middle: string
    full: string
  }
  phone: string | null
  _testUser: boolean | null
  _contactSettings: {
    canText: boolean
    wrongNumber: boolean
    frozenById?: string
    frozenByName?: string
    lastFrozen?: Date
  } | null
  collegeId: string
  readonly _dialog: null | {
    readonly id: string
    readonly state: string
  }
  readonly lock: {
    expiresAt?: Date
    ownerId?: string
    ownerName?: string
    timeLeftSeconds?: number
  }
  readonly campaignHistory: null | {
    readonly [key: string]: ICampaignStatusResponse
  }
  readonly importSegmentLabels: ReadonlyArray<string>
  readonly enrollmentId: null | string
  readonly created: string
  readonly crmId: string
  readonly webBot: null | {
    webBotEnabled: boolean
    id: string
    lastActive: string
  }
  readonly isArchived?: boolean
  readonly smsInfo: {
    readonly lastIncomingMessageAt: null | string
    readonly lastIncomingMessageBody: null | string
    readonly lastIncomingMessageId: null | string
    readonly lastMessageAt: null | string
    readonly lastMessageBody: null | string
    readonly lastMessageId: null | string
    readonly lastOutgoingMessageAt: null | string
    readonly lastOutgoingMessageBody: null | string
    readonly lastOutgoingMessageId: null | string
  } | null
  readonly attributeValues?: {
    readonly contactAttribute: number
    readonly attributeName: string
    readonly id: number
    readonly mongoContactId: string
    readonly value: string
  }[]
  readonly messagingStatus?: { [k: string]: IMessagingStatusOption['value'] }
  readonly previouslyOptedOutOfSMS?: boolean
}

interface IWorkflowResponse {
  readonly id: string
  readonly userId: string
  readonly workflow: string
  readonly step: string
  readonly response: {
    readonly clean: number
    readonly value: string
  }
}

export interface IDialog {
  readonly id: string
  readonly description: string
  readonly humanName: string
  readonly initialState: string
  readonly hidden?: boolean
  readonly expirationLength?: number
  readonly scriptHidden?: boolean
  readonly isInteractive?: boolean
}

export interface ICampaignHistoryDetailsResponseData
  extends ICampaignDetailResponseData {
  readonly responses?: ReadonlyArray<IWorkflowResponse>
  readonly workflow_steps?: ReadonlyArray<ICampaignScriptStep>
  readonly workflow: IDialog
}

export interface ICampaignDetailsResponseData {
  readonly campaign: ICampaignHistoryDetailsResponseData
  readonly workflow_steps?: ReadonlyArray<ICampaignScriptStep>
  readonly aggregate_workflow_steps?: ReadonlyArray<ICampaignScriptStep>
  readonly gen_report: boolean
}

export interface IAggregateData extends IEngagementCounts {
  readonly countCampaignsSent: number
  readonly totalRecipients: number
  readonly startDate: string
  readonly parentId: string
  readonly totalDistinctResponses: number
}

export interface IRecurringCampaignHistoryDetailsResponseData
  extends ICampaignHistoryDetailsResponseData {
  readonly recurrenceSettings: IRecurrenceSettings
  readonly sendDate: string
}

export interface IRecurringCampaignDetailsResponseData
  extends ICampaignDetailsResponseData {
  readonly campaign: IRecurringCampaignHistoryDetailsResponseData
}

export interface IRecurringCampaignsListItem {
  readonly id: string
  readonly sent_date: string
  readonly count_active_recipients: number
  readonly count_passive_recipients: number
  readonly count_opt_outs: number
  readonly count_pauses: number
  readonly count_delivery_failures: number
}

export interface IRecurringCampaignsListResponseData
  extends IPaginatedResponse<IRecurringCampaignsListItem[]> {}

export interface ICampaignImportResponseData {
  readonly import_report_id: string
  readonly import_name: string
  readonly import_date: string
  readonly import_user: string
  readonly import_label: string
  readonly contact_labels: string[]
  readonly count_contacts: number
}

export interface IBaseCampaignResponseData {
  readonly id: string
  readonly distinctResponses?: number
  readonly name: string
  readonly description: string
  readonly isRespondable: boolean
  readonly countEligibleUsersProcessed: number
}

export interface ICampaignHistoryResponseData
  extends IBaseCampaignResponseData {
  readonly sidebarFilter: string
  readonly scheduledAt: string
  readonly lastSentRecurringCampaign: string | null
  readonly started?: boolean
  readonly recurring: boolean
  readonly recurrenceSettings: IRecurrenceSettings
  readonly region?: string | null
  isParentRecurringInstance?: boolean
}

export interface ICampaignTriggerResponseData
  extends IBaseCampaignResponseData {
  readonly enabled: boolean
  readonly lastSentDate: string | null
  readonly dialogId: string
  readonly contactFilterId: number
  readonly timeDelay: number
  readonly sendCampaignOnce: boolean
  readonly region?: string | null
}

export interface ICampaignTriggerDetailsResponseData {
  readonly trigger: ICampaignTriggerDetail
  readonly workflow_steps?: ReadonlyArray<ICampaignScriptStep>
}

export interface ICampaignTriggerDetail
  extends Omit<
    ICampaignTriggerResponseData,
    'lastSentDate' | 'countEligibleUsersProcessed' | 'isRespondable'
  > {
  readonly dialog: IDialog
  readonly isInteractive: boolean
  readonly createdAt: string
  readonly engagementData: IEngagementCounts
  readonly filterName: string
  readonly creationInfo: ICreationInfo
}

export interface IDTCQueueEvent {
  readonly _id: string
  readonly contact_id: string
  readonly phone: string | null
  readonly crm_id: string | null
  readonly email: string | null
  readonly name_first: string | null
  readonly name_last: string | null
  readonly entered_audience: string
  readonly total_count: number
}

export interface IDTCQueueResponseData extends Array<IDTCQueueEvent> {}

export interface ICampaignByDialogCampaignObject {
  readonly workflow: IDialog
  readonly expirationInMins: number
  readonly urlPath: string
  readonly engagementData: IEngagementCounts
  readonly currentDialogId: string | null
  readonly creationInfo: ICreationInfo
  readonly scheduledAt: string | null
  readonly name: string
}
export interface ICampaignByDialogDetailsResponseData {
  readonly campaign: ICampaignByDialogCampaignObject
  readonly workflow_steps?: ReadonlyArray<ICampaignScriptStep>
}

export interface IEngagementCounts {
  readonly passiveUsersCount: number
  readonly activeUsersCount: number
  readonly resultingSoftStopUsersCount: number
  readonly resultingHardStopUsersCount: number
  readonly deliveryFailureUsersCount: number
  readonly messagedUsersCount: number
  readonly totalIntendedRecipients: number
  readonly countEligibleUsersProcessed: number
  readonly totalDistinctResponses?: number
  readonly countCampaignsSent?: number
}

export interface ICreationInfo {
  readonly lastName?: string
  readonly firstName?: string
  readonly createdAt?: string
}

export interface ICampaignDetailResponseData
  extends ICampaignHistoryResponseData {
  readonly messagingService: string
  readonly users: number
  readonly optOutUsers: number
  readonly deliveryFailureUsers: number
  readonly pendingUsers: number
  readonly workflow: IDialog
  readonly importReportId: null | string
  readonly recipientLabel: null | string
  readonly filterName: null | string
  readonly completed: boolean
  readonly isIntro: boolean
  readonly expirationInMins: number | null
  readonly engagementData: IEngagementCounts
  readonly aggregateData: IAggregateData | null
  readonly contactFilter: number | undefined
  readonly creationInfo: ICreationInfo
  readonly countEligibleUsersProcessed: number
  readonly sendCampaignOnce?: boolean
  readonly region?: string | null
}

export interface ICampaignReportPollResponse {
  readonly status: CELERY_STATES
  readonly progress: number
  readonly report_url: string
}

export interface IGenerateCampaignResponse {
  readonly task_id: string
}

export interface IDialogForUnknownContact {
  readonly id: string
  readonly name: string | null
  readonly prompt: string | null
}

export interface ICampaignSendWindow {
  readonly hoursStart: number | null
  readonly minutesStart: number | null
  readonly hoursEnd: number | null
  readonly minutesEnd: number | null
  readonly timezone: string
}

export interface ISMSSettingsResponse {
  readonly enableSMS: boolean
  readonly areaCodes: ReadonlyArray<string> | null
  readonly phoneNumbers: Array<IPhoneNumber> | null
  readonly voiceErrorMessage: string | null
  readonly acceptMessagesFromUnknownNumbers: boolean
  readonly dialogForUnknownContactsFilterOff: IDialogForUnknownContact | null
  readonly dialogForUnknownContactsFilterOn: IDialogForUnknownContact | null
  readonly defaultForUnknownContacts: IDialogForUnknownContact | null
  readonly disableIntroForUnknownSmsContacts?: boolean
  readonly dialogForUnknownContacts:
    | IDialogForUnknownContact
    | 'unset'
    | null
    | undefined
  readonly campaignSendWindow: ICampaignSendWindow
}

interface IUpload {
  readonly id: number
  readonly href: string
}
interface IIntroDialog {
  readonly id: string
  readonly name: string
  readonly prompt: string | null
  readonly humanName?: string
}
export interface IWebBotSettingsResponse {
  readonly id: number
  readonly auth_enabled: boolean
  readonly bot_name: string
  readonly name: string
  readonly archived: boolean
  readonly bot_title: string
  readonly college_id: string
  readonly custom_badge_enabled: boolean
  readonly custom_badge: IUpload | null
  readonly embed_token: string
  readonly integration_enabled: boolean
  readonly intro_dialog_enabled: boolean
  readonly intro_dialog: IIntroDialog | null
  readonly intro_message: string
  readonly theme_color: string
  readonly integration_locked: boolean
  readonly popup_enabled: boolean
  readonly popup_time_seconds: number
  readonly popup_message: string
  readonly button_label_enabled: boolean
  readonly button_label_text: string
  readonly quick_actions_enabled: boolean
}

interface IMessageMediaFile {
  readonly url: string
  readonly contentType?: string
}

// Corresponds to the ChannelError class in the backend
interface IChannelFailure {
  // Channel's error code
  code: number
  // Facebook has error sub-codes
  subcode: number
  // Message will be null if the error code is not recognized on the backend
  description: string | null
}

export interface IMessageResponseData {
  id: string
  smsLogId?: string
  body: string
  created: string
  inReplyTo: string
  author: string
  authorName: string
  recipient: string
  senderType: SenderType
  messageStatus: MessageStatus
  approvalStatus: ApprovalStatus
  lastModifiedBy: string
  lastModifiedByName: string | null
  topic?: string
  matchString?: string
  matchScore?: number
  inputContext?: string
  readonly sqsId?: string
  readonly mediaFiles: ReadonlyArray<IMessageMediaFile>
  // Will be non-null when there a failure
  readonly failure: IChannelFailure | null
  readonly aiLogId: string | null
  readonly lastModifiedAt: string | null
  readonly isPartOfCampaign: boolean | null
  readonly translations?: {
    [lang: string]: string
  }
  sampleQuestion: string | null
  understandingId: string | null
  understandingURL: string | null
  templatedAnswer: string | null
  attributeNames: {
    institution?: {
      [id: number]: string
    }
  } | null
  contactFilterName: string | null
  source?: 'marshall' | 'ai-matcher-1'
  incoming?: boolean
  dialogStateId: string | null
}

export interface IExchangeSetResponse {
  contactId: string
  exchanges: {
    incoming: IMessageResponseData
    outgoing: IMessageResponseData
  }[]
}

export interface IInboxResponseData
  extends IPaginatedResponse<{
    contacts: IContactResponseData[]
    exchangeSets: IExchangeSetResponse[]
  }> {}

export interface IBrandedApplicantConversationMessage {
  readonly auto: boolean
  readonly body: string
  readonly created: string
  readonly email: string
  readonly logId: string
  readonly sender: 'student' | 'college' | 'admithub'
  readonly unverified: null
}

export interface IBrandedApplicantConversation {
  readonly id: string
  readonly applicantId: string
  readonly brandedCollegeId: string
  readonly userId: string
  readonly messagingService: string
  readonly created: string
  readonly handled: boolean
  readonly messages: ReadonlyArray<IBrandedApplicantConversationMessage>
}

export interface IConversationReset {
  readonly contactId: string
  readonly lastMessageId: string
  readonly resetTime: Date
}

export interface IConversationResponseData {
  readonly contact: IContactResponseData
  readonly messages: ReadonlyArray<IMessageResponseData>
  readonly handledBy: ReadonlyArray<IUserResponseData>
  readonly brandedApplicantConversations: ReadonlyArray<
    IBrandedApplicantConversation
  >
  readonly conversationResets: ReadonlyArray<IConversationReset>
}

interface IQuestionGroupResponseData {
  readonly id: string
  inputContexts: string[]
  fuzzyQuestions: string[]
  exactQuestions: string[]
}

interface IAnswerGroupResponseData {
  messagingService: string
  answers: string[]
}

export interface ICreateUnderstandingV2ResponseData {
  readonly id: string
  readonly topic: string
  readonly sampleQuestion: string
}

export interface IQuestionSearchResponseData {
  results: Array<[string, string, string, string]>
  topicToUnderstandingId: {
    [id: string]: string
  }
}

export interface IUpdateProfileResponseData {
  contact: IContactResponseData
}

export interface IUserResponseData {
  id: string
  createdAt: string
  profile: {
    name: string
    firstName?: string
    lastName?: string
  }
  emails: {
    address: string
  }[]
  presence: {
    route: {
      path: string
      date_recorded: string
    } | null
  }
  region: string | null
  admitHubUser: boolean
  engineeringEmployee: boolean
  currentInstitution: string
  currentInstitutionObj: IInstitutionResponseData
  readonly enabledFeatures?: ReadonlyArray<FeaturesType>
  permissions: string[]
  permissionRoles: string[]
  readonly pinnedConversationIds?: string[]
  readonly isStaff: boolean
  readonly memberInstitutions: IMemberInstitution | null
}

export interface IGetUsersByLocationResponseData {
  firstName: string
  lastName: string
  isActive: boolean
}

export interface IUpdateUserLocationResponseData {
  success: boolean
  message: string
}

export interface ICampaignScriptscriptHistory {
  isMainstayStaff?: boolean
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
}
export interface IInstitutionWithInboxCountResponseData {
  id: string
  name: string
  displayName: string
  botName: string
  label: string
  count: number
}

export interface IFetchKnowledgeSeedsResponseData {
  knowledge: Array<{
    sampleQuestion: null | string
    sampleAnswer: null | string
    id: string
    topic: string
    category: string
    subCategory: string
    hasApprovedAnswer: boolean
    fuzzyQuestionCount?: number | null
    answersCount?: number
    suggestedAnswersCount?: number
    sampleDialogId?: string
    sampleDialogName?: string
    sampleAnswerType?: 'INSTITUTION' | 'SUGGESTION' | 'NO_ANSWER'
    sampleAnswerIsGenerative: boolean
    responsesFilters: ResponsesFilters
  }>
}
export interface ICampaignScriptResponse {
  description: string
  topic: string | null
  audience: string | null
  timing: string | null
  lifeCycle: string | null
  principle: string | null
  realImpact: string | null
  humanName: string
  id: string
  initialState: string
  draft: boolean
  name: string
  messagingService: string
  reminders?: { prompt: string; after: number }[]
  expirationLength?: number
  createdAt: string
  createdBy?: string
  isMainstayStaff?: boolean
  updatedAt?: string
  scriptHistory?: ICampaignScriptscriptHistory[]
  custom: boolean
  customLogic: null | ICampaignScriptCustomLogic[]
  sentToUsers?: boolean
  published?: boolean
  labels?: IDialogLabel[]
  collections?: ITemplateCollection[]
  firstMessage?: string
}

export interface IAttributeMapStatus {
  // TODO (neckenth): remove redundant `name` field
  name: string
  fromName: string
  fromId: number
  toId: number | null
  toName: string | null
  dataType: ContactAttributeType
}

export interface IVerifyScriptCopyResponse {
  institution: IAttributeMapStatus[]
  contact: IAttributeMapStatus[]
}

export interface ICampaignScriptPreviewListResponse {
  count: number
  results: ReadonlyArray<ICampaignScriptPreviewResponse>
}
export interface ITemplateListResponse {
  count: number
  results: ReadonlyArray<ITemplateScriptPreview>
}
export interface ITemplateScriptPreview {
  readonly id: string
  readonly name: string
  readonly humanName: string
  readonly description: string
  readonly category?: string
  readonly topic?: string
  readonly lifeCycle?: string
  readonly audience?: string
  readonly timing?: string
  readonly principle?: string
  readonly realImpact?: string
  readonly firstMessage: string
  readonly createdAt: string
  readonly custom: boolean
  readonly published?: boolean
  readonly sentToUsers?: boolean
  readonly isInteractive: boolean
  readonly labels: IDialogLabel[]
  readonly collections: ITemplateCollection[]
}
export interface ICampaignScriptPreviewResponse {
  id: string
  name: string
  humanName: string
  description: string
  category?: string
  topic?: string
  lifeCycle?: string
  audience?: string
  timing?: string
  principle?: string
  realImpact?: string
  firstMessage: string
  createdAt: string
  custom: boolean
  published?: boolean
  sentToUsers?: boolean
  isInteractive: boolean
  labels?: IDialogLabel[]
  frequencyCount?: number
}

export interface ICampaignScriptRetrieveResponse {
  dialog: ICampaignScriptResponse
  states: ICampaignScriptStep[]
}

export interface IUploadStudentGroupResponseData {
  task_id: string
}

// see: https://github.com/celery/celery/blob/b2668607c909c61becd151905b4525190c19ff4a/celery/states.py#L151

// TODO (Manan) - Can we get rid of the CELERY_STATES type below?
export enum CeleryStates {
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Retry = 'RETRY',
  Revoked = 'REVOKED',
}

export type CELERY_STATES =
  | 'PENDING'
  | 'RECEIVED'
  | 'STARTED'
  | 'SUCCESS'
  | 'FAILURE'
  | 'RETRY'
  | 'REVOKED'

export interface IUploadStudentGroupPollingResponseData {
  // 'PROGRESS' is a custom state
  status: CELERY_STATES | 'PROGRESS'
  importReport: null | IImportReport
  detail: string
  progress: number
}

export interface IFilteredContactsResponseData
  extends IPaginatedAsyncResponse<IFilteredContact[]> {
  total_recipient_count: number
  attribute_names: string[]
  additional_contact_fields: string[]
}

export function isSuccessfulAsyncResponse<T>(
  data: IPaginatedAsyncResponse<T>
): data is IPaginatedAsyncResponse<T> & { results: T } {
  return 'results' in data && data.status === CeleryStates.Success
}

export interface IOperator {
  readonly name:
    | 'CONTAINS'
    | 'NOT_CONTAINS'
    | 'EQUALS'
    | 'NOT_EQUALS'
    | 'LT'
    | 'GT'
    | 'EXISTS'
    | 'DNE'
  readonly label: string
}

export interface IFetchQueryableFieldResponse {
  readonly name: string
  readonly kind:
    | 'JSON_STRING_ARRAY'
    | 'TEXT'
    | 'DATE'
    | 'NUMBER'
    | 'BOOLEAN'
    | 'PHONE'
    | 'EMAIL'
  readonly operators: IOperator[]
}

export interface IImportLabelResponse {
  readonly id: string
  readonly csvName: string
  readonly reportName: string
  readonly numContactsCreated: number
  readonly numContactsUpdated: number
  readonly numContactsPhoneUpdated: number
  readonly numTotalContacts: number
  readonly labels: { name: string; auto: boolean }[]
  readonly createdAt: string
}

export enum ActivityLogAction {
  APPROVE_LOG = 'Approved Log',
  TRASH_LOG = 'Ignored Log',
  FIX_LOG = 'Corrected Response',
  ESCALATE_LOG = 'Escalated Question',
  UNHANDLE_LOG = 'Unhandled Log',
  SENT_MESSAGE = 'Sent Message',
  CREATE_KNOWLEDGE = 'Created Knowledge',
  UPDATE_KNOWLEDGE = 'Updated Knowledge',
  DELETE_KNOWELEDGE = 'Deleted Knowledge',
}

export enum ActivityLogScope {
  CONVERSATION = 'conversation',
  KNOWLEDGE = 'knowledge base',
}

export interface IActivityLogUpdatedKnowledgeData {
  understandingId: string
  studentId: string
  aiLogId: string
  updatedUnderstanding: IUnderstandingResponseData
  originalUnderstanding: IUnderstandingResponseData
}

export interface IActivityLogCreatedKnowledgeData {
  understandingId: string
  studentId: string
  aiLogId: string
  understanding: IUnderstandingResponseData
}

export interface IActivityLogDeletedKnowledgeData {
  understandingId: string
  deletedUnderstanding: IUnderstandingResponseData
  aiLogId: string
  studentId: string
}

export interface IHandledLogActivityData {
  studentId: string
  aiLogId: string
}
export interface IUnhandledLogActivityData {
  studentId: string
  aiLogId: string
  handledBy: string
  handledMethod: string
  handledDate: string
}
export interface IActivityLogFixedLogData {
  studentId: string
  aiLogId: string
  chosenResponse: { topic: string; question: string; answer: string }
}

export interface IActivityLogSentMessageData {
  studentId: string
  aiLogId: string
  message: string
}

export interface IActivityLogEscalateMessageData {
  aiLogId: string
  studentId: string
  question: string
  message: string
}

export interface IAiLogResponseData {
  id: string
  answer: string
  question: string
  createdAt: string
  studentId: string
}

export type IActivityLogData = Partial<IActivityLogUpdatedKnowledgeData> &
  Partial<IActivityLogCreatedKnowledgeData> &
  Partial<IActivityLogDeletedKnowledgeData> &
  Partial<IActivityLogFixedLogData> &
  Partial<IActivityLogEscalateMessageData> &
  Partial<IHandledLogActivityData> &
  Partial<IUnhandledLogActivityData> &
  Partial<IActivityLogSentMessageData>

export interface IActivityLogResponseData {
  id: string
  createdAt: string
  updatedAt: string
  action: ActivityLogAction
  actionTakenBy: string
  scope: ActivityLogScope
  reports: string[]
  viewed: boolean
  institutionId: string
  data: IActivityLogData
}

export interface IActivityLogListResponseData {
  activityLogs: IActivityLogResponseData[]
  contacts: { id: string; name: { first: string } }[]
  users: IUserResponseData[]
  aiLogs: IAiLogResponseData[]
}

export interface IPaginatedResponse<T> {
  count: number
  next: null | string
  previous: null | string
  results: T
}

export interface IPaginatedAsyncResponse<T> {
  count: number
  next: null | string
  previous: string
  results?: T
  status: CeleryStates
  task_id: string
}

export interface IActivityLogTotalsResponseData {
  readonly actions?: {
    readonly [key: string]: number | undefined
  }
  readonly total?: number
}

export interface IPhoneNumbersResponseData {
  numbers: string[]
}

export interface ICreateBotResponseData {
  brandedCollege: IInstitution
  errors: string[]
  numbers: string[]
}

type ValidationErrors<T> = { readonly [P in keyof T]?: string[] }

export interface ICreateBotValidationData
  extends ValidationErrors<
    Pick<IPurchasePhoneNumbersRequestData, 'areaCode' | 'quantity'>
  > {
  brandedCollege: ValidationErrors<ICreateInstitutionRequestData>
}

export interface ICreateBotValidationData
  extends ValidationErrors<
    Pick<IPurchasePhoneNumbersRequestData, 'areaCode' | 'quantity'>
  > {
  brandedCollege: ValidationErrors<ICreateInstitutionRequestData>
}

interface IOverallReach {
  readonly createdAt: string
  readonly updatedAt: string
  readonly startDate: string
  readonly endDate: string
  readonly week: number
  readonly year: number
  readonly uniqueUsersReached: number
  readonly ignore?: boolean
  readonly transport: TransportId
}

interface ISMSLogAnalytics {
  readonly week: number
  readonly year: number
  readonly sent: number
  readonly received: number
  readonly sentUnique: number
  readonly receivedUnique: number
  readonly messagingService: string
  readonly date: string
  readonly month?: string
  readonly transport?: TransportId
}

interface IOptOutAnalytics {
  readonly softStopCount: number
  readonly hardStopCount: number
  readonly week: number
  readonly year: number
  readonly transport: TransportId
}

interface ICampaignAnalytics {
  readonly nudgeCount: number
  readonly interactiveCount: number
  readonly totalCount: number
  readonly week: number
  readonly year: number
}

interface IMessagesByWeekday {
  readonly count: number
  readonly dayOfWeek: number
  readonly transport: TransportId
}

interface IMessagesByHour {
  readonly count: number
  readonly hour: number
  readonly transport: TransportId
}

export interface ITrendsAnalyticsDataFull {
  readonly id: number
  /// ISO format date string
  readonly created: string
  /// ISO format date string
  readonly modified: string
  readonly full: true
  readonly college_id: string
  /// ISO format date string
  readonly start_date: string
  readonly overall_reach: ReadonlyArray<IOverallReach>
  readonly sms_log_analytics: ReadonlyArray<ISMSLogAnalytics>
  readonly opt_out_analytics: ReadonlyArray<IOptOutAnalytics>
  readonly campaign_analytics: ReadonlyArray<ICampaignAnalytics>
  readonly messages_by_weekday: ReadonlyArray<IMessagesByWeekday>
  readonly messages_by_hour: ReadonlyArray<IMessagesByHour>
}

interface ITrendsAnalyticsDataEmpty {
  readonly full: false
}

export type ITrendsAnalyticsData =
  | ITrendsAnalyticsDataFull
  | ITrendsAnalyticsDataEmpty

export interface ILoginResponse {
  readonly user: IUserResponseData
  readonly token: string | null
}

export interface IUserPasswordRequirementInfo {
  readonly userData: {
    readonly names: ReadonlyArray<string>
    readonly email: string
  }
  readonly passwordConstraints: ReadonlyArray<IPasswordConstraint>
}

interface IEnrollmentInfoResponseDataError {
  readonly error: true
  readonly message: string
}

interface IEnrollmentInfoResponseDataSuccess
  extends IUserPasswordRequirementInfo {
  readonly error: false
}

export type IEnrollmentInfoResponseData =
  | IEnrollmentInfoResponseDataError
  | IEnrollmentInfoResponseDataSuccess

interface IApplicationAlertBannerResponseEmpty {
  readonly banner_exists: false
}
interface IApplicationAlertBannerResponseFull {
  readonly banner_exists: true
  readonly id: number
  readonly created: string
  readonly modified: string
  readonly start_date: string
  readonly expiration_date: string
  readonly message_markdown: string
}

export type IApplicationAlertBannerResponse =
  | IApplicationAlertBannerResponseFull
  | IApplicationAlertBannerResponseEmpty

export interface IListCustomLabelsResponse {
  labels: string[]
}

export interface IFetchMappedFieldsResponseData {
  mapped_fields: {
    [id: string]: IField
  }
}

export interface IFetchAllFieldsResponseData {
  fields: IField[]
}

export interface IPreprocessStudentUploadResponseData {
  records: IDataRow[]
  s3FilePath: string
  importReportId: string
}

export interface IMostActiveContactResponseData {
  id: string
  name: string
  total: number
  frozen: boolean
}

export interface IAPIAuthToken {
  readonly id: string
  readonly created: string
  readonly modified: string
  readonly college_id: string
  readonly created_by_id: string
  readonly note: string
  readonly last_used?: string
  readonly generated_token?: string
}
// we only set the generated_token on create
export interface IAPIAuthTokenCreate extends IAPIAuthToken {
  readonly generated_token: string
}

export interface IConfigureSalesforceResponse {
  consumerKey?: string
  consumerSecret?: string
  batchSize?: number
}

export interface IUploadResponse {
  readonly id: number
  readonly college_id: string

  readonly bucket: string
  readonly key: string

  readonly completed: boolean
  readonly content_type: string
  readonly href: string
  readonly name: string
  readonly public: boolean
  readonly size: number
}

export interface IInitializeFileUploadResponse {
  readonly url: string
  readonly fields: { readonly [key: string]: string | undefined }
  readonly upload: IUploadResponse
}

export interface ISearchUnderstandingByTopicResponseData {
  success: boolean
  results: ISearchUnderstandingByTopicResult[]
}

export interface ISearchUnderstandingByTopicResult {
  readonly id: string
  readonly topic: string
  readonly sampleQuestion: string
  readonly answerGroups: ReadonlyArray<{
    readonly messagingService: string
    readonly answers: ReadonlyArray<string>
  }>
  readonly questionGroups: ReadonlyArray<{
    readonly fuzzyQuestions: ReadonlyArray<string>
  }>
}

export interface IUnderstandingResponseData {
  id: string
  organization: string[]
  topic: string
  sampleQuestion: string
  questionGroups: IQuestionGroupResponseData[]
  answerGroups: IAnswerGroupResponseData[]
  markLogAsHandled: boolean
  messageId: string
}

export interface IUpdateUnderstandingTopLevelResponse {
  readonly id: string
  readonly topic: string
  readonly sampleQuestion: string
}

export interface ICounselorResponseData {
  readonly id: number
  readonly college_id: string
  readonly name: string
  readonly email: string
  readonly reason?: string
}

export interface ICounselorsResponseData
  extends Array<ICounselorResponseData> {}

export interface IEscalationConditionResponseData {
  readonly id: number
  readonly type: ConditionType
  readonly field: string
  readonly value: string
  readonly operator: EscalationOperator
  readonly college_id: string
}

export interface IEscalationRuleResponseData {
  readonly id: number
  readonly name: string
  readonly type: 'MANUAL' | 'AUTOMATIC'
  readonly counselors: number[]
  readonly counselor: number
  readonly conditions: IEscalationConditionResponseData[]
  readonly priority: number
  readonly college_id: string
  readonly contact_filter?: number
}

export interface IEscalationRulesResponseData {
  readonly AUTOMATIC: IEscalationRuleResponseData[]
  readonly MANUAL: IEscalationRuleResponseData[]
  readonly used_understandings: Array<IEscalationUnderstandingResponseData>
}

export interface IEscalationUnderstandingResponseData {
  id: number
  url: string
  mongo_id: string
  topic: string
  sample_question: string
}

export interface IEscalationUnderstandingsResponseData {
  sensitive: Array<IEscalationUnderstandingResponseData>
  used: Array<IEscalationUnderstandingResponseData>
}

export type IGetAllInstitutionNamesResponseData = ReadonlyArray<{
  readonly id: string
  readonly messagingService: string
  readonly displayName: string
}>

export interface IInstitutionAttributeResponseData {
  readonly id: number
  readonly institution: number
  readonly name: string
  readonly value: string
  readonly data_type: InstitutionAttributeType
  readonly understandings_count?: number
  readonly scripts_count?: number
}

export interface IAudienceInstitutionAttrValue {
  readonly contact_filter_id: number
  readonly contact_filter_name: string
  readonly value: string
  readonly priority: number
}

export interface IInstitutionAttributeRetrieveResponseData {
  id: number
  institution: number
  name: string
  data_type: InstitutionAttributeType
  value: string
  audience_specific_values: IAudienceInstitutionAttrValue[] | undefined
}
export interface IInstitutionAttributesResponseData {
  results: Array<IInstitutionAttributeResponseData>
  count: number
}

export interface IContactAttributeResponseData {
  readonly id: number
  readonly institution: number
  readonly name: string
  readonly description: string | null
  readonly requires_auth: boolean
  readonly include_in_escalations: boolean
  readonly data_type: ContactAttributeType
  readonly topLevelField?: boolean
  readonly multi_choices?: {
    id: number
    value: string
    order?: number
  }[]
  readonly understandings_count?: number
  readonly scripts_count?: number
  readonly contact_filters_count?: number
}

export interface IContactAttributeConditionResponseData {
  readonly id: number
  readonly institution: number
  readonly name: string
  readonly requires_auth: boolean
  readonly include_in_escalations: boolean
  readonly data_type: ContactAttributeType
  readonly multi_choices: {
    id: number
    value: string | null
    order: number | null
  }[]
}

export interface IContactAttributesResponseData {
  results: Array<IContactAttributeResponseData>
  count: number
}

export interface IAttributeMap {
  source_attribute: IMappingAttribute
  target_attribute: IMappingAttribute
}

export interface IMappingAttribute
  extends Omit<
      IContactAttributeResponseData,
      | 'institution'
      | 'requires_auth'
      | 'data_type'
      | 'include_in_escalations'
      | 'description'
    >,
    Omit<
      IInstitutionAttributeResponseData,
      'data_type' | 'institution' | 'value'
    > {
  data_type: DataTypes
  value?: string
  requires_auth?: boolean
  include_in_escalations?: boolean
  target_college_id?: string
}

export interface IGetAttributeMappingsResponseData {
  source_attrs: IMappingAttribute[]
  target_attrs: IMappingAttribute[]
  mappings: IAttributeMap[]
}
export interface ICreateMappingAttributesResponseData {
  target_attributes: IMappingAttribute[]
  mappings: IAttributeMap[]
}
export interface ITopLevelContactFieldsResponseData {
  [key: string]: ContactAttributeType
}

export type ISaveableTopLevelContactFieldsResponseData = Array<
  IContactAttributeResponseData
>

export interface ITraySolutionConfigSlot {
  defaultValue: string
  externalId: string
  title: string
}

export interface ITrayCustomField {
  key: string
  value: string
}

export interface ITraySolutionResponseData {
  id: string
  title: string
  description: string
  tags: Array<string>
  customFields: Array<ITrayCustomField>
  configSlots: Array<ITraySolutionConfigSlot>
  isSFTP: boolean
  needCSVDownload: boolean
}

export interface ITraySolutionInstanceResponseData {
  id: string
  name: string
  enabled: boolean
  owner: string
  created: string // stringified solution instance created date
  description: string | null
}

export interface ICreateSolutionInstanceResponseData {
  solutionInstanceId: string
  authenticationId: string
  configWizardAuthCode: string
  embedUrl: string
}

export interface IUpdateSolutionInstanceResponseData {
  embedUrl: string
}

export interface IIntegrationError {
  id: number
  created: Date
  platform?: string
  direction?: string
  admithub_contact_id?: string
  platform_contact_id?: string
  solution_instance?: number
  error_code: string
  error_message?: string
}

export interface IIntegrationErrorsResponse {
  page: number
  count: number
  results: IIntegrationError[]
}

interface IContactSegmentShortResponse {
  id: number
  name: string
  created: Date
  modified: Date
}

export interface IContactSegmentsListResponse
  extends Array<IContactSegmentShortResponse> {}

export interface IContactConditionResponseData {
  comparison_type: ComparisonOperator | null
  contact_attribute: IContactAttributeConditionResponseData | null
  contact_field: string | null
  value: string | null
}

export interface IContactConditionGroupResponseData {
  combination_type: CombinationOperator
  conditions: Array<IContactConditionResponseData>
  condition_groups: Array<IContactConditionGroupResponseData>
}

export interface IContactFilterResponseData {
  id: number | undefined
  name: string
  condition_group: IContactConditionGroupResponseData
  region?: string | null
  size: number | null
  last_calculated: string | null
  contacts_loading?: boolean
  deleted?: boolean
}

export interface IEmptyContactCondition extends IContactConditionResponseData {
  comparison_type: null
  contact_attribute: null
  contact_field: null
  value: null
}

export interface IEmptyContactConditionGroup
  extends IContactConditionGroupResponseData {
  conditions: Array<IEmptyContactCondition>
}

export interface IEmptyContactFilter extends IContactFilterResponseData {
  id: number | undefined
  name: string
  condition_group: IEmptyContactConditionGroup
  region?: string | null
  size: null
  last_calculated: null
}

export interface IInsightsData<T> {
  bucket: number
  bucket_datetime: string
  points: T
}

export interface IInsightsResponseData<T> {
  label?: string | null
  insight: Insights
  data: Array<IInsightsData<T>>
}

export interface IWebhooksSubscriptionResponse {
  shared_secret: string
  name: string
  endpoint_url: string
  subscription_id: string
}

export interface IHelloPageFieldResponse {
  id?: number
  order: number
  visible: boolean
  required: boolean
  display_field_name: string | null
  top_level_field: string | null
  default_field_value: string | null
  contact_attribute: number | null
}

export interface IHelloPageResponse {
  id: number
  page_title: string
  url_path: string
  created: string
  modified: string
  published: boolean
  created_by: string
  dialog_id: string | null
  confirmation_message: string
  icon: IUploadResponse | null
  form_image: IUploadResponse | null
  form_header: string | null
  form_description: string | null
  consent_text: string
  attribute_fields: IHelloPageFieldResponse[]
}

export interface IScriptUsages {
  answers_count: number
  upcoming_campaigns_count: number
  hello_pages_count: number
  command_count: number
  webbot_intro_count: number
  open_bot_unknown_contact_usage: boolean
  archived_contact_usage: boolean
  closed_bot_unknown_contact_usage: boolean
  public_api_intro_usage: boolean
}
