import { useState } from 'react'
import { MainstaySidebarLinkType } from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSectionLink/MainstaySidebarSectionLink'
import MainstaySidebarNestedSection from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSection/MainstaySidebarNestedSection/MainstaySidebarNestedSection'
import cls from 'classnames'

import 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSection/MainstaySidebarSection.scss'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

const Header = ({
  header,
  collapsable,
  expanded,
  onClick,
}: {
  header?: string
  collapsable?: boolean
  expanded?: boolean
  onClick?: () => void
}) => {
  if (!header) {
    return null
  }
  if (!collapsable) {
    return (
      <h6 className="text-mainstay-dark-blue pl-3">{header.toUpperCase()}</h6>
    )
  }
  return (
    <div
      className="d-flex pointer align-items-center justify-content-between"
      onClick={onClick}>
      <h6 className="text-mainstay-dark-blue pl-3">{header.toUpperCase()}</h6>
      <AHIcon
        className="text-mainstay-dark-blue mb-2"
        name={expanded ? 'expand_more' : 'navigate_before'}
      />
    </div>
  )
}

export default function MainstaySidebarSection({
  header,
  links,
  children,
  collapsable,
}: {
  readonly header?: string
  readonly links?: MainstaySidebarLinkType[]
  readonly collapsable?: boolean
  readonly children?: React.ReactNode
}) {
  const sectionActive = links && links.some(l => !!l.active) !== undefined
  const [expanded, setExpanded] = useState(true)

  return (
    <div
      className={cls('pt-4 d-flex flex-column', {
        'flex-grow-1': sectionActive,
      })}>
      <Header
        header={header}
        collapsable={collapsable}
        expanded={expanded}
        onClick={() => setExpanded(prev => !prev)}
      />
      {links &&
        (!collapsable || expanded) &&
        links.map(link =>
          link.permission ? (
            <PermissionGuard
              permission={link.permission}
              key={`permission-${link.to}-${link.text}`}>
              <MainstaySidebarNestedSection key={link.to} link={link} />
            </PermissionGuard>
          ) : (
            <MainstaySidebarNestedSection key={link.to} link={link} />
          )
        )}
      {children}
    </div>
  )
}
