import {
  ConversationSettingsShapeType,
  GenerativeAISettingsShapeType,
} from 'api'
import {
  fetchConversationSettings,
  IActions,
  updateConversationSettings,
  fetchGenerativeTextPromptSettings,
  updateGenerativeAISettings,
} from 'page/settings/ConversationSettings/actions'
import { Failure, Success, WebData } from 'store/webdata'
import { getType } from 'typesafe-actions'

const DEFAULT_FREEZE_WINDOW = 180

type ConversationSettingsState = {
  readonly liveChatDuration: number
  readonly liveChatDisabled: boolean
  readonly dialogSettings: ConversationSettingsShapeType['dialogSettings']
  readonly generativeTextPromptSettings: GenerativeTextPromptSettings
  readonly generativeAISettings: WebData<GenerativeAISettingsShapeType>
  readonly status: ConversationSettingsRequestStatus
  readonly updateStatus: ConversationSettingsRequestStatus
}

export enum ConversationSettingsRequestStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

export type GenerativeTextPromptSettings = {
  readonly data: GenerativeTextPromptSettingsData
}

export type GenerativeTextPromptSettingsData =
  | {
      status: GenerativeTextPromptSettingsRequestStatus.initial
    }
  | {
      status: GenerativeTextPromptSettingsRequestStatus.loading
    }
  | {
      status: GenerativeTextPromptSettingsRequestStatus.error
    }
  | {
      status: GenerativeTextPromptSettingsRequestStatus.ok
    }

export enum GenerativeTextPromptSettingsRequestStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

const initialConversationSettingsState: ConversationSettingsState = {
  liveChatDuration: DEFAULT_FREEZE_WINDOW,
  liveChatDisabled: false,
  dialogSettings: null,
  generativeTextPromptSettings: {
    data: {
      status: GenerativeTextPromptSettingsRequestStatus.initial,
    },
  },
  generativeAISettings: undefined,
  status: ConversationSettingsRequestStatus.initial,
  updateStatus: ConversationSettingsRequestStatus.initial,
}

const conversationSettings = (
  state: ConversationSettingsState = initialConversationSettingsState,
  action: IActions
): ConversationSettingsState => {
  switch (action.type) {
    case getType(fetchConversationSettings.request):
      return {
        ...state,
        status: ConversationSettingsRequestStatus.loading,
      }
    case getType(fetchConversationSettings.success):
      return {
        ...state,
        liveChatDuration: action.payload.liveChatDuration,
        liveChatDisabled: action.payload.liveChatDisabled,
        dialogSettings: action.payload.dialogSettings,
        status: ConversationSettingsRequestStatus.ok,
      }
    case getType(fetchConversationSettings.failure):
      return {
        ...state,
        status: ConversationSettingsRequestStatus.error,
      }
    case getType(updateConversationSettings.request):
      return {
        ...state,
        updateStatus: ConversationSettingsRequestStatus.loading,
      }
    case getType(updateConversationSettings.success):
      return {
        ...state,
        liveChatDisabled: action.payload.liveChatDisabled,
        liveChatDuration: action.payload.liveChatDuration,
        updateStatus: ConversationSettingsRequestStatus.ok,
      }
    case getType(updateConversationSettings.failure):
      return {
        ...state,
        updateStatus: ConversationSettingsRequestStatus.error,
      }
    case getType(fetchGenerativeTextPromptSettings.request):
      return {
        ...state,
      }
    case getType(fetchGenerativeTextPromptSettings.success):
      return {
        ...state,
        generativeAISettings: Success(action.payload.generative_ai_settings),
      }
    case getType(fetchGenerativeTextPromptSettings.failure):
      return {
        ...state,
        generativeAISettings: Failure('Failed to load settings'),
      }
    case getType(updateGenerativeAISettings):
      return {
        ...state,
        generativeAISettings: Success(action.payload),
      }
    default:
      return state
  }
}

export default conversationSettings
