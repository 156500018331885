import * as Ably from 'ably/promises'
import * as t from 'io-ts'
import { isRight } from 'fp-ts/lib/Either'
import * as Sentry from '@sentry/browser'
import { store } from 'store/store'

import {
  updateAblyConnectionState,
  resetAblyConnectionState,
} from 'store/ablyConnection/actions'
import { CURRENT_INSTITUTION_HEADER } from 'const/settings'

let clientInfo: { institutionId: string; client: ClientType } | null = null

function createClient({ institutionId }: { readonly institutionId: string }) {
  return new Ably.Realtime.Promise({
    authUrl: '/api/v0/conversations-v2/get_live_updates_token',
    authHeaders: {
      [CURRENT_INSTITUTION_HEADER]: institutionId,
    },
  })
}

type ClientType = ReturnType<typeof createClient>

/** Get or create a pubsub client for Ably
 *
 * We use one client across the app so the browser only opens one websocket
 * connection to Ably. If we used a client per feature (notifications,
 * conversation updates, etc.), we'd open a websocket connection per feature.
 */
function getClient({ institutionId }: { institutionId: string }): ClientType {
  if (clientInfo && clientInfo.institutionId !== institutionId) {
    if (
      clientInfo.client.connection.state !== 'closed' &&
      clientInfo.client.connection.state !== 'closing'
    ) {
      clientInfo.client.close()
      clientInfo.client.connection.off()
    }
    clientInfo = null
  }
  if (clientInfo == null) {
    clientInfo = { client: createClient({ institutionId }), institutionId }
    clientInfo.client.connection.on(stateChange => {
      store.dispatch(updateAblyConnectionState(stateChange.current))
      if (stateChange.current === 'failed') {
        handleAblyError(stateChange.reason)
      }
    })
  }
  return clientInfo.client
}

const AblyErrorShape = t.type({
  message: t.string,
  code: t.number,
})
const ABLY_IGNORED_ERRORS = new Set([
  // Although we don't call close there seems to be some issue with Ably
  // where it will raise an exception about calling close twice when the
  // page unloads.
  // https://support.ably.com/support/solutions/articles/3000092530
  80017,
  // Client configured authentication provider request failed.
  // Ably tries to get an auth token but gets a 401 because the user is logged
  // out. Ably will continue to retry so we can ignore for now.
  80019,
])

/**
 * Subscribe to a given Ably channel.
 *
 * Returns a function that is intended to be called on component unmount.
 */
export function subscribe({
  institutionId,
  options,
  channel: channelName,
  onMessage,
}: {
  readonly institutionId: string
  readonly channel: string
  readonly options?: Ably.Types.ChannelOptions
  readonly onMessage: (_: Ably.Types.Message) => void
}): () => void {
  const client = getClient({ institutionId })

  // connect to new channel for contact's conversation
  //
  // channels.get does _not_ make a network request. When we call subscribe
  // the Ably client will send a message.
  const channel = client.channels.get(channelName)

  // calling setOptions will attach the channel:
  // https://www.ably.io/documentation/realtime/channels#modifying-options
  if (options != null) {
    channel.setOptions(options)
  }

  channel.subscribe(onMessage).catch((e: unknown) => handleAblyError(e))

  return () => {
    // disconnect on unmount. This will often occur when we navigate to a
    // different page and the component unmounts.
    channel.unsubscribe()
  }
}

export function attachToChannel({
  institutionId,
  channel: channelName,
  presenceData,
}: {
  readonly institutionId: string
  readonly channel: string
  readonly presenceData: string
}) {
  const client = getClient({ institutionId })
  const channel = client.channels.get(channelName)

  // attaching to channel and entering user
  channel
    .attach()
    .then(() => {
      return channel.presence.enter(presenceData)
    })
    .catch((e: unknown) => handleAblyError(e))

  return () => {
    // disconnect on unmount
    channel.presence.leave().catch((e: unknown) => handleAblyError(e))
  }
}

export function subscribeToPresence({
  institutionId,
  options,
  channel: channelName,
  onMembersUpdate,
}: {
  readonly institutionId: string
  readonly channel: string
  readonly options?: Ably.Types.ChannelOptions
  readonly onMembersUpdate: (_: Ably.Types.PresenceMessage[]) => void
}): () => void {
  const client = getClient({ institutionId })
  const channel = client.channels.get(channelName)
  if (options != null) {
    channel.setOptions(options)
  }

  // subscribing to channel presence in order to get current members on the channel
  channel.presence
    .subscribe(() => {
      channel.presence.get().then(members => {
        onMembersUpdate(members)
      })
    })
    .catch((e: unknown) => handleAblyError(e))

  return () => {
    // disconnect on unmount
    channel.presence.unsubscribe()
  }
}

export function isAblyError(e: unknown) {
  const err = AblyErrorShape.decode(e)
  return isRight(err)
}

export function handleAblyError(e: unknown) {
  const err = AblyErrorShape.decode(e)
  if (isRight(err) && ABLY_IGNORED_ERRORS.has(err.right.code)) {
    return
  }

  Sentry.withScope(scope => {
    const message = 'Error from pubsub Ably subscribe.'
    scope.setExtras({
      message,
    })
    // Sentry has trouble with grouping these errors so to cut down on noise
    // we want to group them manually by the specific error data.
    if (isRight(err)) {
      scope.setFingerprint([message, String(err.right.code)])
    }
    Sentry.captureException(e)
  })
}

export function closeAblyConnection() {
  if (clientInfo) {
    clientInfo?.client.close()
    clientInfo?.client.connection.off()
    clientInfo = null
    store.dispatch(resetAblyConnectionState())
  }
}
