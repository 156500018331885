import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import { IContactFilterFormData } from 'components/ContactFilterBuilder/formUtils'
import { push } from 'connected-react-router'
import { CAMPAIGN_SCHEDULER_ROUTES } from 'const/routes'
import { ICampaignSchedulerRequestData } from 'api/request'
import { Http2ErrorUnion } from 'api/http'
import {
  ICampaignSchedulerValidationData,
  ICampaignSchedulerTouchedFields,
  ICampaignSchedulerFormData,
} from 'store/campaign-scheduler/reducer'
import {
  IApiError,
  IScheduledMessageDetail,
  IDataTriggeredCampaignDetail,
  ITrigger,
} from 'api'

export const navToScriptSelectionConfirmation = (
  basePath: string,
  id: string
) =>
  push(`${basePath}${CAMPAIGN_SCHEDULER_ROUTES.SCRIPT_SELECTION}confirm/${id}`)

export const navToScriptSelection = (basePath: string) =>
  push(`${basePath}${CAMPAIGN_SCHEDULER_ROUTES.SCRIPT_SELECTION}`)

export const navToTrigger = (basePath: string) =>
  push(`${basePath}${CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG}`)

export const navToContactSelection = (basePath: string) =>
  push(`${basePath}${CAMPAIGN_SCHEDULER_ROUTES.STUDENT_SELECTION}`)

export const updateHasBeenSubmitted = createStandardAction(
  '@@mascot/campaign/scheduler/UPDATE_HAS_SUBMITTED'
)<boolean>()

export const updateValidationErrors = createStandardAction(
  '@@mascot/campaign/scheduler/UPDATE_VALIDATION_DATA'
)<Partial<ICampaignSchedulerValidationData>>()

export const updateTouchedFormFields = createStandardAction(
  '@@mascot/campaign/scheduler/UPDATE_TOUCHED_FORM_FIELDS'
)<Partial<ICampaignSchedulerTouchedFields>>()

export const updateFormData = createStandardAction(
  '@@mascot/campaign/scheduler/UPDATE_FORM_DATA'
)<Partial<ICampaignSchedulerRequestData | ICampaignSchedulerFormData>>()

export const resetFormData = createStandardAction(
  '@@mascot/campaign/scheduler/RESET_FORM_DATA'
)<void>()

export const stopCurrentDialogForStudent = createAsyncAction(
  '@@@mascot/campaign-scheduler/STOP_CURRENT_DIALOG_FOR_STUDENT_START',
  '@@@mascot/campaign-scheduler/STOP_CURRENT_DIALOG_FOR_STUDENT_END',
  '@@@mascot/campaign-scheduler/STOP_CURRENT_DIALOG_FOR_STUDENT_FAIL'
)<string | undefined, undefined, IApiError>()

export const updateSelectedContactFilter = createStandardAction(
  '@@mascot/campaign/scheduler/UPDATE_SELECTED_CONTACT_FILTER'
)<IContactFilterFormData>()

export const updateAdditionalContactFilters = createStandardAction(
  '@@mascot/campaign/scheduler/UPDATE_ADDITIONAL_CONTACT_FILTERS'
)<number[]>()

export const createScheduledMessage = createAsyncAction(
  '@@mascot/campaign-scheduler/CREATE_SCHEDULED_MESSAGE_START',
  '@@mascot/campaign-scheduler/CREATE_SCHEDULED_MESSAGE_SUCCESS',
  '@@mascot/campaign-scheduler/CREATE_SCHEDULED_MESSAGE_FAIL'
)<ICampaignSchedulerRequestData, undefined, IApiError>()

export const createScheduledMessageDraft = createAsyncAction(
  '@@mascot/campaign-scheduler/CREATE_SCHEDULED_MESSAGE_DRAFT_START',
  '@@mascot/campaign-scheduler/CREATE_SCHEDULED_MESSAGE_DRAFT_SUCCESS',
  '@@mascot/campaign-scheduler/CREATE_SCHEDULED_MESSAGE_DRAFT_FAIL'
)<ICampaignSchedulerRequestData, undefined, IApiError>()

export const fetchScheduledMessage = createAsyncAction(
  '@@@mascot/campaign-scheduler/FETCH_CAMPAIGN_START',
  '@@@mascot/campaign-scheduler/FETCH_CAMPAIGN_END',
  '@@@mascot/campaign-scheduler/FETCH_CAMPAIGN_FAIL'
)<void, IScheduledMessageDetail, IApiError>()

export const updateScheduledMessage = createAsyncAction(
  '@@mascot/campaign-scheduler/UPDATE_SCHEDULED_MESSAGE_START',
  '@@mascot/campaign-scheduler/UPDATE_SCHEDULED_MESSAGE_SUCCESS',
  '@@mascot/campaign-scheduler/UPDATE_SCHEDULED_MESSAGE_FAIL'
)<ICampaignSchedulerRequestData, IScheduledMessageDetail, IApiError>()

export const createCampaignTrigger = createAsyncAction(
  '@@mascot/campaign-scheduler/CREATE_CAMPAIGN_TRIGGER_START',
  '@@mascot/campaign-scheduler/CREATE_CAMPAIGN_TRIGGER_SUCCESS',
  '@@mascot/campaign-scheduler/CREATE_CAMPAIGN_TRIGGER_FAIL'
)<ICampaignSchedulerRequestData, undefined, IApiError | Http2ErrorUnion>()

export const fetchCampaignTrigger = createAsyncAction(
  '@@@mascot/campaign-scheduler/FETCH_CAMPAIGN_TRIGGER_START',
  '@@@mascot/campaign-scheduler/FETCH_CAMPAIGN_TRIGGER_END',
  '@@@mascot/campaign-scheduler/FETCH_CAMPAIGN_TRIGGER_FAIL'
)<void, IDataTriggeredCampaignDetail, void>()

export const updateCampaignTrigger = createAsyncAction(
  '@@@mascot/campaign-scheduler/UPDATE_CAMPAIGN_TRIGGER_START',
  '@@@mascot/campaign-scheduler/UPDATE_CAMPAIGN_TRIGGER_END',
  '@@@mascot/campaign-scheduler/UPDATE_CAMPAIGN_TRIGGER_FAIL'
)<ICampaignSchedulerRequestData, ITrigger, IApiError | Http2ErrorUnion>()

export type ICampaignSchedulerActions =
  | ActionType<typeof stopCurrentDialogForStudent>
  | ActionType<typeof updateFormData>
  | ActionType<typeof updateTouchedFormFields>
  | ActionType<typeof updateValidationErrors>
  | ActionType<typeof createScheduledMessage>
  | ActionType<typeof createScheduledMessageDraft>
  | ActionType<typeof updateHasBeenSubmitted>
  | ActionType<typeof fetchScheduledMessage>
  | ActionType<typeof updateScheduledMessage>
  | ActionType<typeof fetchCampaignTrigger>
  | ActionType<typeof createCampaignTrigger>
  | ActionType<typeof updateCampaignTrigger>
  | ActionType<typeof updateSelectedContactFilter>
  | ActionType<typeof updateAdditionalContactFilters>
  | ActionType<typeof resetFormData>
