import { IActions } from 'store/store'
import {
  ICampaign,
  ICampaignTriggerDetails,
  mapCampaignDetailResponseToCampaignDetailState,
  mapRecurringCampaignDetailResponseToCampaignDetailState,
  mapCampaignTriggerResponseToCampaignDetailState,
  mapHelloPageCampaignDetailResponseToCampaignDetailState,
  ICampaignByDialogDetails,
} from 'store/campaign-history/reducer'
import { getType } from 'typesafe-actions'
import {
  fetchCampaignDetails,
  fetchCampaignTriggerDetails,
  fetchCampaignImportDetails,
  fetchRecurringCampaignDetails,
  generateCampaignReport,
  generateCampaignReportProgress,
  fetchIntroDialogVariantDetails,
  updateRecurringCampaignEndDateAction,
} from 'store/campaign-details/actions'
import { WebData, Loading, Failure, Success, isSuccess } from 'store/webdata'
import { IDialog } from 'api/response'

export interface ICampaignImportDetails {
  importReportId: string
  name: string
  date: string
  user: string
  label: string
  contactLabels: string[]
  countContacts: number
}

interface ICampaignDetailsState {
  readonly selectedCampaign: WebData<ICampaign>
  readonly selectedTrigger: WebData<ICampaignTriggerDetails>
  readonly selectedIntroDialogVariant: WebData<ICampaignByDialogDetails>
  readonly workflow: null | IDialog
  readonly canDownload: boolean
  readonly reportProgressByCampaignId: {
    readonly [campaignId: string]: undefined | number
  }
  readonly campaignImportDetails: WebData<ICampaignImportDetails>
}

export enum ReportType {
  Campaign = 'individual_campaign',
  Trigger = 'data_triggered_campaign',
  CampaignDeliveryFailures = 'delivery_failures',
  TriggerDeliveryFailures = 'data_triggered_campaign_delivery_failures',
  HelloPageDeliveryFailures = 'hello_pages_delivery_failures',
  HelloPageCampaign = 'hello_pages_campaign',
  WebchatIntroCampaign = 'webchat_intro_campaign',
  WebchatIntroDeliveryFailures = 'webchat_intro_delivery_failures',
}

const INITIAL_STATE: ICampaignDetailsState = {
  selectedCampaign: undefined,
  selectedTrigger: undefined,
  selectedIntroDialogVariant: undefined,
  workflow: null,
  canDownload: false,
  reportProgressByCampaignId: {},
  campaignImportDetails: undefined,
}

const reducer = (
  state: ICampaignDetailsState = INITIAL_STATE,
  action: IActions
): ICampaignDetailsState => {
  switch (action.type) {
    case getType(fetchCampaignDetails.request): {
      return {
        ...state,
        selectedCampaign: Loading(),
        campaignImportDetails: Loading(),
      }
    }
    case getType(fetchCampaignDetails.failure): {
      return {
        ...state,
        selectedCampaign: Failure(action.payload.errorKind),
        campaignImportDetails: Failure(),
      }
    }
    case getType(fetchCampaignDetails.success): {
      const { campaign, gen_report } = action.payload
      return {
        ...state,
        workflow: campaign.workflow,
        canDownload: gen_report,
        selectedCampaign: Success(
          mapCampaignDetailResponseToCampaignDetailState(campaign)
        ),
      }
    }
    case getType(fetchIntroDialogVariantDetails.request): {
      return {
        ...state,
        selectedIntroDialogVariant: Loading(),
        campaignImportDetails: Loading(),
      }
    }
    case getType(fetchIntroDialogVariantDetails.failure): {
      return {
        ...state,
        selectedIntroDialogVariant: Failure(action.payload.errorKind),
        campaignImportDetails: Failure(),
      }
    }
    case getType(fetchIntroDialogVariantDetails.success): {
      const { campaign } = action.payload
      return {
        ...state,
        workflow: campaign.workflow,
        canDownload: true,
        selectedIntroDialogVariant: Success(
          mapHelloPageCampaignDetailResponseToCampaignDetailState(campaign)
        ),
      }
    }
    case getType(fetchCampaignTriggerDetails.request): {
      return {
        ...state,
        selectedTrigger: Loading(),
      }
    }
    case getType(fetchCampaignTriggerDetails.failure): {
      return {
        ...state,
        selectedTrigger: Failure(action.payload.errorKind),
      }
    }
    case getType(fetchCampaignTriggerDetails.success): {
      const { trigger } = action.payload
      return {
        ...state,
        workflow: trigger.dialog,
        canDownload: true,
        selectedTrigger: Success(
          mapCampaignTriggerResponseToCampaignDetailState(trigger)
        ),
      }
    }
    case getType(fetchRecurringCampaignDetails.request): {
      return {
        ...state,
        selectedCampaign: Loading(),
        campaignImportDetails: Loading(),
      }
    }
    case getType(fetchRecurringCampaignDetails.failure): {
      return {
        ...state,
        selectedCampaign: Failure(action.payload.errorKind),
        campaignImportDetails: Failure(),
      }
    }

    case getType(fetchRecurringCampaignDetails.success): {
      const recurringCampaign = action.payload
      const { campaign, gen_report } = recurringCampaign
      return {
        ...state,
        workflow: campaign.workflow,
        canDownload: gen_report,
        selectedCampaign: Success(
          mapRecurringCampaignDetailResponseToCampaignDetailState(
            recurringCampaign
          )
        ),
      }
    }
    case getType(fetchCampaignImportDetails.request): {
      return {
        ...state,
        campaignImportDetails: Loading(),
      }
    }
    case getType(fetchCampaignImportDetails.failure): {
      return {
        ...state,
        campaignImportDetails: Failure(action.payload.errorKind),
      }
    }
    case getType(fetchCampaignImportDetails.success): {
      const {
        import_report_id,
        import_name,
        import_date,
        import_user,
        import_label,
        contact_labels,
        count_contacts,
      } = action.payload

      return {
        ...state,
        campaignImportDetails: Success({
          importReportId: import_report_id,
          name: import_name,
          date: import_date,
          user: import_user,
          label: import_label,
          contactLabels: contact_labels,
          countContacts: count_contacts,
        }),
      }
    }
    case getType(generateCampaignReport.request): {
      return {
        ...state,
        reportProgressByCampaignId: {
          ...state.reportProgressByCampaignId,
          [action.payload.campaignId]: 0,
        },
      }
    }
    case getType(generateCampaignReportProgress): {
      return {
        ...state,
        reportProgressByCampaignId: {
          ...state.reportProgressByCampaignId,
          [action.payload.campaignId]: action.payload.progress,
        },
      }
    }
    case getType(updateRecurringCampaignEndDateAction): {
      if (!isSuccess(state.selectedCampaign)) {
        return state
      }
      return {
        ...state,
        selectedCampaign: Success({
          ...state.selectedCampaign.data,
          recurrenceSettings: action.payload,
        }),
      }
    }
    case getType(generateCampaignReport.failure):
    case getType(generateCampaignReport.success): {
      return {
        ...state,
        reportProgressByCampaignId: {
          ...state.reportProgressByCampaignId,
          [action.payload.campaignId]: undefined,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
