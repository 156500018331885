import { IContactAttributeValue } from 'store/personalization/contactAttributes/selectors'
import { ContactFieldView } from 'components/ContactPanel/ReadOnlyContactPanel'
import { useSelector } from 'util/hooks'
import { getAttributeValuesLoading } from 'store/contacts/selectors'
import { Spinner } from 'components/Spinner/Spinner'
import { orderBy, truncate } from 'lodash'
import { attributeNameMax } from 'components/AttributeDrawer/AttributeFormValidationSchema'

type IContactAttributeValuePicked = Pick<
  IContactAttributeValue,
  'attributeName' | 'id' | 'value' | 'attributeType' | 'attributeDescription'
>

interface IContactAttributeValueSection {
  contactAttributeValues?: Array<IContactAttributeValuePicked>
}

const tooltipContent = (attr: IContactAttributeValuePicked) => {
  if (
    attr.attributeDescription ||
    attr.attributeName?.length >= attributeNameMax
  ) {
    return (
      <>
        <h5 className="overflow-wrap mb-0">{attr.attributeName}</h5>
        {attr.attributeDescription && (
          <div className="mt-1">{attr.attributeDescription}</div>
        )}
      </>
    )
  }
}

export const ContactAttributeValueSection = ({
  contactAttributeValues,
}: IContactAttributeValueSection) => {
  const loading = useSelector(getAttributeValuesLoading)
  if (loading) {
    return (
      <div className="d-flex justify-content-center w-100 my-4">
        <Spinner className="stroke-bg-mainstay-dark-blue" />
      </div>
    )
  }

  return (
    <>
      {orderBy(
        contactAttributeValues ?? [],
        [attr => attr.attributeName.toLowerCase()],
        ['asc']
      ).map(attributeValue => (
        <ContactFieldView
          key={attributeValue.id}
          name={truncate(attributeValue.attributeName, { length: 30 })}
          value={attributeValue.value}
          dataType={attributeValue.attributeType}
          info={tooltipContent(attributeValue)}
        />
      ))}
    </>
  )
}
