import { IActions } from 'store/store'
import * as _ from 'lodash'
import { getType } from 'typesafe-actions'
import { listImportLabels } from 'store/import-label/actions'
import { IImportLabelResponse } from 'api/response'
import { RDK } from 'store/webdata'

export interface IImportLabel {
  id: string
  reportName: string
  csvName: string
  labels: {
    name: string
    auto: boolean
  }[]
  createdAt: Date
  numContactsCreated: number
  numContactsUpdated: number
  numContactsPhoneUpdated: number
  numTotalContacts: number
}

export interface IImportLabelState {
  byId: {
    [key: string]: IImportLabel
  }
  allIds: string[]
  loading: boolean
  status?: RDK
}

const initialState: IImportLabelState = {
  loading: false,
  allIds: [],
  byId: {},
}

const mapResponseData = (response: IImportLabelResponse): IImportLabel => ({
  id: response.id,
  reportName: response.reportName,
  csvName: response.csvName,
  labels: response.labels,
  createdAt: new Date(response.createdAt),
  numContactsCreated: response.numContactsCreated,
  numContactsUpdated: response.numContactsUpdated,
  numContactsPhoneUpdated: response.numContactsPhoneUpdated,
  numTotalContacts: response.numTotalContacts,
})

const reducer = (
  state: IImportLabelState = initialState,
  action: IActions
): IImportLabelState => {
  switch (action.type) {
    case getType(listImportLabels.success): {
      return {
        ...state,
        loading: false,
        status: RDK.Success,
        allIds: [...state.allIds, ...action.payload.map(x => x.id)],
        byId: {
          ...state.byId,
          ..._.chain(action.payload)
            .map(mapResponseData)
            .keyBy(x => x.id)
            .value(),
        },
      }
    }
    case getType(listImportLabels.request): {
      return {
        ...state,
        loading: true,
        status: RDK.Loading,
      }
    }
    case getType(listImportLabels.failure): {
      return {
        ...state,
        loading: false,
        status: RDK.Failure,
      }
    }

    default:
      return state
  }
}

export default reducer
