import { FireFlyIcon } from 'components/FireflyIcon/FireflyIcon'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { AttributesIcon } from 'components/Icons/AttributesIcon/AttributesIcon'
import { AudienceIcon } from 'components/Icons/AudienceIcon/AudienceIcon'

export const ResponsesFiltersData = {
  staticResponse: {
    queryParam: 'staticResponse',
    description: 'Static Response',
    icon: (
      <AHIcon
        className="pl-2 fill-mainstay-dark-blue-65 text-mainstay-dark-blue-65"
        name="knowledge-base"
      />
    ),
  },
  audienceSpecific: {
    queryParam: 'audienceSpecific',
    description: 'Audience-Specific Response',
    icon: (
      <div className="px-2 pb-1 fill-mainstay-dark-blue-65">
        <AudienceIcon
          height={18}
          width={18}
          className="fill-mainstay-dark-blue-65 text-mainstay-dark-blue-65"
        />
      </div>
    ),
  },
  interactive: {
    queryParam: 'interactive',
    description: 'Interactive Response',
    icon: (
      <div className="px-2 pt-1 height-1_5rem">
        <AHIcon
          name="comment"
          className="fill-mainstay-dark-blue-65 text-mainstay-dark-blue-65"
        />
      </div>
    ),
  },
  generative: {
    queryParam: 'generative',
    description: 'Generative Response',
    icon: (
      <div className="firefly px-2 fill-mainstay-dark-blue-65">
        <FireFlyIcon height="18" width="18" />
      </div>
    ),
  },
  orgAttribute: {
    queryParam: 'orgAttribute',
    description: 'Contains Organization Attribute',
    icon: (
      <div className="px-2 fill-mainstay-dark-blue-65">
        <AttributesIcon />
      </div>
    ),
  },
  contactAttribute: {
    queryParam: 'contactAttribute',
    description: 'Contains Contact Field',
    icon: (
      <div className="px-2 fill-mainstay-dark-blue-65 pt-1">
        <AHIcon
          name="person"
          className="fill-mainstay-dark-blue-65 text-mainstay-dark-blue-65"
        />
      </div>
    ),
  },
} as const

export type ResponsesFiltersNamesType = keyof typeof ResponsesFiltersData

export type ResponsesFilters = Record<ResponsesFiltersNamesType, boolean>

/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
export const ResponsesFiltersNames = Object.keys(
  ResponsesFiltersData
) as ResponsesFiltersNamesType[]
