import * as Cookie from 'js-cookie'
import axios, { AxiosError, AxiosRequestConfig, CancelToken } from 'axios'
import { store } from 'store/store'
import * as Sentry from '@sentry/browser'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  setLoginMessage,
  loggingOut,
  redirectToLoginWithHistory,
} from 'store/auth/actions'
import { headers as h } from 'const/auth'
import * as settings from 'const/settings'
import * as t from 'io-ts'
import { Either, isRight, left } from 'fp-ts/lib/Either'
import { last } from 'lodash'
import { Location } from 'history'
import { pathAllowsGuests } from 'api/sharedUtils'

const config: AxiosRequestConfig = {
  // Setting a base URL to `/` prevents mistakes where we are missing a starting
  // `/` in our api calls
  baseURL: '/',
}

export const http = axios.create(config)

const handleResponseError = (error: AxiosError) => {
  // 401 means Authentication Required (login).
  const unAuthenticated = error.response && error.response.status === 401
  if (unAuthenticated) {
    // NOTE: we want to 'logout' the user, and redirect to login. They are actually
    // logged out on the backend but the frontend doesn't know yet.
    if (!store.getState().auth.authed) {
      /*
      We don't need to logout a user if they are already "logged out" on the frontend.
      This should prevent the problem where we were redirecting when a user was already
      at login, password reset, or password reset confirm. Redirecting clears the form
      inputs, which is an annoying user experience.
      Moreover, if this rejection is happening to a guest user, we will want to handle the
      error in a way specific to this page, so any guest page should always reject here.
      */
      if (pathAllowsGuests(location.pathname)) {
        return Promise.reject()
      }
      return Promise.reject(error)
    }
    // see also: store/auth/thunks.ts
    const currentLocation: Location = {
      pathname: location.pathname,
      state: undefined,
      hash: location.hash,
      search: location.search,
    }
    store.dispatch(loggingOut.success())
    store.dispatch(setLoginMessage('Your session has timed out. Please login.'))
    store.dispatch(redirectToLoginWithHistory(currentLocation))
  }
  return Promise.reject(error)
}

// Temporary solution to prevent the UI from blowing up when it unexpectedly receives
// an HTML response instead of JSON. This is to mitigate the issues with Cloudfront where
// it returns an HTML response for 404s.
// This should be removed once the better solution is available.
http.interceptors.response.use(
  response => {
    if (response.headers['content-type'] === 'text/html') {
      return Promise.reject({ response: { status: 404 } })
    } else {
      return response
    }
  },
  error => Promise.reject(error)
)
http.interceptors.response.use(
  response => response,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  error => handleResponseError(error as AxiosError)
)

http.interceptors.request.use(config => {
  // tslint:disable no-unsafe-any
  const currentInst = store.getState().auth.orgSlug
  if (currentInst) {
    config.headers[settings.CURRENT_INSTITUTION_HEADER] = currentInst
  }
  return config
})
http.interceptors.request.use(
  config => {
    const csrfToken = Cookie.get('csrftoken')
    // tslint:disable no-unsafe-any
    config.headers[h.CSRF] = csrfToken
    // tslint:enable no-unsafe-any
    return config
  },
  error => Promise.reject(error)
)

// Use multiple parmeters without brackets for format
// We use this approach so it's easier to parse these array params in the backend with Django
//
// Default behavior:
// param[]=value&param[]=value2
//
// Our customization:
// param=value1&param=value2

// indexes are a valid option for paramsSerializer
// TODO: remove our custom typing for axios and use the official one. may need to upgrade axios to do this
// @ts-expect-error
http.defaults.paramsSerializer = { indexes: null }

type Method =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'HEAD'
  | 'OPTIONS'
  | 'CONNECT'
  | 'PATCH'
  | 'TRACE'

type ParamValue = string | number | boolean | undefined
type Params = Record<string, ParamValue | readonly ParamValue[]>

/**
 * Convert io-ts schema violations into a simpler format Sentry will accept.
 *
 * Sentry limits the length and depth of `extra` data passed to `scope`. Here we
 * limit the depth of the errors to prevent Sentry from truncating important
 * context.
 */
export function condenseSchemaViolations(violations: t.Errors) {
  return violations.map(violation => {
    const lastContext = last(violation.context)
    return {
      value: violation.value,
      path: violation.context.map(x => x.key).join('.'),
      lastTypeName: lastContext?.type.name,
      lastActual: lastContext?.actual,
      lastKey: lastContext?.key,
    }
  })
}
export type Http2ErrorUnion =
  | {
      kind: 'schema'
      schema: t.Errors
    }
  | {
      kind: 'http'
      http: AxiosError
    }
  | {
      kind: 'unknown'
      unknown: unknown
    }
/**
 * http client using io-ts to provide runtime validation.
 *
 * This uses result types instead of exceptions.
 */
export async function http2<T, A, O>({
  url,
  method,
  params,
  cancelToken,
  shape,
  data,
}: {
  readonly url: string
  readonly method: Method
  readonly data?: T
  readonly shape: t.Type<A, O>
  readonly params?: Params
  readonly cancelToken: CancelToken
}): Promise<
  Either<
    | Http2ErrorUnion
    | {
        kind: 'cancel'
        message: string
      },
    A
  >
>
export async function http2<T, A, O>({
  url,
  method,
  params,
  shape,
  data,
}: {
  readonly url: string
  readonly method: Method
  readonly data?: T
  readonly shape: t.Type<A, O>
  readonly params?: Params
}): Promise<Either<Http2ErrorUnion, A>>
export async function http2<T, A, O>({
  url,
  method,
  params,
  cancelToken,
  shape,
  data,
}: {
  readonly url: string
  readonly method: Method
  readonly data?: T
  readonly shape: t.Type<A, O>
  readonly params?: Params
  readonly cancelToken?: CancelToken
}): Promise<
  Either<
    | Http2ErrorUnion
    | {
        kind: 'cancel'
        message: string
      },
    A
  >
> {
  try {
    const r = await http.request<unknown>({
      url,
      method,
      params,
      cancelToken,
      data,
    })
    const res = shape.decode(r.data)
    if (isRight(res)) {
      return res
    }
    // Report schema violations to Sentry.
    Sentry.withScope(scope => {
      const violations = condenseSchemaViolations(res.left)
      scope.setExtras({
        violationCount: violations.length,
        violations,
        url,
        method,
      })
      Sentry.captureMessage('http response schema violation', {
        level: 'error',
        fingerprint: ['{{ default }}', url],
      })
      // tslint:disable-next-line no-console
      console.error('http response schema violations', violations)
    })
    return left({ kind: 'schema', schema: res.left })
  } catch (e) {
    // AxiosError has an `isAxiosError` attribute to discriminate off.
    if (e instanceof Error && 'isAxiosError' in e) {
      return left({ kind: 'http', http: e })
    }
    if (cancelToken != null && e instanceof axios.Cancel) {
      return left({ kind: 'cancel', message: e.message })
    }
    // report general error to Sentry as they shouldn't happen normally
    Sentry.withScope(scope => {
      scope.setExtras({
        url,
        method,
      })
      Sentry.captureException(e)
    })
    return left({ kind: 'unknown', unknown: e })
  }
}

/** Try to find error information from response based on key */
export function keyOrDefault<T>(
  error: AxiosError,
  /** the attribute to access from error response data */
  key: string,
  defaultValue: T
): string | T {
  if (error.response && error.response.data) {
    // tslint:disable-next-line:no-unsafe-any
    const value = error.response.data[key]
    if (typeof value === 'string') {
      return value
    }
    if (Array.isArray(value)) {
      return value.join(' ')
    }
  }
  return defaultValue
}
export function detailOrDefault(
  error: AxiosError,
  defaultValue: string
): string {
  return keyOrDefault(error, 'detail', defaultValue)
}
export function http2ErrorIs404(error: Http2ErrorUnion) {
  return error.kind === 'http' && error.http.response?.status === 404
}
export function http2DetailOrDefault(
  error: Http2ErrorUnion,
  defaultValue: string
): string {
  if (error.kind === 'http') {
    return keyOrDefault(error.http, 'detail', defaultValue)
  }
  return defaultValue
}
export function http2KeysOrDefault(
  error: Http2ErrorUnion,
  defaultValue: string,
  keys: string[]
): { [key: string]: string } {
  /*
    Returns an object containing all validation error messages, if exists, for each key provided.
  */
  const httpErrorHasKey = (key: string, error: AxiosError) => {
    return (
      // tslint:disable-next-line:no-unsafe-any
      !!error.response && !!error.response.data && key in error.response.data
    )
  }
  if (error.kind === 'http') {
    return keys
      .filter((key: string) => httpErrorHasKey(key, error.http))
      .reduce(
        (prev, current: string) => ({
          ...prev,
          [current]: keyOrDefault(error.http, current, defaultValue),
        }),
        {}
      )
  }
  return { detail: defaultValue }
}
export function toastOnHttpError500or400(
  e: AxiosError,
  autoClose: number = 3000,
  errorStringsForStatusCode: {
    [code: string]: string
  } = {}
) {
  const errorString = e.response
    ? errorStringsForStatusCode[e.response.status.toString()]
    : ''
  if (e.response && e.response.status >= 500) {
    toast(
      'There was a problem with our server. Our team has been notified. Please try again.',
      { type: 'error', options: { autoClose } }
    )
  } else if (e.response && e.response.status === 404) {
    toast(errorString || '404 item not found', {
      type: 'error',
      options: { autoClose },
    })
  } else if (e.response && e.response.status >= 400) {
    // If we're on the login page, we don't want to show an error toast for 401
    // because we already show an alert.
    if (
      e.response.status === 401 &&
      window.location.pathname.includes('login')
    ) {
      return
    }
    const msg = detailOrDefault(
      e,
      'There was a problem completing that action.'
    )
    toast(msg, { type: 'error', options: { autoClose } })
  }
}
