import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import * as settings from 'const/settings'
import {
  getWorkflowSteps,
  getWorkflowStepIds,
} from 'store/campaign-details/selectors'
import _ from 'lodash'
import { notUndefined } from 'util/typeguards'
import { detectBitlyLinks } from 'util/detectBitlyLinks'
import {
  ICampaignScriptStep,
  PromptType,
  TestContact,
} from 'store/campaign-scripts/reducer'
import { assertNever } from 'util/exhaustiveness'
import { NEW_PROMPT_OPTION_INITIAL_MESSAGE } from 'util/campaign-scripts'

export const getCampaignScriptsCollection = (state: IState) => {
  return state.campaignScripts.scriptPreviewsById
}

export const getCampaignScriptsIdCollection = (state: IState) => {
  return state.campaignScripts.allIds
}

export const getCampaignScriptsTotalCount = (state: IState) => {
  return state.campaignScripts.totalCount
}

export const getCampaignScriptSearchResults = createSelector(
  getCampaignScriptsCollection,
  getCampaignScriptsIdCollection,
  (byId, ids) => ids.map(x => byId[x]).filter(notUndefined)
)

export const getCampaignScriptSearchHasResults = createSelector(
  getCampaignScriptSearchResults,
  results => results.length > 0
)

export const isCampaignScriptSearchLoading = (state: IState) =>
  state.campaignScripts.searching

export const getSortedCampaignScriptSearchResults = createSelector(
  getCampaignScriptSearchResults,
  results => results
)

export const getTemplateScriptsIdCollection = (state: IState) => {
  return state.campaignScripts.allTemplateIds
}

export const getTemplateScriptsCollection = (state: IState) => {
  return state.campaignScripts.templatePreviewsById
}

export const getTemplateScriptSearchResults = createSelector(
  getTemplateScriptsCollection,
  getTemplateScriptsIdCollection,
  (byId, ids) => ids.map(x => byId[x]).filter(notUndefined)
)

export const getSortedTemplateScriptSearchResults = createSelector(
  getTemplateScriptSearchResults,
  results => results
)

export const getCampaignScriptLoadingState = (state: IState) => {
  return state.campaignScripts.loading
}

export const getScriptSortOrder = (state: IState) => {
  return state.campaignScripts.ui.scriptSortOrder
}

export const getScriptSortField = (state: IState) => {
  return state.campaignScripts.ui.scriptSortField
}

export const getScriptSortSelectValue = (state: IState) => {
  const order = state.campaignScripts.ui.scriptSortOrder
  const field = state.campaignScripts.ui.scriptSortField
  return `${field}-${order}`
}

export const getScriptSearchQuery = (state: IState) => {
  return state.campaignScripts.ui.scriptSearchQuery
}

export const getScriptSearchTotalPages = createSelector(
  getCampaignScriptsTotalCount,
  totalCount => {
    return Math.ceil(totalCount / settings.CAMPAIGN_SCRIPT_LIST_LIMIT) || 0
  }
)

export const getInitialDialogStateId = createSelector(
  (state: IState) => state.campaignScripts.selectedDialog,
  getWorkflowSteps,
  dialog => {
    return dialog && dialog.initialState
  }
)

export const isCreatingScript = (state: IState) =>
  state.campaignScripts.creating

export const getLinkingStep = (
  state: IState
): ICampaignScriptStep | undefined => {
  if (state.campaignScripts.ui.linkFromId == null) {
    return undefined
  }
  return state.campaignScripts.steps.byId[state.campaignScripts.ui.linkFromId]
}

export const getLinkingTransition = (state: IState) =>
  state.campaignScripts.ui.linkTransition

export const getTestContacts = (state: IState) =>
  state.campaignScripts.ui.testContacts

function getChannelId(contact: TestContact): string | null {
  switch (contact.transport) {
    case 'unknown':
    case 'twilio':
      return contact.phone
    case 'slack':
      return contact.email
    case 'web':
    case 'facebook':
      return null
    default:
      assertNever(contact.transport)
  }
}

function labelForTestContact(contact: TestContact): string {
  const fullName =
    contact.name.full || `${contact.name.first} ${contact.name.last}`
  const channelId = getChannelId(contact)
  const channelIdFormated = channelId == null ? '' : `: ${channelId}`
  return fullName + channelIdFormated
}

export const getTestContactOptions = createSelector(
  getTestContacts,
  contacts => {
    return contacts.map(c => {
      const label = labelForTestContact(c)
      return {
        label,
        value: c.id,
        transport: c.transport,
        phone: c.phone,
        email: c.email,
      }
    })
  }
)

export const getDialogStates: (
  state: IState
) => ReadonlyArray<ICampaignScriptStep> = createSelector(
  getWorkflowSteps,
  getWorkflowStepIds,
  (stepsById, stepIds) => stepIds.map(id => stepsById[id]).filter(notUndefined)
)

export const getSelectedScript = (state: IState) =>
  state.campaignScripts.selectedDialog

export const getHasPlaceholderText: (state: IState) => boolean = createSelector(
  getDialogStates,
  states => {
    return !!states.find(({ prompt, multipleChoices }) => {
      const multiChoiceHasPlaceholder =
        multipleChoices &&
        multipleChoices.find(
          ({ prompt }) => prompt === NEW_PROMPT_OPTION_INITIAL_MESSAGE
        )
      return (
        prompt === settings.DEFAULT_DIALOG_STATE_MESSAGE ||
        multiChoiceHasPlaceholder
      )
    })
  }
)

export const getHasBitlyLinks: (state: IState) => boolean = createSelector(
  getDialogStates,
  states => {
    return !!states.find(({ prompt, multipleChoices }) => {
      const multiChoiceHasBiltyLink =
        multipleChoices &&
        multipleChoices.find(({ prompt }) => detectBitlyLinks(prompt))
      return detectBitlyLinks(prompt) || multiChoiceHasBiltyLink
    })
  }
)

export const getScriptIsBeingEdited: (
  state: IState
) => boolean = createSelector(getDialogStates, states =>
  states.some(({ editing }) => !!editing)
)

export const getHasExcessiveAutoPrompts: (
  state: IState
) => boolean = createSelector(
  getDialogStates,
  getSelectedScript,
  (states, script) => {
    // Returns true if the script begins with two auto prompts followed by at least one of any prompt.
    // We use this to inform the user of best practices –– excessive auto prompts like this are not only
    // quite rude but can also spawn massive amounts of `resumeDialog` jobs, potentially slowing our Agenda DB down.
    let currentState: ICampaignScriptStep | undefined =
      states[states.findIndex(state => state.id === script?.initialState)]
    let consecutiveStates = 1
    while (currentState?.promptType === PromptType.auto) {
      currentState =
        states[
          states.findIndex(
            state => state.id === currentState?.nextStates.default?.default
          )
        ]
      if (!currentState) {
        return false
      }
      consecutiveStates++
      if (consecutiveStates > 2) {
        return true
      }
    }
    return false
  }
)

export const getSelectedScriptCustomizations = createSelector(
  getSelectedScript,
  script => (script ? _.countBy(script.customLogic, 'type') : {})
)

export const getIntroDialogs = (state: IState) =>
  state.campaignScripts.introDialogs.list

export const getAreIntroDialogsLoading = (state: IState) =>
  state.campaignScripts.introDialogs.loading

export const getSelectedIntroDialog = (state: IState) =>
  state.campaignScripts.introDialogs.selectedIntroDialog

export const getReportDownloadProgressById = (state: IState) =>
  state.campaignScripts.introDialogs.reportDownloadProgressById

export const getSelectedDialogReportProgress = createSelector(
  getSelectedIntroDialog,
  getReportDownloadProgressById,
  (dialog, progressById) => {
    return dialog && progressById[dialog.id]
  }
)

export const getCampaignScriptIsUpdating = (state: IState) => {
  return state.campaignScripts.steps.updating
}
