import { getType } from 'typesafe-actions'
import _ from 'lodash'
import {
  IImportReportActions,
  listImportReports,
  getImportReport,
  updateOrdering,
  updateSearchQuery,
  updatePageSize,
} from 'store/import-reports/actions'
import { IMPORT_REPORTS_PER_PAGE } from 'store/import-reports/selectors'
import { WebData, Loading, Success, Failure } from 'store/webdata'
import { IContactLabel } from 'store/contacts/reducer'

export interface IImportReport {
  id: string
  creations: {
    successCount: number
    failureCount: number
    skipCount: number
  }
  updates: {
    successCount: number
    failureCount: number
    skipCount: number
  }
  labelsApplied: string[]
  name: string
  autoLabel: string
  csvColumnMapping: { [key: string]: string }
  status:
    | 'started'
    | 'pre-processing complete'
    | 'pre-processing failure'
    | 'mapping complete'
    | 'mapping failure'
    | 'completed'
  createdAt: string
  createdBy: string
  createdByName: string
  startedAt: string
  institutionId: string
  errors?: {
    error: string
    row_number: number
    message: string
    details: {
      rows: number[]
      where: { row: { [key: string]: string }; rowNumber: number }
    }
  }[]
  totalErrorCount: number
  operations: string
  phoneNumberUpdates: {
    rowNumber: number
    existingCollegeProfileId: string
    existingUserId: string
    originalPhoneNumber: string
    newPhoneNumber: string
  }[]
  csvFileName: null | string
  region: string | null
  s3FilePath: null | string
  source: string
  importLockBypassed: boolean
  testMode: boolean
  lastRowProcessed: number
  completedAt: string
  totalRecordCount: number
  contactLabels: IContactLabel[]
}

export type IImportReportListOrdering = '-createdAt' | 'createdAt'

export interface IImportReportState {
  allIds: string[]
  byId: { [id: string]: IImportReport }
  // TODO(sbdchd): we should add a byId loadingStatus for each importReport so
  // that we only need show a loading spinner on initial load and not for refetching.
  readonly loadingStatus: WebData<{}>
  totalCount: number
  ui: {
    pageSize: number
    ordering: IImportReportListOrdering
    searchQuery: string
  }
}

const initialState: IImportReportState = {
  allIds: [],
  byId: {},
  loadingStatus: undefined,
  totalCount: 0,
  ui: {
    pageSize: IMPORT_REPORTS_PER_PAGE,
    ordering: '-createdAt',
    searchQuery: '',
  },
}

const importReportsReducer = (
  state: IImportReportState = initialState,
  action: IImportReportActions
): IImportReportState => {
  switch (action.type) {
    case getType(getImportReport.request):
    case getType(listImportReports.request):
      return {
        ...state,
        loadingStatus: Loading(),
      }
    case getType(getImportReport.success):
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        allIds: _.uniq([...state.allIds, action.payload.id]),
        loadingStatus: Success({}),
      }
    case getType(listImportReports.success):
      const byId = _.keyBy(action.payload.results, 'id')
      return {
        ...state,
        byId,
        allIds: Object.keys(byId),
        loadingStatus: Success({}),
        totalCount: action.payload.count,
      }
    case getType(getImportReport.failure):
    case getType(listImportReports.failure):
      return {
        ...state,
        loadingStatus: Failure(undefined),
      }
    case getType(updateSearchQuery):
      return {
        ...state,
        ui: {
          ...state.ui,
          searchQuery: action.payload,
        },
      }
    case getType(updateOrdering):
      return {
        ...state,
        ui: {
          ...state.ui,
          ordering: action.payload,
        },
      }
    case getType(updatePageSize):
      return {
        ...state,
        ui: {
          ...state.ui,
          pageSize: action.payload,
        },
      }
    default:
      return state
  }
}

export default importReportsReducer
