import { IActions } from 'store/store'
import {
  IAggregateData,
  ICampaignDetailResponseData,
  ICampaignHistoryResponseData,
  ICampaignTriggerResponseData,
  ICampaignTriggerDetail,
  ICreationInfo,
  IEngagementCounts,
  IRecurringCampaignDetailsResponseData,
  IDialog,
  ICampaignByDialogDetailsResponseData,
} from 'api/response'
import {
  setCampaignHistoryFilterBy,
  setCampaignHistorySortBy,
  fetchCampaignHistory,
  fetchCampaignCountByType,
  fetchCampaignTriggers,
} from 'store/campaign-history/actions'
import { getType } from 'typesafe-actions'
import { ICounts } from 'page/campaign/CampaignHistory'
import moment from 'moment'
import { IRecurrenceSettings } from 'store/campaign-scheduler/reducer'
import { ICountsResponseData } from 'api'

export interface ICampaignBase {
  readonly upcoming: boolean
  readonly lastSentRecurringCampaign: Date | null
  readonly date: Date | null
  readonly id: string
  readonly name: string
  readonly recurring: boolean
  readonly description: string
  readonly isRespondable: boolean
  readonly countDistinctUsersResponded?: number
  readonly countEligibleUsersProcessed: number
  readonly isParentRecurringInstance?: boolean
  readonly started?: boolean
  readonly region?: string | null
}

export interface ICampaign extends ICampaignBase {
  readonly sidebarFilter: string
  readonly messagingService: string
  readonly optOutUsers: number
  readonly deliveryFailureUsers: number
  readonly pendingUsers: number
  readonly numberSent: number
  readonly workflowHumanName: string
  readonly recipientLabel: null | string
  readonly importReportId: null | string
  readonly filterName: null | string
  readonly completed: boolean
  readonly isIntro: boolean
  readonly expirationInMins: number | null
  readonly scriptId: string
  readonly scriptHidden?: boolean
  readonly engagementData: IEngagementCounts
  readonly aggregateData: IAggregateData | null
  readonly contactFilterId?: number
  readonly creationInfo: ICreationInfo
  readonly sendCampaignOnce?: boolean
  readonly recurrenceSettings?: IRecurrenceSettings
  readonly enabled?: boolean
  readonly timeDelay?: number
}

interface IRecurringCampaign extends ICampaign {
  readonly recurrenceSettings: IRecurrenceSettings
  readonly sendDate: string
}

export interface ICampaignTrigger {
  readonly id: string
  readonly name: string
  readonly description: string
  readonly contactFilterId: number
  readonly dialogId: string
  readonly timeDelay: number
  readonly sendCampaignOnce: boolean
  readonly isRespondable: boolean
  readonly countDistinctUsersResponded?: number
  readonly countEligibleUsersProcessed: number
  readonly enabled: boolean
  readonly lastSentDate: Date | null
  readonly isIntro?: boolean
  readonly region?: string | null
}

export interface ICampaignTriggerDetails
  extends Omit<ICampaignTrigger, 'dialogId' | 'lastSentDate'> {
  scriptHidden?: boolean
  workflowHumanName: string
  recurring: boolean
  date: Date
  filterName: string
  creationInfo: ICreationInfo
  scriptId: string
  aggregateData: IEngagementCounts
  engagementData: IEngagementCounts
  pendingUsers: number
  enabled: boolean
  expirationInMins?: number
  workflow: IDialog
}

export interface ICampaignByDialogDetails {
  readonly creationInfo: ICreationInfo
  readonly engagementData: IEngagementCounts
  readonly aggregateData: IEngagementCounts
  readonly name: string
  readonly urlPath: string
  readonly isRespondable: boolean
  readonly expirationInMins: number
  readonly currentDialogId: string | null
  readonly isIntro: boolean
  readonly scriptId: string
  readonly filterName?: string | null
  readonly scriptHidden?: boolean
  readonly countDistinctUsersResponded?: number
  readonly recurring: boolean
  readonly date: Date | null
  readonly workflowHumanName: string
  readonly countEligibleUsersProcessed: number
}

export enum SortBy {
  name = 'name',
  sent = 'sent',
  date = 'scheduledAt',
  type = 'type',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum Filters {
  all = 'all',
  sent = 'sent',
  sending = 'sending',
  upcoming = 'upcoming',
  recurring = 'recurring',
  is_webchat_intro = 'is_webchat_intro',
  is_other_intro = 'is_other_intro',
  is_datatriggered = 'is_datatriggered',
  is_hellopage = 'is_hellopage',
}

interface ICampaignHistoryState {
  readonly loading: boolean
  readonly sortBy: {
    readonly name: SortBy
    readonly order: SortOrder
  }
  readonly sidebarFilter: Filters
  readonly counts: ICounts | undefined
  readonly campaigns: ICampaignBase[]
  readonly triggers: ICampaignTrigger[]
  readonly campaignCountsByType: ICounts
}

const INITIAL_STATE: ICampaignHistoryState = {
  loading: false,
  sortBy: {
    name: SortBy.date,
    order: SortOrder.desc,
  },
  counts: undefined,
  campaignCountsByType: {},
  sidebarFilter: Filters.all,
  campaigns: [],
  triggers: [],
}

export const mapCampaignDetailResponseToCampaignDetailState = (
  response: ICampaignDetailResponseData
): ICampaign => ({
  isRespondable: response.isRespondable,
  id: response.id,
  name: response.name,
  description: response.description,
  date: new Date(response.scheduledAt),
  expirationInMins: response.expirationInMins,
  lastSentRecurringCampaign: response.lastSentRecurringCampaign
    ? new Date(response.lastSentRecurringCampaign)
    : null,
  countDistinctUsersResponded: response.distinctResponses,
  sidebarFilter: response.sidebarFilter,
  messagingService: response.messagingService,
  numberSent: response.countEligibleUsersProcessed,
  optOutUsers: response.optOutUsers,
  deliveryFailureUsers: response.deliveryFailureUsers,
  pendingUsers: response.pendingUsers,
  workflowHumanName: response.workflow.humanName,
  recipientLabel: response.recipientLabel,
  importReportId: response.importReportId,
  filterName: response.filterName,
  completed: response.completed,
  recurring: response.recurring,
  recurrenceSettings: response.recurrenceSettings,
  isIntro: response.isIntro,
  upcoming: moment(response.scheduledAt).isAfter(moment()),
  scriptId: response.workflow.id,
  scriptHidden: response.workflow.hidden,
  engagementData: response.engagementData,
  aggregateData: response.aggregateData,
  contactFilterId: response.contactFilter,
  creationInfo: response.creationInfo,
  sendCampaignOnce: response.sendCampaignOnce || false,
  countEligibleUsersProcessed: response.countEligibleUsersProcessed,
  region: response.region,
})

export const mapHelloPageCampaignDetailResponseToCampaignDetailState = (
  response: ICampaignByDialogDetailsResponseData['campaign']
): ICampaignByDialogDetails => ({
  expirationInMins: response.expirationInMins,
  workflowHumanName: response.workflow.humanName,
  urlPath: response.urlPath,
  name: response.name,
  isIntro: true,
  scriptId: response.workflow.id,
  currentDialogId: response.currentDialogId,
  scriptHidden: response.workflow.hidden,
  engagementData: response.engagementData,
  aggregateData: response.engagementData,
  creationInfo: response.creationInfo,
  isRespondable: response.workflow.isInteractive || false,
  countDistinctUsersResponded: response.engagementData.totalDistinctResponses,
  recurring: false,
  date: response.scheduledAt ? new Date(response.scheduledAt) : null,
  filterName: null,
  countEligibleUsersProcessed:
    response.engagementData.countEligibleUsersProcessed,
})

export const mapRecurringCampaignDetailResponseToCampaignDetailState = (
  response: IRecurringCampaignDetailsResponseData
): IRecurringCampaign => ({
  ...mapCampaignDetailResponseToCampaignDetailState(response.campaign),
  sendDate: response.campaign.sendDate,
  recurrenceSettings: response.campaign.recurrenceSettings,
})

const mapCampaignHistoryResponseToCampaignHistoryState = (
  response: ICampaignHistoryResponseData
): ICampaignBase => ({
  isRespondable: response.isRespondable,
  id: response.id,
  name: response.name,
  description: response.description,
  started: response.started,
  date: new Date(response.scheduledAt),
  lastSentRecurringCampaign: response.lastSentRecurringCampaign
    ? new Date(response.lastSentRecurringCampaign)
    : null,
  countDistinctUsersResponded: response.distinctResponses,
  recurring: response.recurring,
  upcoming: moment(response.scheduledAt).isAfter(moment()),
  countEligibleUsersProcessed: response.countEligibleUsersProcessed,
  isParentRecurringInstance: response.isParentRecurringInstance,
  region: response.region,
})

const mapCampaignTriggerResponseToCampaignTriggerState = (
  response: ICampaignTriggerResponseData
): ICampaignTrigger => ({
  isRespondable: response.isRespondable,
  id: response.id,
  name: response.name,
  isIntro: false,
  dialogId: response.dialogId,
  contactFilterId: response.contactFilterId,
  timeDelay: response.timeDelay,
  sendCampaignOnce: response.sendCampaignOnce,
  description: response.description,
  lastSentDate: response.lastSentDate ? new Date(response.lastSentDate) : null,
  countEligibleUsersProcessed: response.countEligibleUsersProcessed,
  countDistinctUsersResponded: response.distinctResponses,
  enabled: response.enabled,
  region: response.region,
})

export const mapCampaignTriggerResponseToCampaignDetailState = (
  response: ICampaignTriggerDetail
): ICampaignTriggerDetails => ({
  id: response.id,
  name: response.name,
  description: response.description,
  workflowHumanName: response.dialog.humanName,
  scriptId: response.dialogId,
  scriptHidden: response.dialog.scriptHidden,
  recurring: false,
  isRespondable: response.isInteractive,
  date: new Date(response.createdAt),
  engagementData: response.engagementData,
  filterName: response.filterName,
  contactFilterId: response.contactFilterId,
  creationInfo: response.creationInfo,
  enabled: response.enabled,
  workflow: response.dialog,
  timeDelay: response.timeDelay,
  sendCampaignOnce: response.sendCampaignOnce,
  countEligibleUsersProcessed:
    response.engagementData.countEligibleUsersProcessed,
  countDistinctUsersResponded: response.engagementData.totalDistinctResponses,
  aggregateData: response.engagementData,
  pendingUsers: 0,
  region: response.region,
  expirationInMins: response.dialog.expirationLength,
})

const mapCountsResponseToCountsHistory = (
  response: ICountsResponseData
): ICounts => ({
  totalCount: response.total_count,
  sentCount: response.sent_count,
  upcomingCount: response.upcoming_count,
  recurringCount: response.recurring_count ?? 0,
  otherIntroCount: response.other_intro_count,
  webchatIntroCount: response.webchat_intro_count,
  totalIntroCount:
    response.other_intro_count +
    response.webchat_intro_count +
    response.hellopages_count,
  dataTriggeredCount: response.datatriggered_count ?? 0,
  helloPagesCount: response.hellopages_count,
  sendingCount: response.sending_count,
})

const reducer = (
  state: ICampaignHistoryState = INITIAL_STATE,
  action: IActions
): ICampaignHistoryState => {
  switch (action.type) {
    case getType(fetchCampaignHistory.request): {
      return {
        ...state,
        loading: true,
      }
    }
    case getType(fetchCampaignHistory.failure): {
      return {
        ...state,
        loading: false,
      }
    }
    case getType(fetchCampaignHistory.success): {
      return {
        ...state,
        loading: false,
        campaigns: action.payload.campaigns.map(
          mapCampaignHistoryResponseToCampaignHistoryState
        ),
      }
    }
    case getType(fetchCampaignTriggers.request): {
      return {
        ...state,
        loading: true,
      }
    }
    case getType(fetchCampaignTriggers.failure): {
      return {
        ...state,
        loading: false,
      }
    }
    case getType(fetchCampaignTriggers.success): {
      return {
        ...state,
        loading: false,
        triggers: action.payload.map(
          mapCampaignTriggerResponseToCampaignTriggerState
        ),
      }
    }
    case getType(fetchCampaignCountByType): {
      return {
        ...state,
        campaignCountsByType: mapCountsResponseToCountsHistory(
          action.payload.counts
        ),
      }
    }
    case getType(setCampaignHistoryFilterBy): {
      return {
        ...state,
        sidebarFilter: action.payload,
      }
    }
    case getType(setCampaignHistorySortBy): {
      return {
        ...state,
        sortBy: { name: action.payload.sortBy, order: action.payload.order },
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
