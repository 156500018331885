import { stringify, ParsedQuery } from 'query-string'
import { parseQueryString } from 'util/string'
import { LocationDescriptor } from 'history'

type Url = {
  search: string
  audienceSearch?: string
}

export function appendQueryFilter(
  url: Url,
  filterGroup: string,
  value: string,
  multi?: boolean,
  hash?: string
): string {
  const queryParams: Mutable<ParsedQuery> = getQueryFilters(url)
  if (hasQueryFilter(url, filterGroup, value)) {
    return `?${stringify(queryParams)}${hash ?? ''}`
  }
  if (multi && filterGroup in queryParams) {
    const param = queryParams[filterGroup]
    if (param != null) {
      queryParams[filterGroup] = [value].concat(param)
    }
  } else {
    queryParams[filterGroup] = value
  }

  return `?${stringify(queryParams)}${hash ?? ''}`
}

export function removeQueryFilter(
  url: Url,
  filterGroup: string,
  value?: string,
  hash?: string
) {
  const queryParams: Mutable<ParsedQuery> = getQueryFilters(url)
  const filterValues = queryParams[filterGroup]
  if (value && Array.isArray(filterValues)) {
    queryParams[filterGroup] = filterValues.filter(
      filterValue => filterValue !== value
    )
  } else if (filterValues === value || !value) {
    delete queryParams[filterGroup]
  }

  return `?${stringify(queryParams)}${hash ?? ''}`
}

/*
  This is a very specific combination of the main techniques used in appendQueryFilter and removeQueryFilter used to
  clear out the pagination on the Campaign Table page on sidebar navigation, while retaining the other query params.
 */
export function appendAndRemoveQueryFilter({
  url,
  appendGroup,
  appendValue,
  removeGroup,
}: {
  url: Url
  appendGroup: string
  appendValue: string
  removeGroup: string
}) {
  const queryParams: Mutable<ParsedQuery> = getQueryFilters(url)
  queryParams[appendGroup] = appendValue
  delete queryParams[removeGroup]
  return `?${stringify(queryParams)}`
}

export function appendAndRemoveQueryFilters({
  url,
  appendPairs,
  removeGroups,
}: {
  url: Url
  appendPairs: {
    filterGroup: string
    value: string
  }[]
  removeGroups: string[]
}) {
  const queryParams: Mutable<ParsedQuery> = getQueryFilters(url)
  appendPairs.forEach(pair => {
    queryParams[pair.filterGroup] = pair.value
  })
  removeGroups.forEach(filterGroup => {
    delete queryParams[filterGroup]
  })
  return `?${stringify(queryParams)}`
}

export function hasQueryFilter(url: Url, filterGroup: string, value?: string) {
  const queryParams = getQueryFilters(url)
  if (value || value === '') {
    const filterValues = queryParams[filterGroup]
    return Array.isArray(filterValues)
      ? filterValues.includes(value)
      : filterValues === value
  }
  return filterGroup in queryParams
}

export function getQueryFilters(url: Url): ParsedQuery {
  return parseQueryString(url.search)
}

export function getAudienceQueryFilters(url: Url): ParsedQuery {
  return parseQueryString(url.audienceSearch || '')
}

export const getReturnLocation = (root: string): LocationDescriptor => ({
  pathname: root,
  search: window.location.search,
})
