import { useMemo } from 'react'
import {
  getAvailableUserGroups,
  getUserGroups,
} from 'store/triage/profile/selectors'
import { useSelector, useFeatures } from 'util/hooks'
import Select from 'components/Select/SelectV2'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import PermissionGuard from 'util/permissions/PermissionGuard'

export interface IUserGroupSelectValue {
  label: string
  value: string | null
}

export const UserGroupSelector = ({
  onChange,
  region,
  name,
  className,
  setDefaultRegion,
  permission,
}: {
  onChange: (value: IUserGroupSelectValue) => void
  name?: string
  region?: string | null
  className?: string
  setDefaultRegion?: (value: string | null) => void
  permission?: string
}) => {
  const { hasFeature, FeaturesType } = useFeatures()

  const userGroups = useSelector(getUserGroups)
  const allAvailableGroups = useSelector(getAvailableUserGroups)

  const readOnly = !!(userGroups && userGroups.length === 1)

  const userGroupOptions: IUserGroupSelectValue[] = useMemo(() => {
    const allContactsOption = { label: 'All Contacts', value: null }

    if (!hasFeature(FeaturesType.PERMS_REGIONS_ENABLED)) {
      // If this feature is turned off, we will default the region form value to `null`
      return [allContactsOption]
    }

    // If a user has a null/empty value for `userGroups`, it means they have access to all user groups.
    if (!userGroups || userGroups.length === 0) {
      const nationalOptions = allAvailableGroups.map(group => {
        return {
          label: group,
          value: group,
        }
      })

      return [allContactsOption, ...nationalOptions.sort()]
    }

    // If a user has a value for `userGroups`, it means they have access to only specific user groups.
    if (userGroups?.length) {
      return userGroups.map(group => {
        return {
          label: group,
          value: group,
        }
      })
    }

    return []
  }, [
    allAvailableGroups,
    userGroups,
    hasFeature,
    FeaturesType.PERMS_REGIONS_ENABLED,
  ])

  const { id } = useParams<{ id: string }>()
  const setInitialValue = useMemo(() => {
    let defaultRegion = userGroupOptions[0]
    if (id) {
      defaultRegion =
        userGroupOptions.find(option => option.value === region) ||
        defaultRegion
    }

    if (setDefaultRegion) {
      setDefaultRegion(defaultRegion?.value)
    }

    return defaultRegion
  }, [region, userGroupOptions, id, setDefaultRegion])

  const userGroupSelect = (
    <Select
      className={classNames({ 'w-25': permission === undefined }, className)}
      name={name}
      id={id}
      options={userGroupOptions}
      defaultValue={setInitialValue}
      value={userGroupOptions.find(option => option.value === region)}
      onChange={option => {
        if (Array.isArray(option) || option == null) {
          return
        }
        onChange(option)
      }}
      closeMenuOnSelect={true}
      placeholder="Select a User Group"
      isDisabled={readOnly}
      readOnly={readOnly}
    />
  )

  return permission ? (
    <PermissionGuard permission={permission} className="w-25">
      {userGroupSelect}
    </PermissionGuard>
  ) : (
    userGroupSelect
  )
}
