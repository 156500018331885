import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'
import {
  Conversation,
  ConversationDetailsRequestStatus,
  ConversationMessage,
  Escalation,
  InflightMessage,
  LiveChatState,
  FlaggedMessages,
  ReplyToFlagState,
} from 'page/conversations-v2/ConversationsDetail/index'

export const fetchConversationDetails = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_FETCH_START',
  '@@MASCOT/CONVERSATION_DETAILS_FETCH_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_FETCH_FAILURE'
)<
  {
    readonly loadMoreNewer: boolean
    readonly loadMoreOlder: boolean
  },
  Conversation,
  ConversationDetailsRequestStatus
>()
export const markMessageAsUnread = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_MARK_MESSAGE_AS_UNREAD_START',
  '@@MASCOT/CONVERSATION_DETAILS_MARK_MESSAGE_AS_UNREAD_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_MARK_MESSAGE_AS_UNREAD_FAILURE'
)<
  {
    readonly messageId: string | null
    readonly timestamp: string
  },
  void,
  void
>()
export const updateMessageFlag = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_MESSAGE_FLAG_START',
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_MESSAGE_FLAG_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_MESSAGE_FLAG_FAILURE'
)<
  { readonly messageIds: string[]; readonly resolveAll: boolean },
  { readonly messageIds: string[]; readonly flagged: boolean },
  { readonly messageIds: string[] }
>()

export interface IAutomaticGenAIResponseForFallback {
  shouldGenerateResponse: boolean
  lastestFallbackSmsLogID: string | null
}
export const updateLiveChatState = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_LIVE_CHAT_STATE_MESSAGE_START',
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_LIVE_CHAT_STATE_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_LIVE_CHAT_STATE_FAILURE'
)<
  void,
  {
    chatState: LiveChatState
    genAIResponseForFallback?: IAutomaticGenAIResponseForFallback
  },
  {
    chatState: LiveChatState
    genAIResponseForFallback?: IAutomaticGenAIResponseForFallback
  }
>()
export const sendConversationMessage = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_SEND_MESSAGE_START',
  '@@MASCOT/CONVERSATION_DETAILS_SEND_MESSAGE_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_SEND_MESSAGE_FAILURE'
)<InflightMessage, void, void>()
export const transferToBot = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_TRANSFER_TO_BOT_START',
  '@@MASCOT/CONVERSATION_DETAILS_TRANSFER_TO_BOT_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_TRANSFER_TO_BOT_FAILURE'
)<void, void, void>()
export const extendLiveChat = createAsyncAction(
  '@@MASCOT/CONVERSATION_DETAILS_EXTEND_LIVE_CHAT_START',
  '@@MASCOT/CONVERSATION_DETAILS_EXTEND_LIVE_CHAT_SUCCESS',
  '@@MASCOT/CONVERSATION_DETAILS_EXTEND_LIVE_CHAT_FAILURE'
)<void, void, void>()
export const selectFlagForReply = createStandardAction(
  '@@MASCOT/CONVERSATION_DETAILS_REPLY_TO_FLAG'
)<ReplyToFlagState | null>()
export const onConversationMessage = createStandardAction(
  '@@MASCOT/CONVERSATION_DETAILS_ON_CONVERSATION_MESSAGE'
)<ConversationMessage>()
export const onConversationEscalation = createStandardAction(
  '@@MASCOT/CONVERSATION_DETAILS_ON_CONVERSATION_ESCALATION'
)<Escalation>()
export const fetchFlaggedMessageIds = createAsyncAction(
  '@@MASCOT/FLAGGED_MESSAGE_IDS_FETCH_START',
  '@@MASCOT/FLAGGED_MESSAGE_IDS_FETCH_SUCCESS',
  '@@MASCOT/FLAGGED_MESSAGE_IDS_FETCH_FAILURE'
)<void, FlaggedMessages, void>()
export const resolveAllFlaggedMessages = createAsyncAction(
  '@@MASCOT/RESOLVE_ALL_FLAGGED_MESSAGES_START',
  '@@MASCOT/RESOLVE_ALL_FLAGGED_MESSAGES_SUCCESS',
  '@@MASCOT/RESOLVE_ALL_FLAGGED_MESSAGES_FAILURE'
)<void, void, void>()
export const clearFlaggedMessages = createStandardAction(
  '@@MASCOT/CLEAR_FLAGGED_MESSAGES'
)<void>()
export const undoFlaggedMessages = createStandardAction(
  '@@MASCOT/UNDO_FLAGGED_MESSAGES'
)<void>()
export const updateConversationDetailsContact = createStandardAction(
  '@@MASCOT/CONVERSATION_DETAILS_UPDATE_CONTACT_DATA'
)<{ readonly name: string }>()

export const generateAIResponse = createAsyncAction(
  '@@MASCOT/GENERATIVE_AI_RESPONSE_START',
  '@@MASCOT/GENERATIVE_AI_RESPONSE_SUCCESS',
  '@@MASCOT/GENERATIVE_AI_RESPONSE_FAILURE'
)<
  void,
  {
    generative_response: string
    transaction_id?: number
    confident_in_answer: boolean | null | undefined
  },
  void
>()

export const acceptGenerativeAiResponse = createStandardAction(
  '@@MASCOT/ACCEPT_GENERATIVE_RESPONSE'
)<void>()

export const resetGenerativeAiResponse = createStandardAction(
  '@@MASCOT/RESET_GENERATIVE_RESPONSE'
)<void>()

export const setLastGeneratedSmsLogId = createStandardAction(
  '@@MASCOT/SET_LAST_GENERATED_SMS_LOG_ID'
)<string>()

export type IActions =
  | ActionType<typeof fetchConversationDetails>
  | ActionType<typeof markMessageAsUnread>
  | ActionType<typeof updateMessageFlag>
  | ActionType<typeof updateLiveChatState>
  | ActionType<typeof sendConversationMessage>
  | ActionType<typeof transferToBot>
  | ActionType<typeof extendLiveChat>
  | ActionType<typeof selectFlagForReply>
  | ActionType<typeof onConversationMessage>
  | ActionType<typeof onConversationEscalation>
  | ActionType<typeof fetchFlaggedMessageIds>
  | ActionType<typeof clearFlaggedMessages>
  | ActionType<typeof undoFlaggedMessages>
  | ActionType<typeof updateConversationDetailsContact>
  | ActionType<typeof generateAIResponse>
  | ActionType<typeof acceptGenerativeAiResponse>
  | ActionType<typeof resetGenerativeAiResponse>
  | ActionType<typeof setLastGeneratedSmsLogId>
