import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import { RouteComponentProps } from 'react-router'

import { getCampaignScriptsCollection } from 'store/campaign-scripts/selectors'
import {
  ICampaignSchedulerFormData,
  ICampaignSchedulerValidationData,
  StudentSelectionType,
  TriggerType,
} from 'store/campaign-scheduler/reducer'

export const IMPORTED_RECIPIENTS_PER_PAGE = 20

export const getDialogIdFromUrl = (
  _state: IState,
  ownProps: RouteComponentProps<{ dialogId: string }>
) => ownProps.match.params.dialogId

export const getSelectedContactLabels = (state: IState) => {
  return state.campaignScheduler.form.contactLabels
}

export const getSelectedImportLabels = (state: IState) => {
  return state.campaignScheduler.form.importLabels
}

export const getCampaignSchedulerFormData = (state: IState) => {
  return state.campaignScheduler.form
}

export const getSchedulerDate = (state: IState) => {
  return state.campaignScheduler.form.date
}

export const getRecurrenceSettings = (state: IState) => {
  return state.campaignScheduler.form.recurrenceSettings
}

export const getDataTriggeredSettings = (state: IState) => {
  return state.campaignScheduler.form.dataTriggeredSettings
}

export const getGlobalAppropriateTimeSettings = (state: IState) => {
  return state.triage.application.profile.currentUser.institution
    .globalAppropriateHourSettings
}

export const getStudentSelectionType = (state: IState) => {
  return state.campaignScheduler.form.studentSelectionType
}

export const getTriggerType = (state: IState) => {
  return state.campaignScheduler.form.triggerType
}

export const getValidationData = (state: IState) => {
  return state.campaignScheduler.errors
}

export const getCampaignName = (state: IState) =>
  state.campaignScheduler.form.name

export const getCampaignDescription = (state: IState) =>
  state.campaignScheduler.form.description

export const getSelectedContactFilterId = (state: IState) =>
  state.campaignScheduler.form.contactFilter

export const getSavedMultiAudiences = (state: IState) =>
  state.campaignScheduler.form.multiContactFilters

export const getSavedMultiAudienceDelay = (state: IState) =>
  state.campaignScheduler.form.multiContactFilterDelay

export const getSelectedContactFilter = (state: IState) =>
  state.campaignScheduler.selectedContactFilter

export const getRecipientGroupSelection = createSelector(
  getStudentSelectionType,
  getCampaignSchedulerFormData,
  getSelectedContactLabels,
  getSelectedImportLabels,
  getSelectedContactFilter,
  (
    studentSelectionType,
    form,
    contactLabels,
    importLabels,
    selectedContactFilter
  ) => {
    switch (studentSelectionType) {
      case StudentSelectionType.ContactSegment:
        return form.contactFilter ? selectedContactFilter.name : undefined
      case StudentSelectionType.UploadCSV:
        return form.importAutoLabel
      case StudentSelectionType.ImportLabel:
        return importLabels && importLabels.join(', ')
      case StudentSelectionType.CustomImportLabel:
        return contactLabels && contactLabels.map(x => x.text).join(', ')
      case StudentSelectionType.MultiAudience:
        return 'Multiple Audiences'
    }
  }
)

export const getSelectedScript = createSelector(
  getCampaignSchedulerFormData,
  getCampaignScriptsCollection,
  (form, scriptsById) => {
    return scriptsById[form.dialogId] && scriptsById[form.dialogId]
  }
)

export const getPreviewedScript = createSelector(
  getDialogIdFromUrl,
  getCampaignScriptsCollection,
  (id, byId) => id && byId[id]
)

export const getScriptSelection = createSelector(getSelectedScript, script => {
  return script && script.name
})

export const getScriptPreviewName = createSelector(
  getPreviewedScript,
  script => script && script.name
)

export const getScriptPreviewDescription = createSelector(
  getPreviewedScript,
  script => script && script.description
)

export const getScriptPreviewCreatedOn = createSelector(
  getPreviewedScript,
  script => (script ? script.createdAt : undefined)
)

export const getTriggerSelection = createSelector(
  getCampaignSchedulerFormData,
  getTriggerType,
  getGlobalAppropriateTimeSettings,
  (form, triggerType, globalAppropriateHourSettings) => {
    switch (triggerType) {
      case TriggerType.Immediate:
        return 'Send immediately'
      case TriggerType.SpecificDate:
        const timezone = globalAppropriateHourSettings.timezone
        return form.date
          ? moment.tz(form.date, timezone).format('L, h:mm a')
          : ''
      case TriggerType.DataTriggered:
        return 'Send on Audience entrance'
      case TriggerType.Recurring:
        return 'Send on a recurring schedule'
    }
  }
)

export const checkFieldsAreCompleted: (
  state: IState
) => boolean = createSelector(
  getCampaignName,
  getCampaignDescription,
  getRecipientGroupSelection,
  getScriptSelection,
  getTriggerSelection,
  (name, description, recipientGroup, script, trigger) => {
    return !!(name && description && recipientGroup && script && trigger)
  }
)

const checkFieldsAreValid = (
  validationData: Partial<ICampaignSchedulerValidationData>,
  fieldNames?: (keyof ICampaignSchedulerFormData)[]
) => {
  if (!fieldNames) {
    return Object.keys(validationData).length === 0
  }
  return fieldNames.reduce((acc, curr) => {
    const fieldIsValid =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      !validationData[curr] || validationData[curr]!.length === 0
    return acc && fieldIsValid
  }, true)
}

export const getIsStudentSelectionValid = createSelector(
  getValidationData,
  validationData => {
    return checkFieldsAreValid(validationData, [
      'importLabels',
      'importReportId',
      'contactLabels',
      'studentSelectionType',
    ])
  }
)
export const getIsScriptSelectionValid = createSelector(
  getValidationData,
  validationData => {
    return checkFieldsAreValid(validationData, ['dialogId'])
  }
)

export const getIsTriggerValid = createSelector(
  getValidationData,
  validationData => {
    return checkFieldsAreValid(validationData, [
      'triggerType',
      'date',
      'immediate',
      // @ts-expect-error form validation produces recurrenceSettings.endDate which
      // should not be defined as a property. There is already defined recurrenceSettings: { endDate: ... }
      'recurrenceSettings.endDate',
      'dataTriggeredSettings',
    ])
  }
)

export const getIsCampaignSchedulerValid = (state: IState) =>
  checkFieldsAreValid(state.campaignScheduler.errors)

export const getIsCampaignSchedulerFormDraft = (state: IState) =>
  Object.keys(state.campaignScheduler.touched).length > 0

export const isUploadingScheduledMessage = (state: IState) =>
  state.campaignScheduler.uploadingScheduledMessage

export const getHasBeenSubmitted = (state: IState) =>
  state.campaignScheduler.hasSubmitted

export const getSelectedAutoLabels = (state: IState) =>
  state.campaignScheduler.form.selectedAutoLabels
