import * as Raven from '@sentry/browser'
import { DEFAULT_CSV_FILE_NAME } from 'const/settings'
import { isAblyError } from 'page/conversations-v2/pubsub'

interface IOpenDownloadURL {
  readonly url: string
  readonly fileName?: string
}

/** Automatically open and download a file at the provided `url` */
export function openDownloadURL({
  url,
  fileName = DEFAULT_CSV_FILE_NAME,
}: IOpenDownloadURL): void {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', fileName)
  try {
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (e) {
    if (isAblyError(e)) {
      return
    }
    // tslint:disable:no-unsafe-any
    Raven.captureException(e)
  }
}
