import React from 'react'
import { connect } from 'react-redux'
import NavBarPage from 'page/NavBarPage'
import { Dispatch } from 'store/store'
import { clearCurrentContact } from 'store/contacts/actions'
import { CONTACTS } from 'const/routes'
import { MainstayPage } from 'components/MainstayPageContainer/MainstayPageContainer'
import { MainstaySidebarLinkType } from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSectionLink/MainstaySidebarSectionLink'
import MainstaySidebarNestedSection from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSection/MainstaySidebarNestedSection/MainstaySidebarNestedSection'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionGuard from 'util/permissions/PermissionGuard'
const links: MainstaySidebarLinkType[] = [
  {
    to: CONTACTS.AUDIENCES,
    text: 'Audiences',
    permission: PERMISSIONS.AUDIENCE.VIEW,
    event: {
      action: 'click',
      object: 'go to audiences',
    },
  },
  {
    to: CONTACTS.INDEX + '?filter=active',
    text: 'Contacts',
    permission: PERMISSIONS.CONTACT.VIEW,
    event: {
      action: 'click',
      object: 'go to contacts',
    },
  },
]
const contactsPageLinks: MainstaySidebarLinkType[] = [
  {
    to: CONTACTS.IMPORT_REPORT_LIST,
    text: 'Import History',
    permission: PERMISSIONS.IMPORT.VIEW,
    event: {
      location: 'contacts',
      action: 'click',
      object: 'go to import history',
    },
  },
  {
    to: CONTACTS.PROFILE_FIELDS,
    text: 'Custom Contact Fields',
    event: {
      location: 'contacts',
      action: 'click',
      object: 'go to contact fields',
    },
  },
  {
    to: CONTACTS.CONTACT_LABEL_MANAGER,
    text: 'Label Manager',
    event: {
      location: 'contacts',
      action: 'click',
      object: 'go to label manager',
    },
  },
]

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearCurrentContact: () => {
      dispatch(clearCurrentContact())
    },
  }
}

const UnconnectedImportReportNavbarPage: React.FC<{
  errorMsg?: string
  error?: boolean
  title: string
  contentClassName?: string
  children?: React.ReactElement
}> = ({
  error = false,
  errorMsg = undefined,
  title,
  children,
  contentClassName,
}) => (
  <NavBarPage
    title={title}
    errorMsg={errorMsg}
    error={error}
    className="d-flex placeholder-mobile-panels flex-grow-1 min-height-0"
    pageMainClassName="page-campaign-history">
    <MainstayPage
      header="Audience Manager"
      links={links}
      footer={
        <>
          <hr className="my-2" />
          {contactsPageLinks.map(link =>
            link.permission ? (
              <PermissionGuard
                key={`permission-${link.to}`}
                permission={link.permission}>
                <MainstaySidebarNestedSection key={link.to} link={link} />
              </PermissionGuard>
            ) : (
              <MainstaySidebarNestedSection key={link.to} link={link} />
            )
          )}
        </>
      }
      pageContentClassName={contentClassName}>
      {children}
    </MainstayPage>
  </NavBarPage>
)

export const ImportReportNavbarPage = connect(
  null,
  mapDispatchToProps
)(UnconnectedImportReportNavbarPage)
