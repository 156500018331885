import classNames from 'classnames'
import { Button, ButtonColor } from 'components/Button/Button'
import { EventAction } from 'components/EventTracker/EventTracker'
import { CenteredLoader } from 'components/Loader/Loader'
import 'components/Modal/Modal.scss'
import {
  ISimpleModalFooterProps,
  SimpleModalFooter,
} from 'components/SimpleModalFooter/SimpleModalFooter'
import {
  ISimpleModalHeaderProps,
  SimpleModalHeader,
} from 'components/SimpleModalHeader/SimpleModalHeader'
import { Form } from 'formik'
import * as React from 'react'
import { CustomInput, FormGroup, Modal, ModalBody } from 'reactstrap'
import { noOp } from 'util/noOp'

export enum ModalSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export interface IModalProps {
  show: boolean
  className?: string
  zIndex?: number | string
  size?: ModalSize
  onClose: () => void
  children?: React.ReactNode
}

type IMainstayModalProps = IModalProps &
  ISimpleModalFooterProps &
  ISimpleModalHeaderProps & {
    wrapContentInForm?: boolean
    headerClassName?: string
    contentClassName?: string
    footerClassname?: string
    noFooterPadding?: boolean
    hideFooter?: boolean
    scroll?: boolean
  }

const MainstayModalContent = (props: IMainstayModalProps) => {
  const className = classNames(props.contentClassName, {
    'scroll-y': props.scroll,
  })
  return (
    <>
      <ModalBody className={className}>{props.children}</ModalBody>
      {!props.hideFooter && (
        <SimpleModalFooter
          // with wrapContentInForm==true, the onSubmit for the button can be a noOp because it's handled by the form
          onSubmit={!props.wrapContentInForm ? props.onSubmit : noOp}
          onCancel={props.onCancel}
          noPadding={props.noFooterPadding}
          disableSubmit={props.disableSubmit}
          disableCancel={props.disableCancel}
          loading={props.loading}
          submitText={props.submitText}
          submitPermission={props.submitPermission}
          cancelText={props.cancelText}
          className={props.footerClassname}
          submitTrackingEvent={props.submitTrackingEvent}
          cancelTrackingEvent={props.cancelTrackingEvent}
        />
      )}
    </>
  )
}

export const MainstayModal = (props: IMainstayModalProps) => {
  return (
    <Modal
      zIndex={props.zIndex}
      isOpen={props.show}
      size={props.size}
      className={props.className}>
      <SimpleModalHeader
        className={props.headerClassName}
        onClose={props.onClose}
        text={props.text}
        hideX={false}
        hideDivider={false}
        cancelTrackingEvent={props.cancelTrackingEvent}
        closeTooltipContent={props.closeTooltipContent}
      />
      {props.wrapContentInForm ? (
        <Form>
          <MainstayModalContent {...props} />
        </Form>
      ) : (
        <MainstayModalContent {...props} />
      )}
    </Modal>
  )
}

type IConfirmationModalProps = IModalProps & {
  title?: string
  helpText: React.ReactNode
  confirmationCTA?: string
  confirmButtonText: string
  cancelButtonText?: string
  hideCheckbox?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  includeDivider?: boolean
  includeCloseIcon?: boolean
  confirmButtonClassName?: string
  cancelButtonClassName?: string
  confirmButtonColor?: ButtonColor
  cancelButtonColor?: ButtonColor
  onConfirm: (() => void) | undefined
  onCancel?: () => void
  eventObject?: string
  eventLocation?: string
  eventAction?: EventAction
}

interface IConfirmationModalState {
  confirmed: boolean
}

export class ConfirmationModal extends React.PureComponent<
  Omit<IConfirmationModalProps, 'size'>,
  IConfirmationModalState
> {
  state = {
    confirmed: !!this.props.hideCheckbox,
  }

  componentDidUpdate(prevProps: IConfirmationModalProps) {
    if (prevProps !== this.props) {
      this.setState({ confirmed: !!this.props.hideCheckbox })
    }
  }

  toggleConfirmed = () => {
    this.setState(s => ({ confirmed: !s.confirmed }))
  }

  render() {
    const confirmationCTA = this.props.confirmationCTA
      ? this.props.confirmationCTA
      : `Confirm ${this.props.confirmButtonText}`
    const confirmButtonColor = this.props.confirmButtonColor
      ? this.props.confirmButtonColor
      : 'new-ui-danger'
    const cancelButtonColor = this.props.cancelButtonColor
      ? this.props.cancelButtonColor
      : 'white'
    const cancelButtonText = this.props.cancelButtonText
      ? this.props.cancelButtonText
      : 'Cancel'
    return (
      <Modal
        zIndex={this.props.zIndex}
        onClose={this.props.onClose}
        isOpen={this.props.show}
        size={ModalSize.Medium}
        className={classNames(this.props.className, 'modal-max-h')}>
        {this.props.title && (
          <SimpleModalHeader
            onClose={this.props.onClose}
            text={this.props.title}
            hideX
            hideDivider
            noTextPadding
            cancelTrackingEvent={{
              location: this.props.eventLocation,
              action: 'click',
              object:
                this.props.eventObject && `cancel ${this.props.eventObject}`,
            }}
          />
        )}
        <ModalBody className="pt-3">
          {this.props.children}
          <div className="text-mainstay-dark-blue-80">
            {this.props.helpText}
          </div>
          {!this.props.hideCheckbox && (
            <FormGroup className="mb-0 pt-3">
              <CustomInput
                className="pointer text-mainstay-dark-blue-80"
                type="checkbox"
                onChange={this.toggleConfirmed}
                id="confirm"
                label={confirmationCTA}
              />
            </FormGroup>
          )}
        </ModalBody>
        {this.props.includeDivider && (
          <hr className="w-100 border-mainstay-dark-blue-20 mt-3" />
        )}
        <div className="ml-auto px-4 pt-3 pb-4">
          {!this.props.isReadonly && (
            <Button
              color={confirmButtonColor}
              className={classNames('mr-2', this.props.confirmButtonClassName)}
              onClick={this.props.onConfirm}
              disabled={
                !this.state.confirmed ||
                this.props.isLoading ||
                this.props.isDisabled
              }
              eventLocation={this.props.eventLocation}
              eventAction="click"
              eventObject={
                this.props.eventObject && `confirm ${this.props.eventObject}`
              }>
              {this.props.isLoading ? (
                <CenteredLoader size="small" />
              ) : (
                this.props.confirmButtonText
              )}
            </Button>
          )}
          <Button
            className={classNames(
              'text-mainstay-dark-blue-65',
              'border-color-mainstay-dark-blue-65',
              'border-1px',
              this.props.cancelButtonClassName,
              `bg-${cancelButtonColor}`
            )}
            onClick={this.props.onClose}
            eventLocation={this.props.eventLocation}
            eventAction="click"
            eventObject={
              this.props.eventObject && `cancel ${this.props.eventObject}`
            }>
            {cancelButtonText}
          </Button>
        </div>
      </Modal>
    )
  }
}
