import { Dispatch } from 'store/store'
import { fetchInstitutions } from 'store/institution/reducer'
import * as api from 'api'
import { HttpErrorKind } from 'store/webdata'
import { isRight } from 'fp-ts/lib/Either'

export interface IFetchInstitutions {
  readonly copyingScript?: boolean
}

export const fetchingInstitutions = (dispatch: Dispatch) => async ({
  copyingScript = false,
}: IFetchInstitutions) => {
  dispatch(fetchInstitutions.request({ copyingScript }))
  return await api.getInstitutions(copyingScript).then(res => {
    if (isRight(res)) {
      dispatch(
        fetchInstitutions.success({ institutions: res.right, copyingScript })
      )
    } else {
      dispatch(fetchInstitutions.failure(HttpErrorKind.other))
    }
  })
}
