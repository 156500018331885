import { IInsightsResponseData } from 'api/response'
import {
  ChartType,
  ChartWidth,
} from 'components/InsightsChartMenu/InsightsChartMenu'
import * as t from 'io-ts'
import * as et from 'io-ts/Type'
import { withFallback } from 'io-ts-types/lib/withFallback'
import { TransportEnum } from 'store/transport'
import { fromEnum } from 'util/schema'

// NOTE(neckenth): These values should match the InsightType values from the backend
export const InsightEnum = {
  Campaigns: 'campaigns',
  Conversations: 'conversations',
  Engagement: 'engagement',
  IncomingMessage: 'incoming_message',
  IncomingMessageByTimeOfDay: 'incoming_message_by_time_of_day',
  IncomingMessageByDayOfWeek: 'incoming_message_by_day_of_week',
  IndividualsContacted: 'individuals_contacted',
  OptOut: 'opt_out',
  EngagementByCampaign: 'engagement_by_campaign',
  CampaignsHistory: 'campaign_history_report',
  EscalatedMessages: 'escalated_messages_report',
  IndividualQuestionAndAnswer: 'individual_qa_report',
  BotSummaryReport: 'bot_summary_report',
  ReportBundle: 'report_bundle',
  ImportantTopics: 'important_topics',
  MissedQuestions: 'missed_questions',
  AnsweredQuestions: 'answered_questions',
  WebChatFeedBack: 'webchat_feedback',
} as const

export const humanizedInsightGraphsName = {
  [InsightEnum.Campaigns]: 'Campaigns',
  [InsightEnum.Conversations]: 'Conversations',
  [InsightEnum.Engagement]: 'Engagement',
  [InsightEnum.IndividualsContacted]: 'Individuals Contacted',
  [InsightEnum.IncomingMessage]: 'Incoming Messages',
  [InsightEnum.IncomingMessageByTimeOfDay]: 'Incoming Messages By Time Of Day',
  [InsightEnum.IncomingMessageByDayOfWeek]: 'Incoming Messages By Day Of Week',
  [InsightEnum.OptOut]: 'Opt Outs',
  [InsightEnum.CampaignsHistory]: 'Campaign History',
  [InsightEnum.EngagementByCampaign]: 'Contact Engagement By Campaign',
  [InsightEnum.ImportantTopics]: 'Important Topics',
  [InsightEnum.MissedQuestions]: 'Missed Questions',
  [InsightEnum.AnsweredQuestions]: 'Answered Messages',
  [InsightEnum.WebChatFeedBack]: 'Webchat Feedback',
} as const

export const humanizedInsightReportName = {
  ...humanizedInsightGraphsName,
  [InsightEnum.IndividualQuestionAndAnswer]: 'Individual Question & Answer',
  [InsightEnum.EscalatedMessages]: 'Escalated Messages',
  [InsightEnum.BotSummaryReport]: 'Bot Summary',
  [InsightEnum.ReportBundle]: 'Report Bundle',
  unknown: 'Unknown',
} as const

export const humanizedEngagementChoices = {
  [InsightEnum.Campaigns]: 'Campaigns',
  [InsightEnum.Conversations]: 'Conversations',
  [InsightEnum.Engagement]: 'Engagement',
  [InsightEnum.IndividualsContacted]: 'Individuals Contacted',
  [InsightEnum.IncomingMessageByTimeOfDay]: 'Incoming Messages By Time Of Day',
  [InsightEnum.IncomingMessageByDayOfWeek]: 'Incoming Messages By Day Of Week',
  [InsightEnum.OptOut]: 'Opt Outs',
  [InsightEnum.ReportBundle]: 'Report Bundle',
}

export const humanizedKnowledgeChoices = {
  [InsightEnum.ImportantTopics]: 'Important Topics',
  [InsightEnum.MissedQuestions]: 'Missed Questions',
  [InsightEnum.AnsweredQuestions]: 'Answered Messages',
  [InsightEnum.WebChatFeedBack]: 'Webchat Feedback',
}

export const humanizedInsightChoices = {
  ...humanizedEngagementChoices,
  ...humanizedKnowledgeChoices,
}

export const categoryToInsight = {
  Engagement: humanizedEngagementChoices,
  Knowledge: humanizedKnowledgeChoices,
}

export const TimeBucketEnum = {
  Hour: 'hour',
  Day: 'day',
  Week: 'week',
  Month: 'month',
  DayOfWeek: 'dayofweek',
  DayOfYear: 'dayofyear',
} as const

export const InsightCategoryEnum = {
  Engagement: 'Engagement',
  Knowledge: 'Knowledge',
} as const

export type InsightCategory = typeof InsightCategoryEnum[keyof typeof InsightCategoryEnum]
export type Graphs = typeof humanizedInsightChoices[keyof typeof humanizedInsightChoices]
export type Insights = typeof InsightEnum[keyof typeof InsightEnum] | 'unknown'

export type TimeBucket = typeof TimeBucketEnum[keyof typeof TimeBucketEnum]

const TBaseInsightsDataPoints = t.type({
  insights_bucket: t.number,
})

const TWeekBucketDataPoints = t.type({
  week: t.number,
  year: t.number,
})

export const TEngagementDataPoints = t.intersection([
  TBaseInsightsDataPoints,
  TWeekBucketDataPoints,
  t.type({
    received: t.number,
    receivedUnique: t.number,
    sent: t.number,
    sentUnique: t.number,
  }),
])
export const TConversationsDataPoints = t.intersection([
  TBaseInsightsDataPoints,
  TWeekBucketDataPoints,
  t.type({
    aiCount: et.nullable(t.number),
    scriptCount: et.nullable(t.number),
    liveChatCount: et.nullable(t.number),
    otherCount: et.nullable(t.number),
  }),
])

export const TOptOutsDataPoints = t.intersection([
  TBaseInsightsDataPoints,
  TWeekBucketDataPoints,
  t.type({
    softStopCount: t.number,
    hardStopCount: t.number,
  }),
])

export const TIncomingMessagesDataPoints = t.intersection([
  TBaseInsightsDataPoints,
  t.type({
    count: t.number,
  }),
])

export const TIndividualsContactedDataPoints = t.intersection([
  TBaseInsightsDataPoints,
  TWeekBucketDataPoints,
  t.type({
    count: t.number,
  }),
])

export const TImportantTopicsDataPoints = t.array(
  t.type({
    topicAndSubtopic: t.string,
    percentage: t.number,
    total: t.number,
  })
)

export const KnowledgeMatchingCount = t.type({
  total: t.number,
  totalMatched: t.number,
  totalMatchedCommand: withFallback(t.number, 0),
  totalUnmatched: t.number,
  totalNoMatch: withFallback(t.number, 0),
  totalNoAnswer: withFallback(t.number, 0),
})

export const ReviewItemsShape = t.type({
  bot_could_not_answer_count: t.number,
  needs_personalized_answer_count: t.number,
  needs_interactive_answer_count: t.number,
  answer_incorrect_count: t.number,
  contact_feedback_count: t.number,
})

export const UnderstandingsCount = t.type({
  total: t.number,
  unapproved: t.number,
  noAnswers: t.number,
})

export const WebChatFeedBackStatisticsShape = t.type({
  thumbs_up_count: t.number,
  thumbs_down_count: t.number,
  total_requested: t.number,
  total_responded: t.number,
})

export type WebchatFeedbackStatistics = t.TypeOf<
  typeof WebChatFeedBackStatisticsShape
>

export const TMissedQuestionsDataPoints = t.array(
  t.type({
    knowledgeMatchingData: KnowledgeMatchingCount,
    reviewItemsData: ReviewItemsShape,
    overviewData: UnderstandingsCount,
  })
)

export const TAnsweredQuestionsDataPoints = t.array(
  t.type({
    knowledgeMatchingData: KnowledgeMatchingCount,
    reviewItemsData: ReviewItemsShape,
  })
)

export const TWebchatFeedbackDataPoints = t.array(
  WebChatFeedBackStatisticsShape
)

export interface IIncomingMessagesDataPoints
  extends t.TypeOf<typeof TIncomingMessagesDataPoints> {}

export const isIncomingMessagesInsight = (
  res: IInsightsResponseData<{}>
): res is IInsightsResponseData<IIncomingMessagesDataPoints> =>
  res.data.every(d => 'count' in d.points)

const REQUIRED_CAMPAIGN_DATA_POINTS_FIELDS = [
  'interactiveCount',
  'nudgeCount',
  'totalCount',
  'week',
  'year',
]

export const TCampaignsInsightsDataPoints = t.type({
  interactiveCount: t.number,
  nudgeCount: t.number,
  totalCount: t.number,
  week: t.number,
  year: t.number,
})

export interface ICampaignsInsightsDataPoints
  extends t.TypeOf<typeof TCampaignsInsightsDataPoints> {}

export const isCampaignsInsight = (
  res: IInsightsResponseData<{}>
): res is IInsightsResponseData<ICampaignsInsightsDataPoints> => {
  return REQUIRED_CAMPAIGN_DATA_POINTS_FIELDS.every(point =>
    res.data.every(d => Object.keys(d.points).includes(point))
  )
}

export enum ChartResponseType {
  BAR = 'BAR',
  STACKED_BAR = 'STACKED_BAR',
  LINE = 'LINE',
  DONUT = 'DONUT',
  REPORTS = 'REPORTS',
}

export const chartResponseTypeToChartType = (
  responseChartType: ChartResponseType
): ChartType | null => {
  switch (responseChartType) {
    case ChartResponseType.BAR:
      return ChartType.BAR
    case ChartResponseType.STACKED_BAR:
      return ChartType.STACKED_BAR
    case ChartResponseType.LINE:
      return ChartType.LINE
    case ChartResponseType.DONUT:
      return ChartType.DONUT
    case ChartResponseType.REPORTS:
    default:
      return null
  }
}

const InsightsFilterType = t.type({
  contact_filter: t.union([t.number, t.undefined, t.null]),
  date_range_expression: t.string,
  transports: t.array(fromEnum('TransportEnum', TransportEnum)),
})

export const DashboardResponseType = t.type({
  dashboard: t.type({
    id: t.union([t.number, t.undefined, t.null]),
    share_id: t.union([t.string, t.undefined, t.null]),
    user_id: t.union([t.string, t.undefined, t.null]),
    description: t.union([t.string, t.undefined, t.null]),
    insights_filter: InsightsFilterType,
    name: t.string,
    team_access: t.union([t.literal('VIEW'), t.literal('EDIT')]),
    public: t.boolean,
  }),
  charts: t.array(
    t.type({
      id: t.union([t.number, t.undefined, t.null]),
      category: fromEnum('InsightCategoryEnum', InsightCategoryEnum),
      chart_type: fromEnum('ChartResponseType', ChartResponseType),
      description: t.union([t.string, t.undefined, t.null]),
      insight: fromEnum('InsightEnum', InsightEnum),
      insights_filter: t.union([InsightsFilterType, t.undefined, t.null]),
      name: t.string,
      order: t.number,
      time_bucket: t.union([
        fromEnum('TimeBucketEnum', TimeBucketEnum),
        t.undefined,
        t.null,
      ]),
      width: fromEnum('ChartWidth', ChartWidth),
    })
  ),
})

export type DashboardResponse = t.TypeOf<typeof DashboardResponseType>
export type InsightsFilter = t.TypeOf<typeof InsightsFilterType>
