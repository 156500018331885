import { isLeft } from 'fp-ts/lib/Either'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { http2DetailOrDefault, keyOrDefault } from 'api/http'
import axios, { CancelTokenSource } from 'axios'
import { IOption } from 'components/AsyncCampaignSearch/AsyncCampaignSearch'
import { VALID_URL_REGEX } from 'components/AttributeDrawer/AttributeFormValidationSchema'
import { Button } from 'components/Button/Button'
import { FancyTextBoxSendButton } from 'components/FancyTextBox/FancyTextBoxHelpers'
import { FilterButton } from 'components/FilterContactsModal/FilterContactsModal'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { BotIconFilled } from 'components/Icons/BotIcon/BotIcon'
import { Pager } from 'components/LinkPager/LinkPager'
import { CenteredLoader } from 'components/Loader/Loader'
import { ConfirmationModal, MainstayModal } from 'components/Modal/Modal'
import { PanelDrawer } from 'components/Panel/Panel'
import { PanelDrawerHeader } from 'components/PanelDrawerHeader/PanelDrawerHeader'
import { SearchInput } from 'components/SearchInput/SearchInput'
import Select from 'components/Select/SelectV2'
import { TextArea } from 'components/TextArea/TextArea'
import { TextInput } from 'components/TextInput/TextInput'
import ThinkingEllipses from 'components/ThinkingEllipses/ThinkingEllipses'
import { FancyTextArea } from 'embed/components/WebChatFancyTextArea'
import { Field, FieldProps, Formik, FormikActions } from 'formik'
import { isRight } from 'fp-ts/lib/These'
import { capitalize, isUndefined } from 'lodash'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { MessageOrigin } from 'page/conversations-v2/ConversationsDetail'
import { Message } from 'page/conversations-v2/ConversationsDetail/Message/Message'
import 'page/knowledge-base/KnowledgeSource/KnowledgeBaseScraping.scss'
import pluralize from 'pluralize'
import { setMostRecentAIResponse } from 'store/ask-ai/actions'
import {
  useDebounce,
  useDispatch,
  useFeatures,
  useInterval,
  useSelector,
} from 'util/hooks'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'

import {
  GenAIScapeKnowledgeSourceType,
  GenAIScrapeKnowledgeSourceCreateReqShapeType,
  GenAIScrapeKnowledgeSourceShapeType,
  generativeTextService,
} from 'api'
import { getFirstQueryParam } from 'components/ContactList/SortableContactList'
import { PageHeader } from 'components/PageHeader/PageHeader'
import NavBarContainer from 'page/NavBarPage'
import KnowledgeSideBar from 'page/knowledge-base/KnowledgeSideBar/KnowledgeSideBarConnected'
import {
  KnowledgeSourcesFilterChips,
  KnowledgeSourcesFilterModal,
  useKnowledgeSourceFilters,
} from 'page/knowledge-base/KnowledgeSource/KnowledgeSourceFilterModal'
import { KnowledgeSourcesCard } from 'page/knowledge-base/KnowledgeSource/KnowledgeSourcesCard'
import {
  appendQueryFilter,
  getQueryFilters,
  removeQueryFilter,
} from 'util/queryFilters'
import * as nope from 'yup'
import { UploadFileDropZone } from 'components/UploadFileModal/UploadFileModal'
import { Failure, Loading, WebData } from 'store/webdata'
import { openDownloadURL } from 'util/links'
import { Link } from 'react-router-dom'

const FETCH_KNOWLEDGE_SOURCES_DELAY_MS = 5000
const KNOWLEDGE_SOURCES_PAGE_SIZE = 20

type CreateKnowledgeSourceForm = {
  sourceType: SourceTypeValue
  originUrl?: string
  file?: File
  fileName?: string
}

type ReuploadDocumentKnowledgeSourceForm = {
  file?: File
  fileName?: string
}

type SourceTypeValue = keyof typeof GenAIScapeKnowledgeSourceType

type SourceUsages = Array<{
  knowledgeSourceId: number
  knowledgeSourceTitle: string | null
  dialogs: Array<{
    dialogId: string
    dialogName: string
  }>
}>

const ASK_THE_AI_DESCRIPTION = `You can ask your AI questions that it will answer based on the
content you’ve added. Any questions asked here will bypass your
Knowledge Base, and be answered only by any Knowledge Sources
available.`

const AskAITextBox = ({
  query,
  loading,
  onChange,
  onSubmit,
}: {
  query: string
  loading: boolean
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onSubmit: () => void
}) => {
  const handleEnterKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onSubmit()
      }
    },
    [onSubmit]
  )

  return (
    <div className="position-relative">
      <FancyTextArea
        resizable={false}
        rows={4}
        onChange={onChange}
        onPressEnter={handleEnterKeyPress}
        value={query}
        placeholder="Ask the AI..."
        className="bg-transparent mb-2 text-area"
      />
      <FancyTextBoxSendButton
        disabled={!query.trim().length || loading}
        onClick={onSubmit}
      />
    </div>
  )
}

const QueryView = () => {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()
  const answer = useSelector(state => state.askAI.response)

  const search = useCallback(async () => {
    const sentQuery = query
    dispatch(
      setMostRecentAIResponse({
        kind: 'loading',
        query: sentQuery,
      })
    )
    setQuery('')

    const res = await generativeTextService.scrape.search({
      query,
    })
    if (isLeft(res)) {
      dispatch(
        setMostRecentAIResponse({
          kind: 'error',
          query: sentQuery,
        })
      )
      return
    }
    dispatch(
      setMostRecentAIResponse({
        kind: 'answer',
        query: sentQuery,
        answer: res.right.answer,
        transactionId: res.right.transaction_id,
      })
    )
  }, [dispatch, query])

  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <section>
      <div className="section-divider mb-3" />
      <div className="d-flex flex-row">
        <div>
          <h3 className="text-mainstay-dark-blue">Ask The AI</h3>
          <p className="mb-3">{ASK_THE_AI_DESCRIPTION}</p>
        </div>
      </div>
      <Button color="primary" outlined onClick={() => setShowDrawer(true)}>
        {' '}
        Ask The AI{' '}
      </Button>
      <PanelDrawer
        header={
          <PanelDrawerHeader
            title="Ask the AI"
            icon={<BotIconFilled className="mr-2 ml-4" />}
            onClose={() => setShowDrawer(false)}
          />
        }
        panelContainerClassName="h-100 px-4"
        onClose={() => setShowDrawer(false)}
        open={showDrawer}>
        <div className="pb-5 h-100">
          <p className="text-mainstay-dark-blue">{ASK_THE_AI_DESCRIPTION}</p>
          <div className="kb-scraping-conversations">
            {answer.kind === 'initial' ? null : answer.kind === 'loading' ? (
              <QueryConversation
                query={answer.query}
                answer={<ThinkingEllipses />}
              />
            ) : answer.kind === 'error' ? (
              <QueryConversation
                query={answer.query}
                answer="Sorry, something went wrong and I can't answer your question right now. Please try again later."
              />
            ) : (
              <QueryConversation
                query={answer.query}
                answer={answer.answer}
                transactionId={answer.transactionId}
              />
            )}
          </div>
          <div className="kb-scraping-text-box">
            <AskAITextBox
              query={query}
              loading={answer.kind === 'loading'}
              onSubmit={search}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </div>
      </PanelDrawer>
    </section>
  )
}

const ActionConfirmationModal = ({
  action,
  count,
  onClose,
  onConfirm,
}: {
  action: string
  count: number
  onClose: () => void
  onConfirm: () => void
}) => {
  return (
    <ConfirmationModal
      title={`Are you sure you want to ${action} these sources?`}
      helpText={
        <div>
          <span>
            {' '}
            <strong>
              {pluralize('sources', count, true)} will be {action}d
            </strong>
          </span>{' '}
        </div>
      }
      onClose={onClose}
      confirmButtonText={capitalize(action)}
      onConfirm={onConfirm}
      confirmButtonColor={action === 'delete' ? 'new-ui-danger' : 'primary'}
      show
      hideCheckbox
    />
  )
}

const QueryConversation = ({
  query,
  answer,
  transactionId,
}: {
  readonly query: string | JSX.Element
  readonly answer: string | JSX.Element
  readonly transactionId?: number
}) => {
  const messageOrigin: MessageOrigin | null = !!transactionId
    ? { generativeTransactionId: transactionId, kind: 'ai_generated' }
    : null
  return (
    <div className="d-flex flex-column mt-3 mb-4">
      <div className="align-self-start">
        <Message
          id=""
          direction="incoming"
          text={query}
          origin={null}
          sourceText={null}
        />
      </div>
      <div className="align-self-end mt-4">
        <div>
          <Message
            id=""
            direction="outgoing"
            text={answer}
            origin={messageOrigin}
            sourceText={null}
          />
        </div>
      </div>
    </div>
  )
}

const KnowledgeSourcesView = () => {
  const location = useLocation()
  const history = useHistory()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>(
    getFirstQueryParam(getQueryFilters(window.location)['q'])
  )
  const [currentPage, setCurrentPage] = useState(1)
  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  const [initialLoad, setInitialLoad] = useState(true)
  const [knowledgeSources, setKnowledgeSources] = useState<
    GenAIScrapeKnowledgeSourceShapeType[] | 'loading' | 'error'
  >('loading')
  const [knowledgeSourcesCount, setKnowledgeSourcesCount] = useState(0)
  const cancelTokenRef = useRef<CancelTokenSource | null>(null)
  const [confirmModalAction, setConfirmModalAction] = useState<
    'rescrape' | 'delete' | undefined
  >()
  const [uploadResult, setUploadResult] = React.useState<
    WebData<GenAIScrapeKnowledgeSourceShapeType>
  >(undefined)

  const filterModalOptions = useKnowledgeSourceFilters()
  const {
    statuses,
    failureReason,
    removeFilterFromParams,
    toggleFilterModal,
    knowledgeSourceIds,
  } = filterModalOptions

  const fetchKnowledgeSourcesWithFilters = useCallback(
    async (showLoadingState?: boolean) => {
      if (showLoadingState) {
        setKnowledgeSources('loading')
      }
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel()
      }
      cancelTokenRef.current = axios.CancelToken.source()
      const res = await generativeTextService.scrape.knowledgeSources.list({
        search: debouncedSearchQuery,
        offset: (currentPage - 1) * KNOWLEDGE_SOURCES_PAGE_SIZE,
        cancelToken: cancelTokenRef.current.token,
        statuses,
        failureReason,
        knowledgeSourceIds,
      })
      if (isRight(res)) {
        setKnowledgeSources(res.right.results)
        setKnowledgeSourcesCount(res.right.count)
        return
      }
      if (
        res.left.kind === 'cancel' ||
        (res.left.kind === 'http' && res.left.http.code === 'ERR_CANCELED')
      ) {
        return
      }
      setKnowledgeSources('error')
    },
    [
      debouncedSearchQuery,
      statuses,
      currentPage,
      failureReason,
      knowledgeSourceIds,
    ]
  )

  useEffect(() => {
    fetchKnowledgeSourcesWithFilters()
    setInitialLoad(false)
  }, [fetchKnowledgeSourcesWithFilters])

  useInterval(() => {
    if (!initialLoad && document.hasFocus()) {
      fetchKnowledgeSourcesWithFilters(false)
    }
  }, FETCH_KNOWLEDGE_SOURCES_DELAY_MS)

  useEffect(() => {
    if (debouncedSearchQuery === '') {
      history.replace(removeQueryFilter(window.location, 'q'))
    } else if (!isUndefined(debouncedSearchQuery)) {
      history.replace(
        appendQueryFilter(window.location, 'q', debouncedSearchQuery)
      )
      history.replace(appendQueryFilter(window.location, 'page', '1'))
    }
  }, [history, debouncedSearchQuery])

  const numberOfPages = Math.ceil(
    knowledgeSourcesCount / KNOWLEDGE_SOURCES_PAGE_SIZE
  )
  useEffect(() => {
    const pageNumberFromParam = getQueryFilters(location)['page']
    if (typeof pageNumberFromParam === 'string') {
      const intPageNumberFromParam = parseInt(pageNumberFromParam, 10) || 1
      if (intPageNumberFromParam <= numberOfPages) {
        setCurrentPage(intPageNumberFromParam)
      }
    }
    const searchStringFromParam = getQueryFilters(location)['q']
    if (typeof searchStringFromParam === 'string') {
      setSearchQuery(searchStringFromParam)
    }
  }, [location, numberOfPages])

  const editKnowledgeSource = useCallback(
    async (knowledgeSource: GenAIScrapeKnowledgeSourceShapeType) => {
      const res = await generativeTextService.scrape.knowledgeSources.update({
        id: knowledgeSource.id,
        title: knowledgeSource.title,
        description: knowledgeSource.description,
      })
      if (isLeft(res)) {
        toast('Failed to update knowledge source', {
          type: 'error',
        })
        return
      }
      await fetchKnowledgeSourcesWithFilters()
    },
    [fetchKnowledgeSourcesWithFilters]
  )

  const [sourceUsages, setSourceUsages] = useState<SourceUsages>([])
  const archiveKnowledgeSources = useCallback(
    async (knowledgeSourceIDs?: Array<number>) => {
      const res = await generativeTextService.scrape.knowledgeSources.bulkDelete(
        {
          search: debouncedSearchQuery,
          statuses,
          failureReason,
          knowledgeSourceIDs,
        }
      )
      if (isRight(res)) {
        if (res.right?.skippedDeletions) {
          setSourceUsages(res.right.skippedDeletions)
        }
      }
      if (isLeft(res)) {
        toast('Failed to delete knowledge sources', {
          type: 'error',
        })
        return
      }
      await fetchKnowledgeSourcesWithFilters()
    },
    [
      fetchKnowledgeSourcesWithFilters,
      debouncedSearchQuery,
      statuses,
      failureReason,
    ]
  )

  const triggerScrapeWithFilters = useCallback(
    async (knowledgeSourceIDsFromCard?: Array<number>) => {
      const sourceIds =
        knowledgeSourceIDsFromCard || knowledgeSourceIds.map(Number)
      const res = await generativeTextService.scrape.knowledgeSources.rescrapeSources(
        {
          search: debouncedSearchQuery,
          statuses,
          failureReason,
          knowledgeSourceIDs: sourceIds,
        }
      )

      if (isLeft(res)) {
        const message = http2DetailOrDefault(
          res.left,
          'Failed to trigger scrape'
        )
        toast(message, { type: 'error' })
        return
      }
      await fetchKnowledgeSourcesWithFilters()
    },
    [
      fetchKnowledgeSourcesWithFilters,
      debouncedSearchQuery,
      statuses,
      failureReason,
      knowledgeSourceIds,
    ]
  )

  const bulkCreateKnowledgeSource = useCallback(
    async (
      knowledgeSources: GenAIScrapeKnowledgeSourceCreateReqShapeType[]
    ) => {
      const res = await generativeTextService.scrape.knowledgeSources.bulkCreate(
        { knowledgeSources }
      )
      if (isLeft(res)) {
        toast('Failed to create knowledge sources', {
          type: 'error',
        })
        return
      }
      await fetchKnowledgeSourcesWithFilters()
    },
    [fetchKnowledgeSourcesWithFilters]
  )

  const crawlURL = useCallback(
    async (sourceURL: string) => {
      const res = await generativeTextService.scrape.knowledgeSources.crawl({
        sourceURL,
      })
      if (isLeft(res)) {
        toast('Failed to crawl website', {
          type: 'error',
        })
        return
      }
      await fetchKnowledgeSourcesWithFilters()
    },
    [fetchKnowledgeSourcesWithFilters]
  )

  const scrapeDoc = useCallback(
    async (sourceURL: string) => {
      const res = await generativeTextService.scrape.knowledgeSources.scrapeDoc(
        { sourceURL }
      )
      if (isLeft(res)) {
        const fallback = 'Failed to scrape doc'
        const message =
          'http' in res.left
            ? keyOrDefault(res.left.http, 'error', fallback)
            : fallback
        toast(message, {
          type: 'error',
        })
        return
      }
      await fetchKnowledgeSourcesWithFilters()
    },
    [fetchKnowledgeSourcesWithFilters]
  )

  const ingestFile = useCallback(
    async (file: File, fileName: string) => {
      setUploadResult(Loading())
      const res = await generativeTextService.scrape.knowledgeSources.ingestFile(
        { file, fileName }
      )
      if (isLeft(res)) {
        toast('Failed to upload document', {
          type: 'error',
        })
        setUploadResult(Failure(undefined))
        return
      }
      setUploadResult(undefined)
      await fetchKnowledgeSourcesWithFilters()
    },
    [fetchKnowledgeSourcesWithFilters]
  )

  const downloadFile = useCallback(
    async ({
      knowledgeSourceId,
      fileName,
    }: {
      knowledgeSourceId: number
      fileName: string
    }) => {
      const res = await generativeTextService.scrape.knowledgeSources.downloadFile(
        { knowledgeSourceId }
      )
      if (isLeft(res)) {
        toast('Failed to download document', {
          type: 'error',
        })
        return
      }
      openDownloadURL({
        url: res.right.url,
        fileName,
      })
    },
    []
  )

  const reuploadFile = useCallback(
    async (file: File, fileName: string, knowledgeSourceId: number) => {
      setUploadResult(Loading())
      const res = await generativeTextService.scrape.knowledgeSources.reuploadFile(
        { file, fileName, knowledgeSourceId }
      )
      if (isLeft(res)) {
        toast('Failed to re-upload document', {
          type: 'error',
        })
        setUploadResult(Failure(undefined))
        return
      }
      setUploadResult(undefined)
      await fetchKnowledgeSourcesWithFilters()
    },
    [fetchKnowledgeSourcesWithFilters]
  )

  if (knowledgeSources === 'loading') {
    return (
      <div className="d-flex flex-row align-center height-250px">
        <CenteredLoader className="w-100 " />
      </div>
    )
  }
  if (knowledgeSources === 'error') {
    return (
      <div className="d-flex flex-row align-center height-250px">
        Failed to load the knowledge sources
      </div>
    )
  }

  const hasFilters = statuses.length > 0 || debouncedSearchQuery.length > 0

  return (
    <section>
      <CreateKnowledgeSourceModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        bulkCreateKnowledgeSource={bulkCreateKnowledgeSource}
        crawlURL={crawlURL}
        scrapeDoc={scrapeDoc}
        ingestFile={ingestFile}
        uploadResult={uploadResult}
      />
      <KnowledgeSourcesFilterModal
        onSubmit={async () => {
          await fetchKnowledgeSourcesWithFilters()
          toggleFilterModal()
        }}
        {...filterModalOptions}
      />
      {confirmModalAction && (
        <ActionConfirmationModal
          action={confirmModalAction}
          count={knowledgeSourcesCount}
          onClose={() => setConfirmModalAction(undefined)}
          onConfirm={() => {
            if (confirmModalAction === 'delete') {
              archiveKnowledgeSources()
            }
            if (confirmModalAction === 'rescrape') {
              triggerScrapeWithFilters()
            }
            setConfirmModalAction(undefined)
          }}
        />
      )}
      <div className="d-flex flex-row flex-wrap justify-content-between">
        <div className="d-flex flex-row mb-3">
          <PermissionGuard permission={PERMISSIONS.KNOWLEDGE_SOURCE.CREATE}>
            <Button
              onClick={() => setCreateModalOpen(true)}
              className="mr-3"
              color="secondary-teal">
              <div className="d-flex flex-row align-items-center">
                <AHIcon name="add_circle_outline" />
                <span>New Source</span>
              </div>
            </Button>
          </PermissionGuard>
          {knowledgeSourcesCount > 0 && (
            <PermissionGuard permission={PERMISSIONS.KNOWLEDGE_SOURCE.CREATE}>
              <Button
                onClick={() => setConfirmModalAction('rescrape')}
                color="secondary-teal"
                outlined>
                <div className="d-flex flex-row align-items-center">
                  <AHIcon name="cloud_download" className="d-flex" />
                  <span>Rescrape {hasFilters ? ' Sources' : ' All'}</span>
                </div>
              </Button>
            </PermissionGuard>
          )}
          {knowledgeSourcesCount > 0 && hasFilters && (
            <PermissionGuard permission={PERMISSIONS.KNOWLEDGE_SOURCE.DELETE}>
              <Button
                onClick={() => setConfirmModalAction('delete')}
                color="new-ui-danger"
                className="ml-3"
                outlined>
                <div className="d-flex flex-row align-items-center">
                  <AHIcon name="delete" className="d-flex" />
                  <span>Delete Sources</span>
                </div>
              </Button>
            </PermissionGuard>
          )}
        </div>
        <div className="d-flex align-items-center mb-3">
          <FilterButton className="mr-3" onClick={() => toggleFilterModal()} />
          <SearchInput
            className="w-360"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            onClear={() => setSearchQuery('')}
            placeholder="Search"
            name="search"
          />
        </div>
      </div>
      <KnowledgeSourcesFilterChips
        statuses={statuses}
        failureReason={failureReason}
        removeFilterFromParams={removeFilterFromParams}
        knowledgeSourceIds={knowledgeSourceIds}
      />
      <div>
        <div className="d-flex flex-wrap mt-2">
          {knowledgeSources.length > 0 ? (
            <>
              {knowledgeSources.map(knowledgeSource => (
                <KnowledgeSourcesCard
                  key={`knowledge-base-scraping-knowledge-source-${knowledgeSource.id}`}
                  knowledgeSource={knowledgeSource}
                  editKnowledgeSource={editKnowledgeSource}
                  archiveKnowledgeSource={() =>
                    archiveKnowledgeSources([knowledgeSource.id])
                  }
                  triggerScrape={() =>
                    triggerScrapeWithFilters([knowledgeSource.id])
                  }
                  downloadFile={() =>
                    downloadFile({
                      knowledgeSourceId: knowledgeSource.id,
                      fileName: knowledgeSource.original_filename || '',
                    })
                  }
                  reuploadFile={reuploadFile}
                  uploadResult={uploadResult}
                />
              ))}
              <div className="mt-2 w-100 text-align-end">
                {pluralize('sources', knowledgeSourcesCount, true)}
              </div>
              {knowledgeSourcesCount && (
                <div className="py-5 w-100">
                  <Pager
                    urlFormat={x =>
                      appendQueryFilter(location, 'page', x, false)
                    }
                    current={currentPage}
                    onClick={e =>
                      setCurrentPage(
                        parseInt(e.currentTarget.dataset.page || '1', 10) || 1
                      )
                    }
                    last={numberOfPages}
                  />
                </div>
              )}
            </>
          ) : (
            <p className="py-4">No knowledge sources</p>
          )}
        </div>
      </div>

      <MainstayModal
        text={`Cannot delete knowledge ${
          sourceUsages.length > 1 ? 'sources' : 'source'
        }`}
        show={!!sourceUsages.length}
        hideSubmit={true}
        onClose={() => setSourceUsages([])}
        onCancel={() => setSourceUsages([])}>
        <div>
          {sourceUsages.length > 1 ? (
            <p>
              These Knowledge Sources cannot be deleted because they are used in
              Scripts.
            </p>
          ) : (
            <p>
              This Knowledge Source cannot be deleted because it is used in
              Scripts.
            </p>
          )}
          {sourceUsages.map(usage => {
            return (
              <div className="mt-3" key={usage.knowledgeSourceId}>
                "{usage.knowledgeSourceTitle}" is used in:
                {usage.dialogs.map(dialog => {
                  return (
                    <li key={dialog.dialogId} className="ml-3">
                      <Link to={`/campaign-scripts/${dialog.dialogId}/view`}>
                        {dialog.dialogName}
                      </Link>
                    </li>
                  )
                })}
              </div>
            )
          })}
        </div>
      </MainstayModal>
    </section>
  )
}

type SourceTypeSelectOptionType = {
  label: string
  value: SourceTypeValue
}

const uploadFileInitialShape = {
  file: undefined,
  fileName: '',
}

const newSourceScrapeInitialValues = {
  originUrl: '',
  sourceType: GenAIScapeKnowledgeSourceType.URL,
  ...uploadFileInitialShape,
}

const VALID_URL_MESSAGE =
  'Must enter valid URLs, including a protocol (ex: https://) and domain (ex: example.com).'

const newSourceScrapeInitialValuesValidationSchema = nope.object().shape({
  sourceType: nope.string().required('Source type is required.'),
  originUrl: nope
    .string()
    .trim()
    .when('sourceType', (sourceType: string, schema: nope.StringSchema) => {
      if (sourceType === GenAIScapeKnowledgeSourceType.S3_DOC) {
        return schema
      }
      if (sourceType === GenAIScapeKnowledgeSourceType.URL) {
        return schema
          .required('One or more origin URLs are required.')
          .test(
            'all-valid-urls',
            `${VALID_URL_MESSAGE} Each URL must be in its own line.`,
            (originUrlValues?: string) => {
              return (
                !!originUrlValues &&
                originUrlValues
                  .split(/\r?\n/)
                  .filter(url => !!url.trim())
                  .every(url => VALID_URL_REGEX.test(url))
              )
            }
          )
      }
      return schema.test('is-valid-url', VALID_URL_MESSAGE, (url: string) =>
        VALID_URL_REGEX.test(url)
      )
    }),
})

type SourceTypeCopy = {
  [key in GenAIScapeKnowledgeSourceType]: string
}

const sourceTypePlaceholderCopy: SourceTypeCopy = {
  [GenAIScapeKnowledgeSourceType.URL]:
    'Enter specific URLs on separate lines:\nhttps://example_one.edu\nhttps://example_two.edu/some/path',
  [GenAIScapeKnowledgeSourceType.WEBSITE]:
    'Enter an entire domain or section to crawl',
  [GenAIScapeKnowledgeSourceType.PDF]: 'Enter the URL of a hosted PDF',
  [GenAIScapeKnowledgeSourceType.DOC]: 'Enter the URL of a shared Google Doc',
  [GenAIScapeKnowledgeSourceType.S3_DOC]: 'Upload a file',
}

function getFormHeader(sourceType: SourceTypeValue) {
  if (sourceType === GenAIScapeKnowledgeSourceType.URL) {
    return 'URL(s)'
  }
  if (sourceType === GenAIScapeKnowledgeSourceType.S3_DOC) {
    // Return an empty string for files - the dropzone is self-explanatory
    return ''
  }

  return 'URL'
}

const CreateKnowledgeSourceModal = ({
  open,
  setOpen,
  bulkCreateKnowledgeSource,
  crawlURL,
  scrapeDoc,
  ingestFile,
  uploadResult,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly bulkCreateKnowledgeSource: (
    knowledgeSources: GenAIScrapeKnowledgeSourceCreateReqShapeType[]
  ) => Promise<void>
  readonly crawlURL: (sourceURL: string) => Promise<void>
  readonly scrapeDoc: (sourceURL: string) => Promise<void>
  readonly ingestFile: (file: File, fileName: string) => Promise<void>
  readonly uploadResult: WebData<GenAIScrapeKnowledgeSourceShapeType>
}) => {
  const [loading, setLoading] = useState(false)
  const { FeaturesType, hasFeature } = useFeatures()
  const onSubmit = async (
    { originUrl, sourceType, file, fileName }: CreateKnowledgeSourceForm,
    { resetForm }: FormikActions<CreateKnowledgeSourceForm>
  ) => {
    setLoading(true)
    try {
      if (sourceType === GenAIScapeKnowledgeSourceType.URL && originUrl) {
        const knowledgeSources = originUrl
          .split(/\r?\n/)
          .filter(url => !!url.trim())
          .map(url => {
            return {
              source_type: sourceType,
              origin_url: url,
            }
          })
        await bulkCreateKnowledgeSource(knowledgeSources)
      }
      if (sourceType === GenAIScapeKnowledgeSourceType.WEBSITE && originUrl) {
        await crawlURL(originUrl)
      }
      if (
        (sourceType === GenAIScapeKnowledgeSourceType.DOC ||
          sourceType === GenAIScapeKnowledgeSourceType.PDF) &&
        originUrl
      ) {
        await scrapeDoc(originUrl)
      }
      if (
        sourceType === GenAIScapeKnowledgeSourceType.S3_DOC &&
        file &&
        fileName
      ) {
        await ingestFile(file, fileName)
      }
    } finally {
      setLoading(false)
    }
    setOpen(false)
    resetForm()
  }

  const sourceTypeSelectOptions: SourceTypeSelectOptionType[] = [
    {
      label: 'Specific Webpages',
      value: GenAIScapeKnowledgeSourceType.URL,
    },
    {
      label: 'Domain/Site Section',
      value: GenAIScapeKnowledgeSourceType.WEBSITE,
    },
    {
      label: 'Online PDF',
      value: GenAIScapeKnowledgeSourceType.PDF,
    },
    {
      label: 'Google Document',
      value: GenAIScapeKnowledgeSourceType.DOC,
    },
  ]

  if (hasFeature(FeaturesType.GENERATIVE_AI_INGEST_BEDROCK_CONTENT)) {
    sourceTypeSelectOptions.push({
      label: 'File Upload',
      value: GenAIScapeKnowledgeSourceType.S3_DOC,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={newSourceScrapeInitialValues}
      validationSchema={newSourceScrapeInitialValuesValidationSchema}
      onSubmit={onSubmit}>
      {formikProps => (
        <MainstayModal
          wrapContentInForm
          show={open}
          text="Create Knowledge Source"
          onClose={() => setOpen(!open)}
          submitText="Create"
          disableSubmit={
            loading || // If the form is loading
            !!formikProps.errors.sourceType || // if sourceType has an error
            (!formikProps.values.originUrl && !formikProps.values.file) || // if either originalUrl or file isn't populated
            !!formikProps.errors.originUrl || // if there's an error on originUrl
            !!formikProps.errors.file // if there's an error on file
          }
          loading={loading}>
          <p className="mb-2">
            Build your knowledge base by referencing information online.
          </p>
          <ul className="mb-3 pl-3">
            <li className="text-muted fs-small">
              Specific Webpages: Scrape the text contents of the individual
              pages provided.
            </li>
            <li className="text-muted fs-small">
              Domain/Site Section: Scrape this page and all sub-pages.
            </li>
            <li className="text-muted fs-small">
              Online PDF: Scrape the text contents of a PDF file hosted online.
            </li>
            <li className="text-muted fs-small">
              Google Document: Scrape the text contents of a publicly accessible
              Google Doc.
            </li>
            <li className="text-muted fs-small">
              File Upload: Parse a PDF, TXT, DOCX, CSV, MD, or HTML document.
            </li>
          </ul>
          <div>
            Source Type
            <span className="ml-1 color-mainstay-spark-red">*</span>
          </div>
          <Field
            name="sourceType"
            render={({ form }: FieldProps<CreateKnowledgeSourceForm>) => (
              <div>
                <Select<IOption>
                  defaultValue={{
                    value: form.values.sourceType,
                    label:
                      sourceTypeSelectOptions.find(
                        v => v.value === form.values.sourceType
                      )?.label ?? form.values.sourceType,
                  }}
                  options={sourceTypeSelectOptions}
                  onChange={selectedEntry => {
                    if (Array.isArray(selectedEntry)) {
                      return
                    }
                    if (
                      form.values.sourceType ===
                        GenAIScapeKnowledgeSourceType.S3_DOC &&
                      selectedEntry?.value !==
                        GenAIScapeKnowledgeSourceType.S3_DOC
                    ) {
                      form.setFieldValue('file', undefined)
                    }

                    if (selectedEntry?.value) {
                      form.setFieldValue('sourceType', selectedEntry.value)
                    }
                  }}
                  error={!!form.errors.sourceType && form.touched.sourceType}
                />
                {form.errors.sourceType && form.touched.sourceType && (
                  <div className="text-danger small">
                    {form.errors.sourceType}
                  </div>
                )}
              </div>
            )}
          />
          <Field
            name="originUrl"
            render={({
              form,
              field,
            }: FieldProps<CreateKnowledgeSourceForm>) => {
              return (
                <div>
                  <div className="mt-3">
                    <span>{getFormHeader(form.values.sourceType)}</span>
                    {form.values.sourceType !==
                      GenAIScapeKnowledgeSourceType.S3_DOC && (
                      <span className="ml-1 color-mainstay-spark-red">*</span>
                    )}
                  </div>
                  {form.values.sourceType ===
                  GenAIScapeKnowledgeSourceType.S3_DOC ? (
                    <Field
                      name="file"
                      render={({
                        form,
                      }: FieldProps<CreateKnowledgeSourceForm>) => (
                        <UploadFileDropZone
                          fileName={form.values.fileName || ''}
                          setFile={file => {
                            form.setFieldValue('file', file)
                          }}
                          setFileName={fileName => {
                            form.setFieldValue('fileName', fileName)
                          }}
                          readyContentTitle="Upload PDF, TXT, DOCX, CSV, MD, or HTML file"
                          replaceContentTitle="Replace PDF, TXT, DOCX, CSV, MD, or HTML file"
                          uploadResult={uploadResult}
                          acceptedFileTypes=".txt, .doc, .docx, .pdf, .csv, .html, .xls, .xlsx, .md"
                          stretchContainer
                        />
                      )}
                    />
                  ) : form.values.sourceType ===
                    GenAIScapeKnowledgeSourceType.URL ? (
                    <TextArea
                      {...field}
                      id="originUrl"
                      placeholder={
                        sourceTypePlaceholderCopy[form.values.sourceType]
                      }
                      onChange={event => {
                        field.onChange(event)
                      }}
                      isValid={form.touched.originUrl && !form.errors.originUrl}
                    />
                  ) : (
                    <TextInput
                      {...field}
                      id="originUrl"
                      placeholder={
                        sourceTypePlaceholderCopy[form.values.sourceType]
                      }
                      onChange={event => {
                        field.onChange(event)
                      }}
                      error={!!form.errors.originUrl && form.touched.originUrl}
                    />
                  )}
                  {form.errors.originUrl && form.touched.originUrl && (
                    <div className="text-danger small">
                      {form.errors.originUrl}
                    </div>
                  )}
                </div>
              )
            }}
          />
          <section className="mt-4 d-flex justify-content-center">
            <AHIcon name="lock" className="text-muted" />{' '}
            <p>All content securely encrypted and privately stored.</p>
          </section>
        </MainstayModal>
      )}
    </Formik>
  )
}

export const ReuploadKnowledgeSourceDocumentModal = ({
  open,
  setOpen,
  uploadResult,
  reuploadFile,
  knowledgeSourceId,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly uploadResult: WebData<GenAIScrapeKnowledgeSourceShapeType>
  readonly reuploadFile: (
    file: File,
    fileName: string,
    knowledgeSourceId: number
  ) => Promise<void>
  readonly knowledgeSourceId: number
}) => {
  const [loading, setLoading] = useState(false)
  const onSubmit = async (
    { file, fileName }: ReuploadDocumentKnowledgeSourceForm,
    { resetForm }: FormikActions<ReuploadDocumentKnowledgeSourceForm>
  ) => {
    setLoading(true)
    try {
      if (file && fileName && knowledgeSourceId) {
        await reuploadFile(file, fileName, knowledgeSourceId)
      }
    } finally {
      setLoading(false)
    }
    setOpen(false)
    resetForm()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={uploadFileInitialShape}
      onSubmit={onSubmit}>
      {formikProps => (
        <MainstayModal
          wrapContentInForm
          show={open}
          text="Reupload Doucment for Knowledge Source"
          onClose={() => setOpen(!open)}
          submitText="Reupload"
          disableSubmit={
            loading || // If the form is loading
            !formikProps.values.file || // if file isn't populated
            !!formikProps.errors.file // if there's an error on file
          }
          loading={loading}>
          <Field
            name="file"
            render={({ form }: FieldProps<CreateKnowledgeSourceForm>) => (
              <UploadFileDropZone
                fileName={form.values.fileName || ''}
                setFile={file => {
                  form.setFieldValue('file', file)
                }}
                setFileName={fileName => {
                  form.setFieldValue('fileName', fileName)
                }}
                readyContentTitle="Upload PDF, TXT, DOCX, CSV, MD, or HTML file"
                replaceContentTitle="Replace PDF, TXT, DOCX, CSV, MD, or HTML file"
                uploadResult={uploadResult}
                acceptedFileTypes=".txt, .doc, .docx, .pdf, .csv, .html, .xls, .xlsx, .md"
                stretchContainer
              />
            )}
          />
          <section className="mt-4 d-flex justify-content-center">
            <AHIcon name="lock" className="text-muted" />{' '}
            <p>All content securely encrypted and privately stored.</p>
          </section>
        </MainstayModal>
      )}
    </Formik>
  )
}

export const KNOWLEDGE_BASE_SCRAPING_DESCRIPTION =
  'Add sources of information to your bot that can be referenced in various generative AI uses throughout the platform (such as messages drafted during AI-Assisted Live Chat). Sources can come from websites, PDFs, and Google Docs.'

const KnowledgeBaseScraping = () => {
  const { FeaturesType, hasFeature } = useFeatures()
  if (!hasFeature(FeaturesType.GENERATIVE_AI_PARTNER_FACING_SCRAPER)) {
    return null
  }
  return (
    <PermissionGuard
      permission={PERMISSIONS.KNOWLEDGE_SOURCE.VIEW}
      renderNothing>
      <NavBarContainer title="Knowledge" className="d-flex h-100">
        <KnowledgeSideBar
          pageContent={
            <>
              <PageHeader
                title="Knowledge Scraping"
                description={KNOWLEDGE_BASE_SCRAPING_DESCRIPTION}
              />
              <QueryView />
              <div className="section-divider mt-4" />
              <h3 className="mt-3 text-mainstay-dark-blue">
                Knowledge Sources
              </h3>
              <p className="mb-4">
                Build your knowledge base by referencing information from your
                websites or documents.
              </p>
              <KnowledgeSourcesView />
            </>
          }
        />
      </NavBarContainer>
    </PermissionGuard>
  )
}

export default KnowledgeBaseScraping
