import { IKnowledgeSeederActions } from 'store/knowledgeSeeder/actions'
import * as t from 'store/knowledgeSeeder/actionTypes'
import uniq from 'lodash/uniq'
import {
  IInstitutionAttributeResponseData,
  IContactAttributeResponseData,
} from 'api/response'
import { ResponsesFilters } from 'store/knowledgeSeeder/responsesFilterTypes'

export interface IKnowledgeSeed {
  sampleQuestion: null | string
  sampleAnswer: null | string
  sampleDialogId?: string | null
  sampleDialogName?: string | null
  sampleAnswerIsGenerative: boolean
  id: string
  topic: string
  category: string
  subCategory: string
  hasApprovedAnswer: boolean
  fuzzyQuestionCount?: number | null
  readonly formattedTopic?: string
  readonly answersCount?: number
  readonly suggestedAnswersCount?: number
  readonly sampleAnswerType?: 'INSTITUTION' | 'SUGGESTION' | 'NO_ANSWER'
  readonly responsesFilters: ResponsesFilters
}

export enum SeedFilter {
  approved = 'approved',
  unapproved = 'unapproved',
  blank = 'blank',
  all = 'all',
}

interface ISubCategoriesByCategory {
  [category: string]: string[]
}

interface ISubCategoryMapping {
  [key: string]: string[]
  [SeedFilter.all]: string[]
  [SeedFilter.approved]: string[]
  [SeedFilter.unapproved]: string[]
  [SeedFilter.blank]: string[]
}

interface ISeedIdsBySubCategory {
  [subCategory: string]: ISubCategoryMapping
}

export interface IContactAttributeDraftEditor {
  id: number | string | undefined
  type: IContactAttributeResponseData['data_type']
  field: IContactAttributeResponseData['name']
  value?: string
}

export interface IInstitutionAttributeDraftEditor
  extends Pick<IInstitutionAttributeResponseData, 'id'> {
  type: IInstitutionAttributeResponseData['data_type']
  field: IInstitutionAttributeResponseData['name']
  value: string | number | Date
}

export interface IKnowledgeSeederState {
  loading: boolean
  seedsById: { [seedId: string]: IKnowledgeSeed | undefined }
  allIds: string[]
  subCategoriesByCategory: ISubCategoriesByCategory
  seedIdsBySubCategory: ISeedIdsBySubCategory
  // TODO(sbdchd): this should be renamed to `updating`. It doesn't represent
  // the initial load state.
  loadingStatusBySeedId: { [seedId: string]: boolean }
}

const INITIAL_STATE: IKnowledgeSeederState = {
  loading: true,
  seedsById: {},
  allIds: [],
  subCategoriesByCategory: {},
  seedIdsBySubCategory: {},
  loadingStatusBySeedId: {},
}

const makeBlankSubCategoryMapping = (): ISubCategoryMapping => {
  return {
    all: [],
    [SeedFilter.approved]: [],
    [SeedFilter.unapproved]: [],
    [SeedFilter.blank]: [],
  }
}

/** Return boolean indicating whether seed matches supplied filter.
 * approved = seeed is approved
 * unapproved = seed is not approved and seed has sampleAnswer
 * blank = seed has no sampleAnswer
 */
export const getSeedMatchesFilter = (
  filter: undefined | string,
  seed: IKnowledgeSeed
): boolean => {
  return (
    (filter === SeedFilter.approved && seed.hasApprovedAnswer) ||
    (filter === SeedFilter.unapproved &&
      !seed.hasApprovedAnswer &&
      (!!seed.sampleAnswer || seed.sampleAnswerIsGenerative)) ||
    (filter === SeedFilter.blank &&
      !seed.sampleAnswer &&
      !seed.sampleAnswerIsGenerative) ||
    filter === SeedFilter.all
  )
}

export const updateSubCategoryMapping = ({
  mapping,
  newSeed,
  previousSeed,
  removeSeed = false,
}: {
  mapping: ISubCategoryMapping
  newSeed: IKnowledgeSeed
  previousSeed: IKnowledgeSeed
  removeSeed?: boolean
}) => {
  const newMapping: ISubCategoryMapping = makeBlankSubCategoryMapping()
  Object.keys(mapping).forEach(filter => {
    if (removeSeed || !getSeedMatchesFilter(filter, newSeed)) {
      newMapping[filter] = mapping[filter].filter(x => x !== newSeed.id)
    } else if (
      getSeedMatchesFilter(filter, newSeed) &&
      !getSeedMatchesFilter(filter, previousSeed)
    ) {
      newMapping[filter] = [...mapping[filter], newSeed.id]
    } else {
      newMapping[filter] = mapping[filter]
    }
  })
  return newMapping
}

function updateCategory({
  subCategory,
  understandingId,
  hasApprovedAnswer,
  sampleAnswer,
  sampleDialogId,
  sampleAnswerIsGenerative,
}: {
  subCategory: ISubCategoryMapping
  understandingId: string
  hasApprovedAnswer: boolean
  sampleAnswer: string | null
  sampleDialogId: string | null
  sampleAnswerIsGenerative: boolean
}): ISubCategoryMapping {
  function addUnderstanding(arr: string[]): string[] {
    // prevent duplicate understanding ids.
    return uniq([...arr, understandingId])
  }
  function omitUnderstanding(arr: string[]): string[] {
    return arr.filter(x => x !== understandingId)
  }

  const approved = subCategory.approved
  const unapproved = subCategory.unapproved
  const blank = subCategory.blank
  if (hasApprovedAnswer) {
    return {
      all: subCategory.all,
      approved: addUnderstanding(approved),
      unapproved: omitUnderstanding(unapproved),
      blank: omitUnderstanding(blank),
    }
  }
  if (sampleAnswer || sampleDialogId || sampleAnswerIsGenerative) {
    return {
      all: subCategory.all,
      approved: omitUnderstanding(approved),
      unapproved: addUnderstanding(unapproved),
      blank: omitUnderstanding(blank),
    }
  }
  return {
    all: subCategory.all,
    approved: omitUnderstanding(approved),
    unapproved: omitUnderstanding(unapproved),
    blank: addUnderstanding(blank),
  }
}

/**
 * Prefix the subCategories by category since subCategories are not unique
 * across categories.
 *
 * This also means we don't need to make substantial changes
 * to the seedIdsBySubCategory object.
 */
function getSubCategory(seed: {
  readonly category: string
  readonly subCategory: string
}): string {
  return seed.category + seed.subCategory
}

function insertKnowledgeSeed({
  u,
  seedsById,
  allIds,
  subCategoriesByCategory,
  seedIdsBySubCategory,
}: {
  u: IKnowledgeSeed
  seedsById: { [id: string]: IKnowledgeSeed | undefined }
  allIds: string[]
  subCategoriesByCategory: ISubCategoriesByCategory
  seedIdsBySubCategory: ISeedIdsBySubCategory
}) {
  seedsById[u.id] = u
  allIds.push(u.id)
  const subCat = getSubCategory(u)
  // get seed's subCategory's mapping or create new one if doesn't exist
  const subCategoryMapping =
    seedIdsBySubCategory[subCat] || makeBlankSubCategoryMapping()
  // add seed id to appropriate buckets
  subCategoryMapping.all.push(u.id)
  if (getSeedMatchesFilter(SeedFilter.approved, u)) {
    subCategoryMapping[SeedFilter.approved].push(u.id)
  }
  if (getSeedMatchesFilter(SeedFilter.unapproved, u)) {
    subCategoryMapping[SeedFilter.unapproved].push(u.id)
  }
  if (getSeedMatchesFilter(SeedFilter.blank, u)) {
    subCategoryMapping[SeedFilter.blank].push(u.id)
  }
  seedIdsBySubCategory[subCat] = subCategoryMapping
  // if this is first occurance of subcategory, add to array or new one if it doesn't exist
  if (seedIdsBySubCategory[subCat][SeedFilter.all].length === 1) {
    subCategoriesByCategory[u.category] = [
      ...(subCategoriesByCategory[u.category] || []),
      subCat,
    ]
  }
}

const reducer = (
  state: IKnowledgeSeederState = INITIAL_STATE,
  action: IKnowledgeSeederActions
): IKnowledgeSeederState => {
  switch (action.type) {
    case t.FETCH_KNOWLEDGE_SEEDS_START:
      return {
        ...state,
        loading: true,
      }
    case t.ADD_KNOWLEDGE_SEED: {
      const seed = action.payload
      const seedsById = { ...state.seedsById }
      const allIds = [...state.allIds]
      const subCategoriesByCategory = { ...state.subCategoriesByCategory }
      const seedIdsBySubCategory = { ...state.seedIdsBySubCategory }
      insertKnowledgeSeed({
        u: seed,
        seedsById,
        allIds,
        subCategoriesByCategory,
        seedIdsBySubCategory,
      })
      return {
        ...state,
        seedsById,
        allIds,
        subCategoriesByCategory,
        seedIdsBySubCategory,
        loading: false,
      }
    }
    case t.FETCH_KNOWLEDGE_SEEDS_SUCCESS: {
      const { data } = action.payload
      const { knowledge } = data
      const seedsById: { [id: string]: IKnowledgeSeed } = {}
      const allIds: string[] = []
      const subCategoriesByCategory: ISubCategoriesByCategory = {}
      const seedIdsBySubCategory: ISeedIdsBySubCategory = {}
      knowledge.forEach(u => {
        insertKnowledgeSeed({
          u,
          seedsById,
          allIds,
          subCategoriesByCategory,
          seedIdsBySubCategory,
        })
      })

      return {
        ...state,
        seedsById,
        allIds,
        subCategoriesByCategory,
        seedIdsBySubCategory,
        loading: false,
      }
    }
    case t.FETCH_KNOWLEDGE_SEEDS_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case t.REMOVE_KNOWLEDGE_SEED_SUCCESS: {
      const { id } = action.payload
      const seedToRemove = state.seedsById[id]
      if (seedToRemove == null) {
        return state
      }
      const newSeedsById = Object.keys(state.seedsById).reduce(
        (acc: { [id: string]: IKnowledgeSeed | undefined }, key: string) => {
          if (key !== id) {
            acc[key] = state.seedsById[key]
          }
          return acc
        },
        {}
      )
      const subCat = getSubCategory(seedToRemove)
      return {
        ...state,
        seedsById: newSeedsById,
        allIds: state.allIds.filter(x => x !== id),
        seedIdsBySubCategory: {
          ...state.seedIdsBySubCategory,
          [subCat]: updateSubCategoryMapping({
            mapping: state.seedIdsBySubCategory[subCat],
            newSeed: seedToRemove,
            previousSeed: seedToRemove,
            removeSeed: true,
          }),
        },
        loadingStatusBySeedId: {
          ...state.loadingStatusBySeedId,
          [id]: false,
        },
      }
    }
    case t.REMOVE_KNOWLEDGE_SEED_START: {
      return {
        ...state,
        loadingStatusBySeedId: {
          ...state.loadingStatusBySeedId,
          [action.payload.id]: true,
        },
      }
    }
    case t.REMOVE_KNOWLEDGE_SEED_ERROR: {
      return {
        ...state,
        loadingStatusBySeedId: {
          ...state.loadingStatusBySeedId,
          [action.payload.id]: false,
        },
      }
    }
    case t.UPDATE_KNOWLEDGE_SEED_APPROVAL: {
      const {
        understandingId,
        hasApprovedAnswer,
        sampleAnswer,
        sampleDialogId,
        sampleDialogName,
        sampleAnswerIsGenerative,
        responsesFilters,
      } = action.payload
      const understanding = state.seedsById[understandingId]
      if (understanding == null) {
        return state
      }

      const subCat = getSubCategory(understanding)

      return {
        ...state,
        seedsById: {
          ...state.seedsById,
          [understandingId]: {
            ...understanding,
            hasApprovedAnswer,
            sampleAnswer,
            sampleDialogId,
            sampleDialogName,
            sampleAnswerIsGenerative,
            responsesFilters,
          },
        },
        seedIdsBySubCategory: {
          ...state.seedIdsBySubCategory,
          [subCat]: updateCategory({
            subCategory: state.seedIdsBySubCategory[subCat],
            hasApprovedAnswer,
            sampleAnswer,
            sampleDialogId,
            sampleAnswerIsGenerative,
            understandingId,
          }),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
