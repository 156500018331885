import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Checkbox } from 'components/Checkbox/Checkbox'
import Select from 'components/Select/SelectV2'
import { SimpleModalFooter } from 'components/SimpleModalFooter/SimpleModalFooter'
import { SimpleModalHeader } from 'components/SimpleModalHeader/SimpleModalHeader'
import { isRight } from 'fp-ts/lib/These'
import { capitalize, startCase } from 'lodash'
import { Modal, ModalBody } from 'reactstrap'

import { GenAIScrapeAttemptStatus, generativeTextService } from 'api'
import cls from 'classnames'
import { Chip } from 'components/Chip/Chip'
import { appendQueryFilter, removeQueryFilter } from 'util/queryFilters'
import {
  parseFirstQueryParam,
  parseQueryString,
  queryParamToArray,
} from 'util/string'
const SCRAPE_ATTEMPT_STATUSES: Array<GenAIScrapeAttemptStatus> = [
  GenAIScrapeAttemptStatus.QUEUED,
  GenAIScrapeAttemptStatus.STARTED,
  GenAIScrapeAttemptStatus.SUCCESS,
  GenAIScrapeAttemptStatus.PARTIAL_FAIL,
  GenAIScrapeAttemptStatus.FAIL,
]

type KnowledgeSourceFilterOptions = {
  statuses: string[]
  knowledgeSourceIds: string[]
  failureReason: string | undefined
  existingFailureMessages: string[]
  showFilterModal: boolean
  toggleFilterModal: () => void
  addFilterToParams: (
    filterKey: string,
    filterValue: string,
    isMulti?: boolean
  ) => void
  removeFilterFromParams: (filterKey: string, filterValue?: string) => void
}

export function useKnowledgeSourceFilters(): KnowledgeSourceFilterOptions {
  const location = useLocation()
  const history = useHistory()

  const [statuses, setStatuses] = useState<string[]>([])
  const [knowledgeSourceIds, setKnowledgeSourceIds] = useState<string[]>([])
  const [failureReason, setErrorMsg] = useState<string | undefined>()
  const [existingFailureMessages, setExistingFailureMessages] = useState<
    string[]
  >([])
  const [showFilterModal, setShowFilterModal] = useState(false)

  React.useEffect(() => {
    generativeTextService.scrape.knowledgeSources
      .getAllFailureMessages()
      .then(res => {
        if (isRight(res)) {
          setExistingFailureMessages(res.right)
        } else {
          setExistingFailureMessages([])
        }
      })
  }, [])

  React.useEffect(() => {
    setKnowledgeSourceIds(
      queryParamToArray(parseQueryString(location.search)['id']).filter(
        id => id
      )
    )

    const statusesParam = queryParamToArray(
      parseQueryString(location.search)['statuses']
    ).filter(e =>
      SCRAPE_ATTEMPT_STATUSES.includes(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        e.toUpperCase() as GenAIScrapeAttemptStatus
      )
    )
    setStatuses(statusesParam)
    const errorMsgParam = parseFirstQueryParam(
      parseQueryString(location.search)['failureReason']
    )
    if (
      errorMsgParam &&
      statusesParam.includes(GenAIScrapeAttemptStatus.FAIL) &&
      existingFailureMessages.includes(errorMsgParam)
    ) {
      setErrorMsg(errorMsgParam)
    } else {
      setErrorMsg(undefined)
    }
  }, [location.search, existingFailureMessages])

  const removeFilterFromParams = (filterKey: string, filterValue?: string) =>
    history.push(removeQueryFilter(window.location, filterKey, filterValue))

  const addFilterToParams = (
    filterKey: string,
    filterValue: string,
    isMulti?: boolean
  ) =>
    history.push(
      appendQueryFilter(window.location, filterKey, filterValue, isMulti)
    )

  return {
    statuses,
    knowledgeSourceIds,
    failureReason,
    existingFailureMessages,
    showFilterModal,
    toggleFilterModal: () => setShowFilterModal(prev => !prev),
    addFilterToParams,
    removeFilterFromParams,
  }
}

export const KnowledgeSourcesFilterChips = ({
  statuses,
  failureReason,
  removeFilterFromParams,
  knowledgeSourceIds,
}: Pick<
  KnowledgeSourceFilterOptions,
  'statuses' | 'failureReason' | 'removeFilterFromParams' | 'knowledgeSourceIds'
>) => {
  return (
    <div>
      {statuses.map(elem => (
        <Chip
          key={elem}
          className="mr-2"
          label={capitalize(startCase(elem))}
          type="clear"
          onClear={() => {
            if (elem.toUpperCase() === 'FAIL') {
              removeFilterFromParams('failureReason')
            }
            removeFilterFromParams('statuses', elem)
          }}
        />
      ))}
      {failureReason && (
        <Chip
          className="mr-2"
          label={failureReason}
          type="clear"
          onClear={() => removeFilterFromParams('failureReason')}
        />
      )}
      {knowledgeSourceIds.map(id => (
        <Chip
          key={id}
          className="mr-2"
          label={id ? 'id: ' + id : ''}
          type="clear"
          onClear={() => removeFilterFromParams('id', id)}
        />
      ))}
    </div>
  )
}

interface IKnowledgeSourcesFilterModal {
  onSubmit: () => void
}

export const KnowledgeSourcesFilterModal = ({
  showFilterModal,
  statuses,
  existingFailureMessages,
  failureReason,
  removeFilterFromParams,
  addFilterToParams,
  toggleFilterModal,
  onSubmit,
}: IKnowledgeSourcesFilterModal &
  Omit<KnowledgeSourceFilterOptions, 'knowledgeSourceIds'>) => {
  const [selectedStatuses, setSelectedStatuses] = React.useState<
    ReadonlyArray<string>
  >([])
  const [selectedFailureReason, setSelectedFailureReason] = React.useState<
    string | undefined
  >()

  useEffect(() => {
    setSelectedStatuses(statuses)
    setSelectedFailureReason(failureReason)
  }, [statuses, failureReason])

  const failureMessageOptions = existingFailureMessages.map(msg => ({
    label: msg,
    value: msg,
  }))

  const handleSubmit = () => {
    removeFilterFromParams('statuses')
    selectedStatuses.forEach(status =>
      addFilterToParams('statuses', status, true)
    )
    if (selectedFailureReason) {
      addFilterToParams('failureReason', selectedFailureReason)
    } else {
      removeFilterFromParams('failureReason', selectedFailureReason)
    }
    onSubmit()
  }

  const disableErrorMsgSelector = !selectedStatuses.includes('FAIL')

  return (
    <Modal isOpen={showFilterModal} size="md" className="filter-modal">
      <SimpleModalHeader onClose={toggleFilterModal} text="Filter sources" />
      <div className="text-mainstay-dark-blue h-100">
        <ModalBody>
          <div className="px-4 pt-2 pb-2">
            <span className="fs-small fw-600">Status</span>
            <div className="mt-2">
              {SCRAPE_ATTEMPT_STATUSES.map(status => (
                <Checkbox
                  type="check"
                  checkBoxSize="sm"
                  key={status}
                  titleComponent={
                    <div className="d-flex ml-2 mb-1 text-mainstay-dark-blue">
                      <span>{capitalize(startCase(status))}</span>
                    </div>
                  }
                  checked={selectedStatuses.includes(status)}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedStatuses(prev => [...prev, status])
                    } else {
                      if (status === GenAIScrapeAttemptStatus.FAIL) {
                        setSelectedFailureReason(undefined)
                      }
                      setSelectedStatuses(prev =>
                        prev.filter(s => s !== status)
                      )
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <hr />
          <div className="px-4 pt-2 pb-2">
            <span
              className={cls('fs-small fw-600', {
                'text-muted': disableErrorMsgSelector,
              })}>
              Failure reason
            </span>
            <Select<{ label: string; value: string }>
              options={failureMessageOptions}
              key={`fail-reason-${selectedFailureReason}`}
              className="form-control p-0 border-o min-width-120px mt-3"
              isClearable
              onChange={(option, actionMeta) => {
                if (Array.isArray(option)) {
                  return
                }
                if (actionMeta.action === 'clear') {
                  setSelectedFailureReason(undefined)
                }
                if (option) {
                  setSelectedStatuses(prev => [
                    ...prev,
                    GenAIScrapeAttemptStatus.FAIL,
                  ])
                  setSelectedFailureReason(option.value)
                }
              }}
              value={failureMessageOptions.find(
                msg => msg.value === selectedFailureReason
              )}
            />
          </div>
        </ModalBody>
      </div>
      <SimpleModalFooter
        onSubmit={handleSubmit}
        onCancel={toggleFilterModal}
        submitText="Apply filters"
        cancelText="Cancel"
      />
    </Modal>
  )
}
