import { useState } from 'react'
import { Button } from 'components/Button/Button'
import { TextInput } from 'components/TextInput/TextInput'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import * as Raven from '@sentry/browser'
import { useUserId } from 'util/hooks'
import * as api from 'api'
import { isLeft } from 'fp-ts/lib/Either'
import { SettingWithoutField } from 'components/SettingWithoutField/SettingWithoutField'

type SynonymMap = {
  botname: string[]
  id: string[]
  institution: string[]
}

type SynonymName = keyof SynonymMap

function isSynonymName(synonymName: string): synonymName is SynonymName {
  return ['botname', 'id', 'institution'].includes(synonymName)
}

function normalizeSynonym(question: string) {
  return question
    .trim() // Remove leading/trailing whitespace
    .replace(/\s+/g, ' ') // Remove duplicate whitespace
    .replace(/[^a-zA-Z0-9\.?><\:;,\(\){}[\]\\\/\-—–_+=!@#$%\^&*|'\s]/, '') // Remove emoji and other characters that are stripped from questions
}

export function SynonymsSettingsForm({
  initialSynonyms,
}: {
  initialSynonyms: SynonymMap
}) {
  const [synonyms, setSynonyms] = useState<SynonymMap>(initialSynonyms)
  const userId = useUserId()

  const foundDuplicate = (newSynonym: string) => {
    return Object.entries(synonyms).some(pair => {
      const synonymName = pair[0]
      const synonymList = pair[1]
      if (synonymList.includes(newSynonym)) {
        toast(
          `'${newSynonym}' already exists as a synonym for @${synonymName}!`,
          { type: 'warning' }
        )
        return true
      }
      return false
    })
  }

  const isEmpty = (newSynonym: string) => {
    if (newSynonym === '') {
      toast('Synonym cannot be blank!', { type: 'warning' })
      return true
    }
    return false
  }

  const isValid = (newSynonym: string) => {
    if (foundDuplicate(newSynonym)) {
      return false
    }
    if (isEmpty(newSynonym)) {
      return false
    }
    return true
  }

  const handleSave = (synonymName: SynonymName, newSynonym: string) => {
    if (!isValid(newSynonym)) {
      return false
    }
    setSynonyms({
      ...synonyms,
      [synonymName]: synonyms[synonymName].concat(newSynonym),
    })
    api.addSynonym({ synonymName, synonym: newSynonym }).then(res => {
      if (isLeft(res)) {
        toast('Something went wrong saving synonym.', { type: 'error' })
        setSynonyms(synonyms)
        return false
      }
    })
    return true
  }

  const handleRemove = (synonymName: SynonymName, synonymToRemove: string) => {
    if (!synonyms[synonymName].includes(synonymToRemove)) {
      return
    }
    setSynonyms({
      ...synonyms,
      [synonymName]: synonyms[synonymName].filter(
        synonym => synonym !== synonymToRemove
      ),
    })
    api.removeSynonym({ synonymName, synonym: synonymToRemove }).then(res => {
      if (isLeft(res)) {
        toast('Something went wrong removing synonym.', { type: 'error' })
        setSynonyms(synonyms)
        return
      }
    })
  }

  return (
    <>
      {Object.entries(synonyms).map(nameAndSynonymPair => {
        const [synonymName, synonymList] = nameAndSynonymPair
        if (!isSynonymName(synonymName)) {
          Raven.withScope(scope => {
            scope.setExtras({
              userId,
              synonymName,
            })
            Raven.captureMessage('Invalid synonym name. What the heck', 'error')
          })
          return null
        }
        return (
          <SettingWithoutField
            key={synonymName}
            name={`@${synonymName}`}
            helpText={`Indicate synonyms for @${synonymName}. (Case Insensitive)`}>
            <SingleSynonymSettingsForm
              synonyms={synonymList}
              synonymName={synonymName}
              onSave={handleSave}
              onRemove={handleRemove}
            />
          </SettingWithoutField>
        )
      })}
    </>
  )
}

function SingleSynonymSettingsForm({
  synonyms,
  synonymName,
  onSave,
  onRemove,
}: {
  synonyms: string[]
  synonymName: SynonymName
  onSave: (synonymName: SynonymName, synonym: string) => boolean
  onRemove: (synonymName: SynonymName, synonym: string) => void
}) {
  const [textInput, setTextInput] = useState('')
  const [error, setError] = useState(false)
  return (
    <>
      <ul className="list-unstyled mt-1 pl-1 text-muted font-weight-bold">
        {synonyms.map(synonym => {
          return (
            <li key={synonym}>
              <section className="d-flex align-items-center justify-content-start">
                <p className="mb-0 text-truncate">{synonym}</p>
                <Button
                  color="link"
                  onClick={() => onRemove(synonymName, synonym)}>
                  Remove
                </Button>
              </section>
            </li>
          )
        })}
      </ul>
      <section className="d-flex mb-2">
        <TextInput
          error={error}
          value={textInput}
          onChange={e => {
            setError(false)
            setTextInput(e.target.value)
          }}
          placeholder={`Add synonym for @${synonymName}`}
          className="mr-2"
        />
        <Button
          color="primary"
          onClick={() => {
            const normalizedSynonym = normalizeSynonym(textInput)
            if (onSave(synonymName, normalizedSynonym)) {
              setTextInput('')
              return
            }
            setError(true)
          }}
          outlined>
          Add
        </Button>
      </section>
      <hr />
    </>
  )
}
