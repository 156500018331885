import * as api from 'api'
import { Button } from 'components/Button/Button'
import Select from 'components/Select/SelectV2'
import { TextInput } from 'components/TextInput/TextInput'
import React from 'react'

import classnames from 'classnames'
import { FeaturesType } from 'components/Feature/Feature'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { isRight } from 'fp-ts/lib/Either'
import { isUndefined, sortBy } from 'lodash'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { SectionTitle } from 'page/SettingsGeneral'
import {
  getAllContactAttributes,
  IContactAttributes,
} from 'store/personalization/contactAttributes/selectors'
import { getAllContactAttributesAsync } from 'store/personalization/contactAttributes/thunks'
import { Failure, isLoading, isSuccess, Loading, WebData } from 'store/webdata'
import { useDispatch, useSelector } from 'util/hooks'
import { ConfirmationModal } from 'components/Modal/Modal'
import { noOp } from 'util/noOp'
import { isArrayOfStrings } from 'util/typeguards'
import { SettingWithoutField } from 'components/SettingWithoutField/SettingWithoutField'
import { getHasLimitedUserGroups } from 'store/triage/profile/selectors'

interface IAttributeOption {
  label: string
  value: number | undefined
}

interface IRegionInputProps {
  readonly region: string
  readonly removing: boolean
  readonly disabled: boolean
  readonly onRemove: (regionInputValue: string) => void
}

const RegionInput = ({
  region,
  disabled,
  onRemove,
  removing,
}: IRegionInputProps) => {
  return (
    <li>
      <section className="d-flex align-items-center justify-content-start">
        <p className="mb-0 text-truncate">{region}</p>
        <Button
          color="link"
          disabled={disabled}
          onClick={() => onRemove(region)}
          loading={removing}>
          Remove
        </Button>
      </section>
    </li>
  )
}

const RegionRemoveFailureModal = ({
  emailReferences,
  onClose,
}: {
  emailReferences: string[]
  onClose: () => void
}) => {
  const show = emailReferences.length > 0
  return (
    <ConfirmationModal
      isReadonly
      hideCheckbox
      show={show}
      title="This user group cannot be removed"
      onConfirm={noOp}
      onClose={onClose}
      helpText=""
      confirmButtonText=""
      cancelButtonText="Close"
      cancelButtonClassName="border-color-mainstay-teal text-mainstay-teal">
      <div>
        This user group cannot be removed because the following users are
        assigned to it:
        <div className="mt-2">
          {emailReferences?.map(email => (
            <p className="mb-0" key={email}>
              {email}
            </p>
          ))}
        </div>
      </div>
    </ConfirmationModal>
  )
}

interface IRegionsSettingsProps {
  readonly availableRegions: string[]
  readonly regionsEnabled: boolean
  readonly regionField: string | undefined
}

interface IRequestsStatus {
  addRegion: WebData<string>
  removeRegion: { [r: string]: WebData<string> }
  updateRegionAttribute: WebData<string>
}

/** Product term for regions is User Groups.  Therefore they are used interchangeably in code. */
export const UserGroupSettings = ({
  regionField,
  regionsEnabled,
  availableRegions,
}: IRegionsSettingsProps) => {
  const isRegionalUser = !!useSelector(getHasLimitedUserGroups)

  const [currentRegions, setCurrentRegions] = React.useState<string[]>(
    availableRegions
  )
  const [currentRegionField, setCurrentRegionField] = React.useState(
    regionField
  )
  const [emailReferencesInRegion, setEmailReferencesInRegion] = React.useState<
    string[]
  >([])
  const [regionInputValue, setRegionInputValue] = React.useState('')
  const [selectedRegionAttribute, setSelectedRegionAttribute] = React.useState<
    IAttributeOption | undefined
  >(undefined)
  const [regionsFeatureEnabled, setRegionsFeatureEnabled] = React.useState(
    regionsEnabled
  )
  const [requestsStatus, setRequestsStatus] = React.useState<IRequestsStatus>({
    addRegion: undefined,
    removeRegion: {},
    updateRegionAttribute: undefined,
  })

  const dispatch = useDispatch()
  const contactAttributes = useSelector(getAllContactAttributes)

  React.useEffect(() => {
    if (isUndefined(contactAttributes?.kind)) {
      getAllContactAttributesAsync(dispatch)()
    }
  }, [contactAttributes?.kind, dispatch])

  React.useEffect(() => {
    setRegionsFeatureEnabled(regionsEnabled)
    setCurrentRegions(availableRegions)
    setCurrentRegionField(regionField)
  }, [regionsEnabled, availableRegions, regionField])

  const handleRemoveRegionValue = (regionInputValue: string) => {
    setRequestsStatus(prev => ({
      ...prev,
      removeRegion: { ...prev.removeRegion, [regionInputValue]: Loading() },
    }))
    api
      .updateAvailableRegion({
        regionValue: regionInputValue,
        action: 'remove',
      })
      .then(res => {
        if (isRight(res)) {
          setCurrentRegions(res.right.availableRegions ?? [])
          setRequestsStatus(prev => ({
            ...prev,
            removeRegion: {
              ...prev.removeRegion,
              [regionInputValue]: undefined,
            },
          }))
        } else {
          if (
            res.left.kind === 'http' &&
            res.left.http.response?.status === 400
          ) {
            const emailsInRegion =
              /* tslint:disable-next-line:no-any no-unsafe-any */
              res.left.http.response?.data?.emails_in_region
            setEmailReferencesInRegion(
              isArrayOfStrings(emailsInRegion)
                ? emailsInRegion
                : ['unknown email']
            )
          }
          setRequestsStatus(prev => ({
            ...prev,
            removeRegion: {
              ...prev.removeRegion,
              [regionInputValue]: Failure(),
            },
          }))
        }
      })
  }

  const handleAddRegionValue = () => {
    setRequestsStatus(prev => ({
      ...prev,
      addRegion: Loading(),
    }))
    api
      .updateAvailableRegion({
        regionValue: regionInputValue,
        action: 'add',
      })
      .then(res => {
        if (isRight(res)) {
          setCurrentRegions(res.right.availableRegions ?? [])
          setRequestsStatus(prev => ({
            ...prev,
            addRegion: undefined,
          }))
          setRegionInputValue('')
        } else {
          toast.error('Failed to add User Group.')
          setRequestsStatus(prev => ({
            ...prev,
            addRegion: Failure(),
          }))
        }
      })
  }

  const handleToggleFeatureFlag = (enable: boolean) => {
    api
      .updateFeatureFlagForInstitution({
        feature: FeaturesType.PERMS_REGIONS_ENABLED,
        enable,
      })
      .then(() => {
        setRegionsFeatureEnabled(enable)
      })
      .catch(() => {
        toast.error('Failed to update User Group setting.')
      })
  }

  const mapContactAttributesToOptions = (attributes: IContactAttributes) =>
    attributes.map(attr => ({ label: attr.field, value: attr.id }))

  const handleUpdateRegionAttribute = (
    id: number | undefined = undefined,
    name: string | undefined = undefined
  ) => {
    setRequestsStatus(prev => ({ ...prev, updateRegionAttribute: Loading() }))
    api.setRegionAttribute({ id }).then(res => {
      if (isRight(res)) {
        setRequestsStatus(prev => ({
          ...prev,
          updateRegionAttribute: undefined,
        }))
        setCurrentRegionField(name)
      } else {
        setRequestsStatus(prev => ({
          ...prev,
          updateRegionAttribute: Failure(),
        }))
        toast.error('Failed to update User Group.')
      }
    })
  }

  return (
    <>
      <SectionTitle>User Groups</SectionTitle>
      {isRegionalUser && (
        <p className="text-muted">
          To make changes to User Groups, contact your platform admin.
        </p>
      )}
      <SettingWithoutField
        name="User Groups"
        helpText="User Groups restrict user access to specific contact cohorts.">
        <div className="d-flex align-items-center mb-4">
          <ToggleSwitch
            checked={regionsFeatureEnabled}
            onChange={handleToggleFeatureFlag}
            disabled={isRegionalUser}
          />
        </div>
      </SettingWithoutField>
      {regionsFeatureEnabled && (
        <>
          <SettingWithoutField
            name="User Groups List"
            helpElement={
              <>
                <p className="caption">
                  Define the user groups available for Users. When inviting or
                  editing a user, you can restrict their access to contacts in a
                  specific user group, or allow access to all user groups.
                </p>
                <p className="caption">
                  Be sure the user groups configured for users match the user
                  group values on your Contact records.
                </p>
              </>
            }>
            <div>
              <section className="d-flex mt-2">
                <TextInput
                  value={regionInputValue}
                  onChange={e => setRegionInputValue(e.target.value)}
                  placeholder="Add User Group"
                  className="mr-2"
                  disabled={isRegionalUser}
                />
                <Button
                  color="primary"
                  onClick={handleAddRegionValue}
                  disabled={isRegionalUser || !regionInputValue}
                  outlined
                  loading={isLoading(requestsStatus.addRegion)}>
                  Add
                </Button>
              </section>
              <ul className="list-unstyled mt-3 pl-1 text-muted font-weight-bold">
                {sortBy(currentRegions).map(region => {
                  return (
                    <RegionInput
                      key={region}
                      region={region}
                      disabled={isRegionalUser}
                      onRemove={handleRemoveRegionValue}
                      removing={isLoading(requestsStatus.removeRegion[region])}
                    />
                  )
                })}
              </ul>
            </div>
          </SettingWithoutField>
          <RegionRemoveFailureModal
            emailReferences={emailReferencesInRegion}
            onClose={() => setEmailReferencesInRegion([])}
          />
          <SettingWithoutField
            name="User Groups contact field"
            helpElement={
              <p className="caption">
                Select one custom field. Users will only have access to Contacts
                whose value for this field matches their User Group. If no field
                is selected, User access will not be restricted.
              </p>
            }>
            <section className="d-flex mt-2">
              <Select<IAttributeOption>
                className={classnames(
                  'form-control',
                  'p-0',
                  'border-0',
                  'min-width-200px',
                  'mr-2'
                )}
                classNamePrefix="react-select"
                name="contactAttributes"
                placeholder="Search"
                value={selectedRegionAttribute}
                isDisabled={isRegionalUser || !!currentRegionField}
                onChange={option => {
                  if (!Array.isArray(option) && option) {
                    setSelectedRegionAttribute(option)
                  }
                }}
                options={
                  isSuccess(contactAttributes)
                    ? mapContactAttributesToOptions(contactAttributes.data)
                    : []
                }
                closeMenuOnSelect={true}
              />
              <Button
                color="primary"
                outlined
                onClick={() =>
                  handleUpdateRegionAttribute(
                    selectedRegionAttribute?.value,
                    selectedRegionAttribute?.label
                  )
                }
                disabled={
                  isRegionalUser || isUndefined(selectedRegionAttribute?.value)
                }
                loading={
                  isLoading(requestsStatus.updateRegionAttribute) &&
                  !isUndefined(selectedRegionAttribute)
                }>
                Set
              </Button>
            </section>
            {currentRegionField && (
              <div className="mt-3 pl-1 text-muted font-weight-bold d-flex align-items-center justify-content-start">
                <p className="mb-0 text-truncate">{currentRegionField}</p>
                <Button
                  color="link"
                  disabled={isRegionalUser}
                  onClick={() => {
                    setSelectedRegionAttribute(undefined)
                    handleUpdateRegionAttribute()
                  }}
                  loading={
                    isLoading(requestsStatus.updateRegionAttribute) &&
                    isUndefined(selectedRegionAttribute)
                  }>
                  Remove
                </Button>
              </div>
            )}
          </SettingWithoutField>
        </>
      )}
    </>
  )
}
