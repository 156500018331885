import * as React from 'react'
import { RootState as IState, Dispatch } from 'store/store'
import Select from 'components/Select/SelectV2'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { listContactSegmentsAsync } from 'store/contact-segments/thunks'
import {
  listContactSegments,
  IContactSegmentList,
} from 'store/contact-segments/selectors'
import { WebData, isInitial, isSuccess } from 'store/webdata'
import { MenuListProps } from 'react-select/lib/components/Menu'
import { ConfirmationModal } from 'components/Modal/Modal'
import { Portal } from 'react-portal'
import { ContactFilterBuilder } from 'components/ContactFilterBuilder/ContactFilterBuilder'
import { IContactFilterFormData } from 'components/ContactFilterBuilder/formUtils'
import { Props as SelectProps } from 'react-select/lib/Select'
import { Button } from 'components/Button/Button'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { CONTACTS } from 'const/routes'
import { generatePath } from 'react-router'
import { Link } from 'util/routing'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { isNil, isUndefined } from 'lodash'
import {
  DropdownIndicator,
  FloatingLabelSelect,
  ValueContainer,
} from 'components/SettingsEscalation/reactSelectUtils'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'
import { notUndefined } from 'util/typeguards'
import { noOp } from 'util/noOp'
import { SelectComponents } from 'react-select/lib/components'
import 'components/ContactSegmentSelect/ContactSegmentSelect.scss'
import {
  ActionMeta,
  GroupedOptionsType,
  OptionsType,
  ValueType,
} from 'react-select/lib/types'

export interface ISegmentOption {
  label: string | JSX.Element
  value: number | undefined
  name: string
}
interface IContactSegmentSelectDispatchProps {
  fetchContactSegments: (data?: { region: string | null }) => void
}

interface IContactSegmentSelectStateProps {
  segments: WebData<IContactSegmentList>
}

interface IContactSegmentSelectOwnProps {
  showFilterBuilder: boolean
  selectedFilterId: number | undefined | null
  savedFilterId?: number
  disabled: boolean
  disableRecipientsPreview?: boolean
  modalView?: boolean
  confirmFilterChange?: boolean
  includeAllContactsOption?: boolean
  defaultToAllContacts?: boolean
  createInNew?: boolean
  showLinkToContactFilter?: boolean
  showLinkToContactFilterInOption?: boolean
  allowEdit: boolean
  onChange: (selectedSegment?: ISegmentOption) => void
  onNewSegment?: () => void
  onCancel?: () => void
  onSaveAndContinue?: (formData: IContactFilterFormData) => void
  eventLocation?: string
  placeholder?: string
  customClassName?: string
  selectedFilters?: { [key: number]: boolean }
  showCreateNewButton?: boolean
  error?: boolean
  floatingLabel?: string
  omittedAudienceIds?: number[]
}

const allContacts = {
  label: 'All Contacts',
  name: 'All Contacts',
  value: undefined,
}

const renderSegmentOptionLabel = (
  segment: ISegmentOption,
  showLinkToContactFilterInOption?: boolean
) =>
  !!showLinkToContactFilterInOption ? (
    <div className="d-flex align-items-center justify-content-between">
      <div>{segment.name}</div>
      <OpenInNewLink id={segment.value} />
    </div>
  ) : (
    segment.name
  )
const mapSegmentsToOptions = ({
  segmentList,
  includeAllContactsOption,
  selectedFilters = {},
  omittedAudienceIds = [],
  selectedSegment,
  showLinkToContactFilterInOption,
}: {
  segmentList: IContactSegmentList
  includeAllContactsOption: boolean
  selectedFilters: { [key: number]: boolean }
  omittedAudienceIds?: number[]
  selectedSegment: ISegmentOption | null
  showLinkToContactFilterInOption?: boolean
}): ISegmentOption[] => {
  // Map IContactSegmentShort to ISegmentOption
  const mappedSegmentList = segmentList
    .map(segment => {
      if (!selectedFilters[segment.id]) {
        return {
          label: renderSegmentOptionLabel(
            { label: segment.name, name: segment.name, value: segment.id },
            showLinkToContactFilterInOption
          ),
          value: segment.id,
          name: segment.name,
        }
      }
    })
    .filter(notUndefined)
    // Filter out already-chosen audiences from multi-audience campaign selector
    .filter(
      option =>
        option.value && !(omittedAudienceIds || []).includes(option.value)
    )

  // Add All Contacts (no audience) option to top of list
  if (includeAllContactsOption) {
    return [allContacts, ...mappedSegmentList]
  }

  // Pin selected option to top of list
  if (selectedSegment) {
    return [
      {
        label: renderSegmentOptionLabel(
          selectedSegment,
          showLinkToContactFilterInOption
        ),
        value: selectedSegment.value,
        name: selectedSegment.name,
      },
      ...mappedSegmentList.filter(
        segment => segment.value !== selectedSegment.value
      ),
    ]
  }
  return mappedSegmentList
}
export const getAudienceName = (
  id: number | undefined,
  segments: IContactSegmentList
): string | undefined => {
  if (isUndefined(id)) {
    return allContacts.label
  }
  return segments.find(segment => segment.id === id)?.name
}

type CustomSelectProps = Omit<SelectProps<ISegmentOption>, 'onChange'> & {
  floatingLabel?: string
  explicitPreventOverlap?: boolean
  region?: string | null
}

const OpenInNewLink = ({ id }: { id: number | undefined }) =>
  id === undefined ? (
    <div>
      <AHIcon name="open_in_new" className="ml-2 text-mainstay-dark-blue-80" />
    </div>
  ) : (
    <Link
      target="_blank"
      className="mt-1"
      to={generatePath(CONTACTS.AUDIENCE_DETAIL, {
        id,
      })}>
      <AHIcon name="open_in_new" className="ml-2 audience-option" />
    </Link>
  )

const WrappedSelect = ({
  floatingLabel,
  selectedSegment,
  onChange,
  isOpen,
  isClearable,
  onMenuOpen,
  onMenuClose,
  className,
  placeholder,
  options,
  components,
  isDisabled,
  error,
  isLoading,
  explicitPreventOverlap,
  hideSelectedOptions,
  ...restSelectProps
}: {
  floatingLabel?: string
  selectedSegment: ISegmentOption | null
  onChange: (value: ValueType<ISegmentOption>, action: ActionMeta) => void
  isOpen: boolean
  onMenuOpen: () => void
  onMenuClose: () => void
  components: Partial<SelectComponents<ISegmentOption>>
  options: OptionsType<ISegmentOption> | GroupedOptionsType<ISegmentOption>
  placeholder?: string
  isClearable: boolean
  isDisabled: boolean
  error: boolean
  className: string
  isLoading: boolean
  explicitPreventOverlap?: boolean
  hideSelectedOptions?: boolean
} & SelectProps<ISegmentOption>) => {
  const classNamePrefix = 'react-select'

  return floatingLabel ? (
    <FloatingLabelSelect
      floatingLabel={floatingLabel}
      className={className}
      classNamePrefix={classNamePrefix}
      placeholder={placeholder}
      value={selectedSegment}
      isLoading={isLoading}
      options={options}
      components={components}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isDisabled={isDisabled}
      closeMenuOnSelect={true}
      onChange={onChange}
      menuIsOpen={isOpen}
      hasError={error}
      explicitPreventOverlap={!!explicitPreventOverlap}
      hideSelectedOptions={hideSelectedOptions}
      {...restSelectProps}
    />
  ) : (
    <Select<ISegmentOption>
      className={className}
      classNamePrefix={classNamePrefix}
      placeholder={placeholder}
      value={selectedSegment}
      isLoading={isLoading}
      options={options}
      components={components}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isDisabled={isDisabled}
      isClearable={isClearable}
      closeMenuOnSelect={true}
      onChange={onChange}
      menuIsOpen={isOpen}
      error={error}
      explicitPreventOverlap={!!explicitPreventOverlap}
      {...restSelectProps}
    />
  )
}
const UnconnectedContactSegmentSelect = ({
  fetchContactSegments,
  segments,
  showFilterBuilder,
  selectedFilterId,
  disabled,
  savedFilterId,
  modalView,
  confirmFilterChange,
  disableRecipientsPreview,
  includeAllContactsOption,
  defaultToAllContacts,
  createInNew,
  showLinkToContactFilter,
  showLinkToContactFilterInOption,
  allowEdit,
  region,
  eventLocation,
  onNewSegment,
  onChange,
  onCancel,
  onSaveAndContinue,
  placeholder,
  customClassName,
  selectedFilters = {},
  showCreateNewButton = true,
  error,
  floatingLabel,
  explicitPreventOverlap,
  omittedAudienceIds,
  hideSelectedOptions,
  ...selectProps
}: IContactSegmentSelectDispatchProps &
  IContactSegmentSelectStateProps &
  IContactSegmentSelectOwnProps &
  CustomSelectProps) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }
  const handleNewSegment = () => {
    if (onNewSegment) {
      onNewSegment()
    }
  }
  const handleSaveAndContinue = (formData: IContactFilterFormData) => {
    if (onSaveAndContinue) {
      onSaveAndContinue(formData)
    }
  }

  const [
    selectedSegment,
    setSelectedSegment,
  ] = React.useState<ISegmentOption | null>(null)

  const shouldLoadSegments = isInitial(segments)
  React.useEffect(() => {
    if (shouldLoadSegments || allowEdit) {
      if (region) {
        fetchContactSegments({ region })
      } else {
        fetchContactSegments()
      }
    }
  }, [region, fetchContactSegments, allowEdit, shouldLoadSegments])

  const [
    showSegmentChangeConfirmModal,
    setShowSegmentChangeConfirmModal,
  ] = React.useState(false)
  const [onSegmentChange, setOnSegmentChange] = React.useState<() => void>()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoading, setLoading] = React.useState(true)

  const showAllContactsOption = includeAllContactsOption || defaultToAllContacts

  React.useEffect(() => {
    // convert selected filter id to name for input label
    const filterId = selectedFilterId || savedFilterId
    if (isSuccess(segments) && !isNil(filterId)) {
      const selectedSegment = segments.data.filter(
        elem => Number(elem.id) === filterId
      )[0]
      setSelectedSegment(
        !!selectedSegment
          ? {
              label: selectedSegment.name,
              name: selectedSegment.name,
              value: selectedSegment.id,
            }
          : null
      )
    } else if (showAllContactsOption) {
      setSelectedSegment(allContacts)
    } else {
      // explicitly set to null so placeholder is used
      setSelectedSegment(null)
    }
    setLoading(!isSuccess(segments))
  }, [
    segments,
    selectedFilterId,
    savedFilterId,
    showAllContactsOption,
    showLinkToContactFilterInOption,
  ])

  const {
    components: componentsProp,
    className,
    ...restSelectProps
  } = selectProps

  return (
    <>
      <EventTrackerAttrAdder
        eventAction="change"
        eventLocation={eventLocation}
        eventObject="audience">
        <div
          className={classnames(
            'd-flex',
            'align-items-center',
            customClassName
          )}>
          <WrappedSelect
            explicitPreventOverlap={!!explicitPreventOverlap}
            floatingLabel={floatingLabel}
            selectedSegment={selectedSegment}
            hideSelectedOptions={hideSelectedOptions}
            error={!!error}
            isLoading={isLoading}
            onChange={selectedSegment => {
              if (Array.isArray(selectedSegment)) {
                return
              }
              if (selectedSegment) {
                if (
                  !!confirmFilterChange &&
                  (showFilterBuilder || savedFilterId)
                ) {
                  setOnSegmentChange(() => () => {
                    onChange(selectedSegment)
                  })
                  setShowSegmentChangeConfirmModal(true)
                } else {
                  onChange(selectedSegment)
                }
              } else {
                // behavior on clear (clicking x in Select control):
                // - set selectedSegment on state to null to replace the placeholder
                // - pass undefined back up to the parent to clear out the filter builder
                onChange(undefined)
                handleCancel()
              }
            }}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            placeholder={
              !!placeholder
                ? placeholder
                : isLoading || floatingLabel
                ? ''
                : 'Select Audience...'
            }
            className={classnames(
              'p-0',
              'border-0',
              'w-100',
              'min-width-200px',
              className
            )}
            options={
              isSuccess(segments)
                ? mapSegmentsToOptions({
                    segmentList: segments.data,
                    includeAllContactsOption: !!includeAllContactsOption,
                    selectedFilters: !hideSelectedOptions
                      ? {}
                      : selectedFilters || {},
                    omittedAudienceIds,
                    selectedSegment,
                    showLinkToContactFilterInOption,
                  })
                : []
            }
            components={{
              MenuList: menuListProps =>
                ContactSegmentMenuList(
                  menuListProps,
                  () => {
                    if (
                      !!confirmFilterChange &&
                      (showFilterBuilder || savedFilterId)
                    ) {
                      setOnSegmentChange(() => () => {
                        setIsOpen(false)
                        handleNewSegment()
                      })
                      setShowSegmentChangeConfirmModal(true)
                    } else {
                      setIsOpen(false)
                      handleNewSegment()
                    }
                  },
                  createInNew,
                  showCreateNewButton
                ),
              ...componentsProp,
            }}
            isDisabled={disabled}
            isOpen={isOpen}
            isClearable={!!selectedSegment}
            {...restSelectProps}
          />
          {showLinkToContactFilter && selectedSegment && (
            <OpenInNewLink id={selectedSegment.value} />
          )}
        </div>
      </EventTrackerAttrAdder>
      <ConfirmationModal
        hideCheckbox
        show={showSegmentChangeConfirmModal}
        helpText={
          <span className="text-mainstay-dark-blue-80">
            Any change to the current audience will be discarded.
          </span>
        }
        onClose={() => {
          setShowSegmentChangeConfirmModal(false)
        }}
        title="Switch Audience"
        onConfirm={() => {
          if (onSegmentChange) {
            onSegmentChange()
          }
          setShowSegmentChangeConfirmModal(false)
        }}
        confirmButtonText="Confirm"
        zIndex={1800}
      />
      <div>
        {(showFilterBuilder || !!savedFilterId) &&
          (modalView ? (
            <div className="modal-80vh">
              <div className="position-sticky">
                <h1 className="h5 fs-21px mb-0 px-4 mt-4">Audience Builder</h1>
                <hr className="shadow-border mb-0" />
              </div>
              {isSuccess(segments) && (
                <ContactFilterBuilder
                  allFilters={segments.data}
                  filterId={selectedFilterId || savedFilterId}
                  onCancel={handleCancel}
                  onSaveAndContinue={handleSaveAndContinue}
                  disableRecipientsPreview={disableRecipientsPreview}
                />
              )}
            </div>
          ) : (
            <Portal node={document.getElementById('contact-filter-portal')}>
              {isSuccess(segments) && (
                <div className="pb-5">
                  <ContactFilterBuilder
                    allFilters={segments.data}
                    filterId={selectedFilterId || savedFilterId}
                    onCancel={handleCancel}
                    onSaveAndContinue={handleSaveAndContinue}
                    disableRecipientsPreview={disableRecipientsPreview}
                  />
                </div>
              )}
            </Portal>
          ))}
      </div>
    </>
  )
}

interface IContactSegmentMenuListProps extends MenuListProps {
  getStyles: (name: string, props: MenuListProps) => {}
  handleClick?: () => void
  showCreateNewButton?: boolean
}

function ContactSegmentMenuList(
  props: IContactSegmentMenuListProps,
  handleClick: () => void,
  createInNew?: boolean,
  showCreateNewButton = true
) {
  return (
    <>
      <div ref={props.innerRef} style={props.getStyles('menuList', props)}>
        {props.children}
      </div>
      {showCreateNewButton && (
        <PermissionGuard permission={PERMISSIONS.AUDIENCE.CREATE}>
          <Button
            style={{ boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.25)' }}
            className="mt-2 p-3 pointer text-secondary-teal w-100 bg-white action-btn"
            onClick={handleClick}>
            New Audience
            {createInNew && <AHIcon name="open_in_new" className="ml-2" />}
          </Button>
        </PermissionGuard>
      )}
    </>
  )
}

const mapStateToProps = (
  state: IState,
  ownProps: IContactSegmentSelectOwnProps
): IContactSegmentSelectStateProps => {
  return {
    ...ownProps,
    segments: listContactSegments(state),
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch
): IContactSegmentSelectDispatchProps => {
  return {
    fetchContactSegments: (data?: { region: string | null }) =>
      listContactSegmentsAsync(dispatch)(data),
  }
}

export const ContactSegmentSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedContactSegmentSelect)

export const ReadOnlyContactSegmentSelect = (
  props: Omit<
    IContactSegmentSelectOwnProps,
    'showFilterBuilder' | 'disabled' | 'onSaveAndContinue' | 'allowEdit'
  > &
    CustomSelectProps
) => {
  return (
    <ContactSegmentSelect
      {...props}
      explicitPreventOverlap={!!props.explicitPreventOverlap}
      hideSelectedOptions={props.hideSelectedOptions}
      allowEdit={false}
      disabled={false}
      showFilterBuilder={false}
      disableRecipientsPreview={true}
      createInNew={true}
      modalView={true}
      // we don't allow audience filter creation from this component, so operations below are no-op unless explicitly passed in
      onSaveAndContinue={noOp}
      onCancel={props.isClearable && props.onCancel ? props.onCancel : noOp}
      placeholder={props.placeholder || ''}
      components={{
        DropdownIndicator,
        ValueContainer,
        IndicatorSeparator: undefined,
      }}
      customClassName={props.customClassName}
      selectedFilters={props.selectedFilters}
      error={props.error}
    />
  )
}
