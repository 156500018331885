import { RootState as IState } from 'store/store'
import { createSelector } from 'reselect'
import randomColor from 'randomcolor'
import * as _ from 'lodash'
import {
  getConversationAuthor,
  getContactByIdFromUrl,
} from 'store/triage/chat/selectors'
import { ADMITHUB_PRIMARY, DISTINCT_COLORS_LIST } from 'const/settings'

const DEFAULT_DATE_FORMAT = '%b %-d, %Y'
export const TIME_FORMAT = '%l:%M:%S %p' // eg. 9:05:38 PM

const getLegacyCurrentInstitution = (state: IState) => {
  return state.triage.application.institution.currentInstitution
}

export const getContactInstitution = createSelector(
  getContactByIdFromUrl,
  getLegacyCurrentInstitution,
  (contact, currentInstitution) =>
    contact && contact.org === currentInstitution?.id
      ? currentInstitution
      : undefined
)
export const getFullCurrentInstitution = (state: IState) => {
  return state.triage.application.institution.currentInstitution
}

export const getInstitutionIdFromConversation = createSelector(
  getConversationAuthor,
  contact => contact && contact.org
)

export const getInstitionUserGroups = createSelector(
  getFullCurrentInstitution,
  inst => (inst && inst.regions_map) || {}
)

export const getUserGroupColors = createSelector(
  getFullCurrentInstitution,
  getInstitionUserGroups,
  (inst, regionsMap) => {
    // note(fjlanasa): replace if/when we actually save colors for an institution's regions
    if (inst && regionsMap) {
      const regionKeys = Object.keys(regionsMap)
      let colors = DISTINCT_COLORS_LIST
      if (regionKeys.length > colors.length) {
        colors = colors.concat(
          randomColor({
            seed: inst.id,
            count: regionKeys.length - colors.length,
          })
        )
      }
      const baseObj: {
        [region: string]: string
        default: string
      } = { default: ADMITHUB_PRIMARY }

      return Object.keys(regionsMap).reduce((acc, curr, index) => {
        acc[curr] = colors[index]
        return acc
      }, baseObj)
    } else {
      return { default: ADMITHUB_PRIMARY }
    }
  }
)

export const getUserGroupFilterOptions = createSelector(
  getInstitionUserGroups,
  getUserGroupColors,
  (regionMap, colors) => {
    if (regionMap && colors) {
      return Object.keys(regionMap).map(regionId => {
        return {
          id: regionId,
          displayName: regionMap[regionId],
          color: colors[regionId],
        }
      })
    } else {
      return []
    }
  }
)

export const getCurrentInstitutionStatus = (state: IState) => {
  return state.triage.application.institution.fetchingCurrent
}

export const getInstitutionDateFormat = (state: IState) =>
  state.triage.application.profile.currentUser.institution.dateFormat ||
  DEFAULT_DATE_FORMAT

export const getInstitutionDateFormatSettings = (state: IState) =>
  state.triage.application.institution.currentInstitution?.dateFormat ||
  DEFAULT_DATE_FORMAT
