import * as Raven from '@sentry/browser'
import { Link } from 'util/routing'
import * as ROUTES from 'const/routes'
import { NavBarPageSideBar } from 'page/NavBarPage'
import { useEffect } from 'react'
import { useIsLoggedIn } from 'util/hooks'
import NotFoundGame from 'page/NotFoundGame/NotFoundGame'

const NoMatch = () => {
  useEffect(() => {
    const location = window.location.href
    const message = `404: Page not found for path: ${location}`
    Raven.addBreadcrumb({ type: 'navigation', message })
  })

  const isLoggedIn = useIsLoggedIn()

  return (
    <main className="d-flex h-100">
      {isLoggedIn && <NavBarPageSideBar />}
      <div className="align-items-center d-flex flex-column flex-grow-1 justify-content-center height-50vh">
        <h1 className="h1 title bold text-center">404</h1>
        <p className="h4 text-center">Nothing here 🌵</p>
        <Link to={ROUTES.DEFAULT_ROUTE}>Return to Dashboard</Link>
      </div>
      {isLoggedIn && <NotFoundGame />}
    </main>
  )
}

export default NoMatch
