import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import {
  ICampaignDetailsResponseData,
  ICampaignImportResponseData,
  IRecurringCampaignDetailsResponseData,
  ICampaignTriggerDetailsResponseData,
  ICampaignByDialogDetailsResponseData,
} from 'api/response'
import { ICampaign } from 'store/campaign-history/reducer'
import { HttpErrorKind } from 'store/webdata'
import { IRecurrenceSettings } from 'store/campaign-scheduler/reducer'

export const fetchCampaignDetails = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_DETAILS_START',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_DETAILS_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_DETAILS_FAILURE'
)<void, ICampaignDetailsResponseData, { errorKind: HttpErrorKind }>()

export const fetchRecurringCampaignDetails = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_RECURRING_CAMPAIGN_DETAILS_START',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_RECURRING_CAMPAIGN_DETAILS_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_RECURRING_CAMPAIGN_DETAILS_FAILURE'
)<void, IRecurringCampaignDetailsResponseData, { errorKind: HttpErrorKind }>()

export const fetchAggRecurringCampaignDetails = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_AGG_RECURRING_CAMPAIGN_DETAILS_START',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_AGG_RECURRING_CAMPAIGN_DETAILS_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_AGG_RECURRING_CAMPAIGN_DETAILS_FAILURE'
)<void, ICampaignDetailsResponseData, { errorKind: HttpErrorKind }>()

export const fetchCampaignTriggerDetails = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_TRIGGER_DETAILS_START',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_TRIGGER_DETAILS_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_TRIGGER_DETAILS_FAILURE'
)<void, ICampaignTriggerDetailsResponseData, { errorKind: HttpErrorKind }>()

export const fetchIntroDialogVariantDetails = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_WEBCHAT_INTRO_CAMPAIGN_DETAILS_START',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_WEBCHAT_INTRO_CAMPAIGN_DETAILS_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_WEBCHAT_INTRO_CAMPAIGN_DETAILS_FAILURE'
)<void, ICampaignByDialogDetailsResponseData, { errorKind: HttpErrorKind }>()

export const fetchCampaignImportDetails = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_IMPORT_DETAILS_START',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_IMPORT_DETAILS_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/FETCH_CAMPAIGN_IMPORT_DETAILS_FAILURE'
)<void, ICampaignImportResponseData, { errorKind: HttpErrorKind }>()

export const generateCampaignReport = createAsyncAction(
  '@@MASCOT/CAMPAIGN_DETAILS/GENERATE_CAMPAIGN_REPORT_START',
  '@@MASCOT/CAMPAIGN_DETAILS/GENERATE_CAMPAIGN_REPORT_SUCCESS',
  '@@MASCOT/CAMPAIGN_DETAILS/GENERATE_CAMPAIGN_REPORT_FAILURE'
)<
  { campaignId: ICampaign['id'] },
  { campaignId: ICampaign['id'] },
  { campaignId: ICampaign['id'] }
>()

export const generateCampaignReportProgress = createStandardAction(
  '@@MASCOT/CAMPAIGN_DETAILS/GENERATE_CAMPAIGN_REPORT_PROGRESS'
)<{ campaignId: string; progress: number }>()

export const updateRecurringCampaignEndDateAction = createStandardAction(
  '@@MASCOT/RECURRING_CAMPAIGN_DETAILS/UPDATE_END_DATE'
)<IRecurrenceSettings>()

export type ICampaignDetailsActions =
  | ActionType<typeof fetchCampaignDetails>
  | ActionType<typeof fetchCampaignImportDetails>
  | ActionType<typeof fetchRecurringCampaignDetails>
  | ActionType<typeof fetchAggRecurringCampaignDetails>
  | ActionType<typeof generateCampaignReportProgress>
  | ActionType<typeof updateRecurringCampaignEndDateAction>
  | ActionType<typeof generateCampaignReport>
  | ActionType<typeof fetchCampaignTriggerDetails>
  | ActionType<typeof fetchIntroDialogVariantDetails>
