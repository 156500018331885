import * as React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { TextInput, ITextInputProps } from 'components/TextInput/TextInput'
import classnames from 'classnames'
import {
  EventAction,
  EventTrackerAttrAdder,
} from 'components/EventTracker/EventTracker'

export interface ISearchInputProps extends Omit<ITextInputProps, 'children'> {
  innerRef?: React.RefObject<HTMLInputElement>
  readonly onClear?: () => void
  readonly minCharactersCount?: number
  readonly eventAction?: EventAction
  readonly eventLocation?: string
  readonly eventObject?: string
  readonly clearEventAction?: EventAction
  readonly clearEventObject?: string
}

export function SearchInput({
  placeholder,
  value,
  innerRef,
  onChange,
  onBlur,
  className,
  minCharactersCount = 0,
  onClear,
  autoFocus = false,
  eventAction,
  eventLocation,
  eventObject,
  clearEventAction,
  clearEventObject,
  ...rest
}: ISearchInputProps) {
  const [searchCharactersCount, setSearchCharactersCount] = React.useState(0)
  return (
    <div className={classnames('search-input position-relative', className)}>
      <div className="search-input-prepend">
        <AHIcon name="search" />
      </div>
      <TextInput
        {...rest}
        autoFocus={autoFocus}
        placeholder={placeholder}
        type="search"
        value={value}
        ref={innerRef}
        onChange={e => {
          if (onChange) {
            setSearchCharactersCount(e.target.value.trim().length)
            onChange(e)
          }
        }}
        onBlur={onBlur}
        eventAction={eventAction}
        eventLocation={eventLocation}
        eventObject={eventObject}
      />
      {onClear && value ? (
        <EventTrackerAttrAdder
          eventAction={clearEventAction}
          eventLocation={eventLocation}
          eventObject={clearEventObject}>
          <div className="search-input-append">
            <AHIcon name="close" className="btn-icon" onClick={onClear} />
          </div>
        </EventTrackerAttrAdder>
      ) : null}
      {!!searchCharactersCount &&
        searchCharactersCount < minCharactersCount && (
          <span className="position-absolute caption z-index-10">
            Enter {minCharactersCount} or more characters
          </span>
        )}
    </div>
  )
}
