import cls from 'classnames'
import { NavLink } from 'util/routing'
import { useFeatures } from 'util/hooks'
import { FeaturesType } from 'components/Feature/Feature'
import { isUndefined } from 'lodash'
import { ITrackingEvent } from 'components/EventTracker/EventTracker'

export type MainstaySidebarLinkType = {
  readonly icon?: JSX.Element
  readonly disabled?: boolean
  readonly text: string
  readonly to: string
  readonly active?: boolean
  readonly count?: number
  readonly divider?: boolean
  readonly scrollable?: boolean
  readonly feature?: FeaturesType
  readonly expand?: MainstaySidebarLinkType[]
  readonly permission?: string
  readonly event?: ITrackingEvent
  readonly forceOpen?: boolean
  readonly containerClassName?: string
  readonly scrollContentClassName?: string
}

export function MainstaySidebarSectionLink({
  link,
}: {
  readonly link: MainstaySidebarLinkType
}) {
  const { hasFeature } = useFeatures()
  if (link.feature && !hasFeature(link.feature)) {
    return null
  }

  if (link.disabled) {
    return (
      <div className="d-flex justify-content-between w-100 py-1 pl-3 mt-1">
        <span className="text-truncate">{link.text}</span>{' '}
        {link.count !== undefined && (
          <span className="caption pr-3">{link.count}</span>
        )}
      </div>
    )
  }

  return (
    <NavLink
      eventLocation={link.event?.location}
      eventAction={link.event?.action}
      eventObject={link.event?.object}
      to={link.to}
      key={link.to}
      exact
      isActive={isUndefined(link.active) ? undefined : () => !!link.active}
      className={cls(
        'd-flex flex-row align-items-center py-1 pointer pl-3 text-decoration-none mainstay-sidebar-link mt-1',
        {
          active: link.active,
        }
      )}>
      {link.icon && <div className="d-flex pr-1">{link.icon}</div>}
      <div
        className={cls(
          'd-flex justify-content-between w-100',
          'hover-text-mainstay-dark-blue',
          {
            'text-mainstay-dark-blue-65':
              link.active !== undefined && !link.active,
            'text-mainstay-dark-blue': link.active,
          }
        )}>
        <span className="text-truncate">{link.text}</span>{' '}
        {link.count !== undefined && (
          <span className="caption pr-3">{link.count}</span>
        )}
      </div>
    </NavLink>
  )
}

export function MainstaySidebarSectionDivider({
  className,
}: {
  readonly className?: string
}) {
  return (
    <div className={className}>
      <hr className="m-0 my-3 ml-3" />
    </div>
  )
}
