import { ActionType, createStandardAction } from 'typesafe-actions'

/*
Contains actions for anything that's meant to be shared across different pages.
*/
export const openEditRecurringCampaignEndDateModal = createStandardAction(
  '@@mascot/global-states/OPEN_EDIT_RECURRING_CAMPAIGN_END_DATE_MODAL'
)<boolean>()

export type IGlobalSharedStateActions = ActionType<
  typeof openEditRecurringCampaignEndDateModal
>
